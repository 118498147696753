import React, { ReactElement } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
  Edge,
} from '@xyflow/react';
import { useIntl } from 'react-intl';
import { WorkflowNode } from '../nodes/BaseNode';
import { WorkflowStatus } from '../../../graphql/operations';
import { cx } from '../../../helpers/utils';

/**
 * calculates edge source and target position relative to
 * other graph edges connected to the same source/target
 */
function useEdgeRelativePosition(edgeProps: EdgeProps): {
  sourceIndex: number;
  sourceSize: number;
  targetIndex: number;
  targetSize: number;
} {
  const { id, sourceHandleId, targetHandleId, source, target } = edgeProps;
  const reactFlow = useReactFlow();
  const edges = reactFlow.getEdges();

  const getEdgePositions = (edge: Edge) => {
    const sourceNode = reactFlow?.getInternalNode(edge?.source)?.internals;
    const sourceHandle = sourceNode?.handleBounds?.source?.find((handle) => {
      // if no handle id specified, return first handle.
      // if only one handle, return that handle (simetimes edge can keep old
      // handle id after reconnecting to a new handle without id)
      if (!sourceHandleId || sourceNode?.handleBounds?.source?.length === 1) {
        return true;
      }
      return handle.id === sourceHandleId;
    });
    const targetNode = reactFlow.getInternalNode(edge.target)?.internals;
    const targetHandle = targetNode?.handleBounds?.target?.find((handle) => {
      if (!targetHandleId || targetNode?.handleBounds?.target?.length === 1) {
        return true;
      }
      return handle.id === targetHandleId;
    });
    if (!sourceNode || !targetNode || !sourceHandle || !targetHandle) {
      return {
        id: edge.id,
        sourceX: 0,
        targetX: 0,
      };
    }
    return {
      id: edge.id,
      sourceX: sourceNode.positionAbsolute.x + sourceHandle?.x,
      targetX: targetNode.positionAbsolute.x + targetHandle?.x,
    };
  };

  const targetEdges = edges
    .filter(
      (edge) =>
        edge.target === target &&
        (targetHandleId ? edge.targetHandle === targetHandleId : true)
    )
    .map(getEdgePositions);

  const sourceEdges = edges
    .filter((edge) => {
      return (
        edge.source === source &&
        (sourceHandleId ? edge.sourceHandle === sourceHandleId : true)
      );
    })
    .map(getEdgePositions);

  const sortedSource = sourceEdges.sort((a, b) => a.targetX - b.targetX);
  const sortedTarget = targetEdges.sort((a, b) => a.sourceX - b.sourceX);
  const sourceIndex = sortedSource.findIndex((edge) => edge.id === id);
  const targetIndex = sortedTarget.findIndex((edge) => edge.id === id);

  return {
    sourceIndex,
    sourceSize: sortedSource.length,
    targetIndex,
    targetSize: sortedTarget.length,
  };
}

function useEdgePath(
  props: EdgeProps
): [
  path: string,
  labelX: number,
  labelY: number,
  offsetX: number,
  offsetY: number,
] {
  const { sourceIndex, sourceSize, targetIndex, targetSize } =
    useEdgeRelativePosition(props);
  const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition } =
    props;
  const OFFSET = 10;
  return getSmoothStepPath({
    sourceX: sourceX + OFFSET * sourceIndex - ((sourceSize - 1) * OFFSET) / 2,
    sourceY,
    sourcePosition,
    targetX: targetX + OFFSET * targetIndex - ((targetSize - 1) * OFFSET) / 2,
    targetY,
    targetPosition,
    borderRadius: 24,
  });
}

/**
 * Tactiq workflows edge. When rendered in activity view mode, colors reflect node execution status
 */
export default function EdgeComponent(props: EdgeProps): ReactElement {
  const reactFlow = useReactFlow();
  const intl = useIntl();
  const sourceNode = reactFlow.getNode(props.source) as WorkflowNode;
  const targetNode = reactFlow.getNode(props.target) as WorkflowNode;
  const [edgePath, labelX, labelY] = useEdgePath(props);

  const isComplete =
    sourceNode.data.execution?.status === WorkflowStatus.COMPLETE &&
    (targetNode.data.execution?.status === WorkflowStatus.COMPLETE ||
      targetNode.data.execution?.status === WorkflowStatus.PENDING ||
      targetNode.data.execution?.status ===
        WorkflowStatus.WAITING_FOR_CONFIRMATION);

  const isSelected = !!props.selected;

  let label = '';
  switch (sourceNode.type) {
    case 'Condition':
      label =
        props.sourceHandleId === 'true'
          ? intl.formatMessage({ defaultMessage: 'Yes', id: 'a5msuh' })
          : intl.formatMessage({ defaultMessage: 'No', id: 'oUWADl' });
      break;
    case 'Confirmation':
      label =
        props.sourceHandleId === 'true'
          ? intl.formatMessage({ defaultMessage: 'Accept', id: 'sjzLbX' })
          : intl.formatMessage({ defaultMessage: 'Decline', id: 'pvtgR2' });
      break;
    default:
      break;
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        {...props}
        markerEnd={`url('#tactiq-edge-marker')`}
        className={
          isComplete
            ? '!stroke-green-500'
            : isSelected
              ? '!stroke-brand-500'
              : '!stroke-slate-400/80'
        }
      />
      {label && (
        <EdgeLabelRenderer>
          <div
            className={cx(
              'nodrag nopan absolute rounded-button border border-slate-300 bg-slate-50 px-2 py-0.5 font-medium text-sm',
              isComplete
                ? '!border-green-400 bg-green-50 text-green-500'
                : isSelected
                  ? '!border-brand-500 text-slate-600'
                  : 'text-slate-500'
            )}
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

/**
 * React flow doesn't have a great way to extend markers.
 * So we need to export these defs and reference them with and id
 */
export function EdgeMarkerDefs(): ReactElement {
  return (
    <svg style={{ position: 'absolute', top: 0, left: 0 }}>
      <defs>
        <marker
          id="tactiq-edge-marker"
          markerWidth="25"
          markerHeight="25"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            points="-6,-3 0,0 -6,3"
            stroke="context-stroke"
            fill="context-fill"
          />
        </marker>
      </defs>
    </svg>
  );
}
