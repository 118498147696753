import React, { ReactElement } from 'react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Target } from 'lucide-react';
import { Select } from '../../../components/Select';
import { useIntl, FormattedMessage } from 'react-intl';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useIsPreview } from './useIsPreview';
import { useReactFlow } from '@xyflow/react';
import featureFlagService from '../../../helpers/feature-flags';
import { WorkflowTriggerType } from '@tactiq/model';

export function StartNode(
  props: WorkflowNodeProps<{
    trigger:
      | { type: WorkflowTriggerType.MANUAL }
      | { type: WorkflowTriggerType.AUTOMATIC; conditions: string };
  }>
): ReactElement {
  const reactFlow = useReactFlow();
  const disabled = useIsPreview() || Boolean(props.data.execution);
  const intl = useIntl();

  const trigger = props.data.trigger ?? { type: WorkflowTriggerType.MANUAL };
  const isAutomatic = trigger.type === WorkflowTriggerType.AUTOMATIC;

  return (
    <SingleSourceNode
      icon={
        <Target className="size-8 rounded-lg border border-violet-400/15 bg-violet-50 p-1.5 text-violet-500" />
      }
      workflowNode={props}
      hideId
      minHeight={60}
      contentClassName={isAutomatic ? 'h-full' : ''}
    >
      {featureFlagService.isAutomaticWorkflowTriggersEnabled() && (
        <div className="no-drag flex h-full flex-col gap-6">
          <div className="nodrag flex w-full flex-col gap-y-1">
            <label className="block font-medium text-slate-500 text-sm leading-6">
              <FormattedMessage defaultMessage="Trigger type" />
            </label>
            <Select
              value={trigger.type}
              disabled={disabled}
              onChange={(type) =>
                reactFlow.updateNodeData(props.id, {
                  trigger: {
                    type,
                  },
                })
              }
              full
              options={[
                {
                  label: intl.formatMessage({
                    defaultMessage: 'Manual',
                  }),
                  value: WorkflowTriggerType.MANUAL,
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: 'Automatic',
                  }),
                  value: WorkflowTriggerType.AUTOMATIC,
                },
              ]}
            />
          </div>

          {trigger.type === WorkflowTriggerType.AUTOMATIC && (
            <div className="flex h-full flex-col">
              <div className="mb-2 font-medium text-sm">
                <FormattedMessage defaultMessage="Conditions" />
              </div>

              <LiquidTemplateInput
                workflowId=""
                className="min-h-16"
                value={trigger.conditions}
                onChange={(next) => {
                  reactFlow.updateNodeData(props.id, {
                    trigger: {
                      type: WorkflowTriggerType.AUTOMATIC,
                      conditions: next,
                    },
                  });
                }}
                disabled={disabled}
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Conditions',
                })}
              />
            </div>
          )}
        </div>
      )}
    </SingleSourceNode>
  );
}
