import React, {
  ReactElement,
  ReactNode,
  useState,
  useLayoutEffect,
} from 'react';
import AddNodeButton from '../AddNodeButton';
import {
  Handle,
  NodeProps,
  Position,
  useReactFlow,
  useStoreApi,
  Node,
  NodeResizer,
  NodeToolbar,
  useEdges,
  useConnection,
} from '@xyflow/react';
import { cx } from '../../../helpers/utils';
import { NodeData, WorkflowStatus } from '../../../graphql/operations';
import { WorkflowStatusIcon } from '../WorkflowStatusIcon';
import { workflowDuration } from '../../../services/Workflow';
import { Button } from '../../../components/buttons';
import { Check, GripVertical, Info, Trash, X } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';
import { nodeTypes } from '.';
import { Popover } from '../../../components/Popover';
import JsonView from 'react-json-view';
import { Menu } from '../../../components/Menu';
import { formatDistance } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { trackRenameWorkflowNode } from '../../../helpers/analytics';
import { useSavedOnce, useWorkflowId } from '../WorkflowIdContext';
import { Alert } from '../../../components/Alert';
import { ErrorBoundary } from '../../Common/ErrorBoundary';
import { DeadEnd } from '../../../components/DeadEnd';

export type WorkflowNode<T = object> = Node<
  {
    displayName: string;
    execution?: {
      status: WorkflowStatus;
      startedAt?: number;
      completedAt?: number;
      output?: unknown;
      error?: { message: string };
    };
    recentExecutionData?: NodeData;
    isValid?: boolean;
  } & T,
  keyof typeof nodeTypes
>;
export type WorkflowNodeProps<T = object> = NodeProps<WorkflowNode<T>>;

type BaseNodeProps = {
  icon?: ReactNode;
  handles?: ReactNode | undefined | null;
  children?: any;
  workflowNode: WorkflowNodeProps;
  hideId?: boolean;
  noResize?: boolean;
  minHeight: number;
  contentClassName?: string;
};

export function BaseNode({
  icon,
  children,
  handles,
  workflowNode,
  hideId,
  noResize,
  minHeight,
  contentClassName = '',
}: BaseNodeProps): ReactElement {
  const savedOnce = useSavedOnce();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const state = useReactFlow();
  const execution = workflowNode.data.execution;
  const { workflowId } = useWorkflowId();
  const title = workflowNode.data.displayName ?? workflowNode.type;
  const isValid = workflowNode.data.isValid;

  const node = state.getNode(workflowNode.id);

  const nodeBorderRef = React.useRef<HTMLDivElement>(null);
  const nodeBodyRef = React.useRef<HTMLDivElement>(null);

  const store = useStoreApi();
  const { triggerNodeChanges } = store.getState();
  const zoom = state.getZoom();

  useLayoutEffect(() => {
    if (!nodeBorderRef.current || !nodeBodyRef.current) {
      return;
    }
    function adjustNodeHeightIfNeeded() {
      if (
        !node ||
        !node.height ||
        !node.width ||
        !nodeBorderRef.current ||
        !nodeBodyRef.current
      ) {
        return;
      }
      const bbBorder = nodeBorderRef.current.getBoundingClientRect();
      const bbBody = nodeBodyRef.current.getBoundingClientRect();

      const borderBottomWidth =
        Number.parseInt(
          getComputedStyle(nodeBorderRef.current).borderBottomWidth
        ) * zoom;
      const overflowAmount = -(
        bbBorder.bottom -
        borderBottomWidth -
        bbBody.bottom
      );

      // a bit more conservative here in fixing "container height is too big" vs
      // "container height is too small", allow up 10px extra space before we resize the node
      // this is to reduce number of resizes and to lower the risk
      // of "Maximum update depth exceeded" error
      if (overflowAmount > 2 || overflowAmount < -10) {
        const contentHeight = node.height + overflowAmount;
        triggerNodeChanges([
          {
            id: workflowNode.id,
            type: 'dimensions',
            resizing: true,
            setAttributes: true,
            dimensions: {
              width: node.width,
              height: contentHeight,
            },
          },
        ]);
      }
    }

    adjustNodeHeightIfNeeded();
    const ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === nodeBodyRef.current) {
          adjustNodeHeightIfNeeded();
        }
      }
    });
    ro.observe(nodeBodyRef.current!);
    return () => {
      ro.disconnect();
    };
  }, [
    zoom,
    triggerNodeChanges,
    node,
    node?.data,
    node?.width,
    node?.height,
    workflowNode.id,
  ]);

  const recentExecutionOutput =
    workflowNode.data.recentExecutionData?.output ?? '';

  if (execution) {
    const duration = workflowDuration(
      execution.startedAt,
      execution.completedAt
    );
    return (
      <div
        className={cx(
          'relative min-w-96 cursor-default rounded-workflowNode border bg-white shadow-lg shadow-slate-200/20 motion-safe:transition-shadow',
          workflowNode.data.execution?.status === 'COMPLETE'
            ? 'border-green-500'
            : 'border-slate-200'
        )}
      >
        <div className="flex justify-between gap-2">
          <div className="mx-5 flex w-full items-center justify-between gap-1 border-slate-200/60 border-b py-3">
            {icon}
            <div className="font-bold text-base leading-none">{title}</div>
            {duration && (
              <div className="ml-auto pr-1 font-normal text-sm">{duration}</div>
            )}
            {execution.status === 'COMPLETE' ||
            execution.status === 'FAILED' ? (
              <Menu>
                <Menu.Trigger>
                  <Button
                    size="small"
                    variant="naked"
                    startIcon={<WorkflowStatusIcon status={execution.status} />}
                  />
                </Menu.Trigger>
                <div className="flex flex-col items-start pb-2">
                  <div className="flex h-[24px] items-center rounded-full border border-slate-200 bg-slate-100 px-2.5 font-medium text-slate-600 text-xs">
                    {workflowNode.id}
                  </div>
                </div>
                <div className="flex flex-col gap-2 p-2">
                  {execution.startedAt ? (
                    <div className="text-slate-500 text-xs">
                      <FormattedMessage
                        defaultMessage="Started: {date}"
                        id="nOZpKS"
                        values={{
                          date: formatDistance(
                            new Date(execution.startedAt),
                            Date.now(),
                            {
                              addSuffix: true,
                            }
                          ),
                        }}
                      />
                    </div>
                  ) : null}
                  {execution.startedAt && execution.completedAt ? (
                    <div className="text-slate-500 text-xs">
                      <FormattedMessage
                        defaultMessage="Duration: {duration}"
                        id="Sczo8n"
                        values={{
                          duration,
                        }}
                      />
                    </div>
                  ) : null}
                  {execution.error ? (
                    <>
                      <div className="text-red-500 text-xs">
                        <FormattedMessage
                          defaultMessage="Error message:"
                          id="zbpOKH"
                        />
                      </div>
                      <div className="text-red-500 text-xs">
                        {execution.error.message}
                      </div>
                    </>
                  ) : null}
                </div>
                {execution.output ? (
                  <JsonView
                    collapseStringsAfterLength={10}
                    name={workflowNode.id}
                    src={
                      typeof execution?.output === 'string'
                        ? {
                            output: execution?.output,
                          }
                        : execution?.output
                    }
                  />
                ) : null}
              </Menu>
            ) : (
              <WorkflowStatusIcon status={execution.status} />
            )}
          </div>
        </div>
        {children && <div className="relative p-5">{children}</div>}
        {handles}
      </div>
    );
  }
  return (
    <div
      ref={nodeBorderRef}
      className={cx(
        'group flex h-full w-full min-w-96 flex-grow cursor-default flex-col rounded-workflowNode border bg-white shadow-lg shadow-slate-200/20 motion-safe:transition-shadow',
        workflowNode.dragging ? '!shadow-xl !shadow-slate-200' : '',
        workflowNode.selected ? 'border-brand-500' : 'border-slate-200',
        isValid === false
          ? '!border-slate-500 border-1 border-dashed ring-4 ring-slate-200/40'
          : 'border-brand-500'
      )}
    >
      <div className="mx-5 flex items-center justify-between gap-1 border-slate-200/60 border-b py-3">
        {icon}
        <TextInput
          className="nodrag grow font-semibold text-base"
          variant="naked"
          value={title}
          onBlur={() => {
            trackRenameWorkflowNode({ workflowId, type: workflowNode.type });
          }}
          onChange={(displayName) => {
            state.updateNodeData(workflowNode.id, {
              displayName,
            });
          }}
        />
        {!hideId && (
          <div className="flex h-[24px] items-center rounded-input border border-slate-200 bg-slate-50 px-3 font-semibold text-slate-500 text-xs">
            {workflowNode.id}
          </div>
        )}
      </div>

      {isValid === false && savedOnce && (
        <NodeToolbar align="start" position={Position.Right} isVisible>
          <Alert
            compact
            severity="warning"
            title={
              <FormattedMessage
                defaultMessage="This step needs updates"
                id="FOBaks"
              />
            }
          />
        </NodeToolbar>
      )}
      {children && (
        <div
          className={`nopan nowheel flex flex-col p-5 ${contentClassName}`}
          ref={nodeBodyRef}
        >
          <ErrorBoundary ErrorComponent={NodeErrorBoundary}>
            {children}
          </ErrorBoundary>
        </div>
      )}
      <NodeResizer
        minWidth={384}
        minHeight={minHeight}
        maxWidth={800}
        lineClassName="!border-4 !opacity-0"
        handleClassName="!size-8 !opacity-0"
        isVisible={!noResize}
      />
      {handles}
      <button className="-left-6 hover:-left-10 group-hover:-left-10 absolute top-0 h-full cursor-grab p-2 opacity-0 transition-all duration-100 hover:opacity-100 group-hover:opacity-100">
        <GripVertical className="fill-slate-400 stroke-slate-400" />
      </button>
      {workflowNode.type !== 'StartNode' && (
        <NodeToolbar
          isVisible={workflowNode.selected}
          align="end"
          position={Position.Top}
        >
          <div className="flex items-center gap-2">
            {removeConfirm ? (
              <>
                <Button
                  variant="secondaryOutline"
                  startIcon={<X size="1rem" />}
                  onClick={() => setRemoveConfirm(false)}
                />
                <Button
                  color="error"
                  startIcon={<Check size="1rem" />}
                  onClick={async () => {
                    await state.deleteElements({ nodes: [workflowNode] });
                    setRemoveConfirm(false);
                  }}
                />
              </>
            ) : (
              <>
                {recentExecutionOutput && (
                  <Popover
                    placement="right-start"
                    content={
                      <div className="max-h-80 overflow-y-auto">
                        <p className="mb-2 font-bold">
                          <FormattedMessage
                            defaultMessage="The most recent execution of this step resulted in the
                          following output:"
                            id="fhcGDr"
                          />
                        </p>
                        <p>{JSON.stringify(recentExecutionOutput)}</p>
                      </div>
                    }
                  >
                    <Button
                      variant="secondaryOutline"
                      startIcon={<Info size="1rem" />}
                    />
                  </Popover>
                )}
                <Button
                  variant="secondaryOutline"
                  startIcon={<Trash size="1rem" />}
                  onClick={() => setRemoveConfirm(true)}
                />
              </>
            )}
          </div>
        </NodeToolbar>
      )}
    </div>
  );
}

/*
  returns true if the connection is in progress and the given node 
  and handle touch one of the connection sides
*/
function useIsConnectionTouchesHandle({
  nodeId,
  type,
  handleId,
}: {
  nodeId: string;
  type: 'source' | 'target';
  handleId?: string | undefined;
}) {
  const connection = useConnection();
  if (!connection.inProgress) {
    return false;
  }
  if (!handleId) {
    return (
      (connection.fromNode.id === nodeId &&
        connection.fromHandle?.type === type) ||
      (connection.toNode?.id === nodeId && connection.toHandle?.type === type)
    );
  }
  const fromSideIsConnected =
    connection.fromNode.id === nodeId &&
    connection.fromHandle.type === type &&
    connection.fromHandle.id === handleId;
  const toSideIsConnected =
    connection.toNode?.id === nodeId &&
    connection.toHandle?.type === type &&
    connection.toHandle.id === handleId;
  return fromSideIsConnected || toSideIsConnected;
}

const topHandleBaseStyles =
  '!h-5 !rounded-full !bg-white !border-4 !border-white !ring-1 !ring-inset ring-slate-400';
const bottomHandleBaseStyles =
  '!h-5 !rounded-full !bg-white !border-4 !border-white !ring-1 !ring-inset ring-slate-400';

function calculateHandleWidth(numConnectedEdges: number) {
  if (numConnectedEdges < 2) {
    return '1.25rem';
  }
  return `${0.75 + 0.75 * numConnectedEdges}rem`;
}

export function SingleSourceNode({
  icon,
  children,
  workflowNode,
  hideId,
  noResize,
  minHeight,
  contentClassName,
}: BaseNodeProps): ReactElement {
  const edges = useEdges();
  const sourceHandleConnectionInProgress = useIsConnectionTouchesHandle({
    nodeId: workflowNode.id,
    type: 'source',
  });

  const outgoers = edges.filter((e) => e.source === workflowNode.id);
  const hasTarget = outgoers.length > 0;
  const execution = workflowNode.data.execution;
  const incomers = edges.filter((e) => e.target === workflowNode.id);
  const isNodeComplete = workflowNode.data.execution?.status === 'COMPLETE';
  const completedNodeBorderStyle = isNodeComplete ? '!ring-green-500' : '';

  return (
    <BaseNode
      workflowNode={workflowNode}
      hideId={hideId}
      noResize={noResize}
      icon={icon}
      minHeight={minHeight}
      contentClassName={contentClassName}
      handles={
        <div>
          {workflowNode.type !== 'StartNode' && (
            <Handle
              type="target"
              className={cx(
                topHandleBaseStyles,
                workflowNode.selected ? '!ring-brand-500' : '',
                completedNodeBorderStyle
              )}
              position={Position.Top}
              style={{
                width: calculateHandleWidth(incomers.length),
              }}
              isConnectable={!execution}
            />
          )}
          <Handle
            type="source"
            className={cx(
              bottomHandleBaseStyles,
              workflowNode.selected ? '!ring-brand-500' : '',
              completedNodeBorderStyle
            )}
            position={Position.Bottom}
            style={{
              width: calculateHandleWidth(outgoers.length),
            }}
            isConnectable={!execution}
          />
          <NodeToolbar
            isVisible={
              !(hasTarget || sourceHandleConnectionInProgress) && !execution
            }
            position={Position.Bottom}
            className="nodrag"
          >
            <AddNodeButton source={workflowNode.id} />
          </NodeToolbar>
        </div>
      }
    >
      {children}
    </BaseNode>
  );
}

export function BooleanNode({
  icon,
  children,
  workflowNode,
  hideId,
  noResize,
  minHeight,
  yes,
  no,
}: BaseNodeProps & { yes?: string; no?: string }): ReactElement {
  const reactFlow = useReactFlow();
  const intl = useIntl();

  const nodeId = workflowNode.id;
  const node = reactFlow.getNode(nodeId);
  const edges = useEdges();
  const trueHandleConnectionInProgress = useIsConnectionTouchesHandle({
    nodeId: workflowNode.id,
    type: 'source',
    handleId: 'true',
  });
  const falseHandleConnectionInProgress = useIsConnectionTouchesHandle({
    nodeId: workflowNode.id,
    type: 'source',
    handleId: 'false',
  });

  const trueTargets = edges.filter(
    (e) => e.source === nodeId && e.sourceHandle === 'true'
  );
  const falseTargets = edges.filter(
    (e) => e.source === nodeId && e.sourceHandle === 'false'
  );
  const hasTrueTarget =
    trueTargets.length > 0 || trueHandleConnectionInProgress;
  const hasFalseTarget =
    falseTargets.length > 0 || falseHandleConnectionInProgress;

  const incomers = edges.filter((e) => e.target === nodeId);

  const verticalPosition = 'absolute top-full mt-4';
  const execution = node?.data.execution;
  const newY = (node?.position?.y ?? 0) + (node?.measured?.height ?? 0) + 128;
  const newX = node?.position?.x ?? 0;
  const newWidth = (node?.measured?.width ?? 0) * 0.55;
  const isNodeComplete = workflowNode.data.execution?.status === 'COMPLETE';
  const borderStyle = workflowNode.selected ? '!ring-brand-500' : '';
  const completedNodeBorderStyle = isNodeComplete ? '!ring-green-500' : '';

  return (
    <BaseNode
      workflowNode={workflowNode}
      hideId={hideId}
      noResize={noResize}
      icon={icon}
      minHeight={minHeight}
      contentClassName="h-full"
      handles={
        <>
          <Handle
            type="target"
            className={cx(
              topHandleBaseStyles,
              borderStyle,
              completedNodeBorderStyle
            )}
            style={{
              width: calculateHandleWidth(incomers.length),
            }}
            position={Position.Top}
          />
          <div className="flex flex-row items-center">
            {!hasFalseTarget && !execution ? (
              <div
                className={cx(
                  verticalPosition,
                  'nodrag right-2/3 translate-x-1/2'
                )}
              >
                <AddNodeButton
                  source={workflowNode.id}
                  label={no ?? intl.formatMessage({ defaultMessage: 'If no' })}
                  sourceHandle="false"
                  position={{
                    x: newX - newWidth,
                    y: newY,
                  }}
                />
              </div>
            ) : null}
            <Handle
              className={cx(
                bottomHandleBaseStyles,
                borderStyle,
                completedNodeBorderStyle,
                '!left-1/3'
              )}
              style={{
                width: calculateHandleWidth(falseTargets.length),
              }}
              type="source"
              position={Position.Bottom}
              id="false"
              isConnectable={!execution}
            />
          </div>
          <div>
            {!hasTrueTarget && !execution ? (
              <div
                className={cx(
                  verticalPosition,
                  'nodrag -translate-x-1/2 left-2/3'
                )}
              >
                <AddNodeButton
                  source={workflowNode.id}
                  label={
                    yes ?? intl.formatMessage({ defaultMessage: 'If yes' })
                  }
                  sourceHandle="true"
                  position={{
                    x: newX + newWidth,
                    y: newY,
                  }}
                />
              </div>
            ) : null}
            <Handle
              className={cx(
                bottomHandleBaseStyles,
                borderStyle,
                '!left-2/3',
                completedNodeBorderStyle
              )}
              style={{
                width: calculateHandleWidth(trueTargets.length),
              }}
              type="source"
              id="true"
              position={Position.Bottom}
              isConnectable={!execution}
            />
          </div>
        </>
      }
    >
      {children}
    </BaseNode>
  );
}

/**
 * This is not meant to catch known errors. It's just a last resort so
 * that uncaught errors dont nuke the customers unsaved changes.
 *
 * It's not added to the exectution view for this reason. We still
 * want those errors to go to the sad robot, and we fix them asap.
 */
function NodeErrorBoundary(props: { message: string }): ReactElement {
  return <DeadEnd title="" action="" description={props.message} />;
}
