import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useMeetingContext } from '../../../MeetingContext';
import { cx } from '../../../../../../helpers/utils';
import { Spinner } from '../../../../../../components/Spinner';

const Backdrop: React.FC<
  React.PropsWithChildren<{ isOpen: boolean; onClick: () => void }>
> = ({ children, isOpen, onClick }) =>
  isOpen ? (
    <div
      onClick={onClick}
      className="fixed top-0 left-0 z-Backdrop flex h-svh w-svw items-center justify-center bg-slate-900/50"
    >
      {children}
    </div>
  ) : null;

/**
 * Tactiq Screenshot
 */
export const TactiqScreenshot: React.FC<{
  timestamp: number;
}> = ({ timestamp }) => {
  const [isOpen, setIsOpen] = useState(false);
  const showImage = useCallback(
    (event: React.MouseEvent) => {
      setIsOpen(true);
      event.stopPropagation();
      event.preventDefault();
    },
    [setIsOpen]
  );
  const hideImage = useCallback(() => setIsOpen(false), [setIsOpen]);

  const meetingId = useMeetingContext();
  const screenshotUrls = useSelector(
    (state: RootState) => state.global.screenshotUrls
  );

  const source = screenshotUrls
    ? screenshotUrls[`${meetingId}/${timestamp.toString()}`]
    : '';

  return source ? (
    <>
      <Backdrop onClick={hideImage} isOpen={isOpen}>
        <img
          id={timestamp.toString()}
          className="max-w-80%"
          alt="Screenshot from meeting with backdrop"
          src={source}
        />
      </Backdrop>
      <img
        id={timestamp.toString()}
        alt="Screenshot from meeting"
        className={cx('max-h-[500px] max-w-80%')}
        src={source}
        onClick={showImage}
      />
    </>
  ) : (
    <Spinner size="1.5rem" />
  );
};
