import React from 'react';

export const DescriptionListItemContentSubList: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <ul
      role="list"
      className="divide-y divide-slate-100 rounded-md border border-slate-200"
    >
      {children}
    </ul>
  );
};
