import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import { ToggleDebuggingDocument } from '../../graphql/operations';
import { MutationResult } from '../../services/helpers';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { selectUserDebug } from '../../redux/selectors';
import { Page } from '../Common/Navigation';
import { Alert } from '../../components/Alert';
import { enqueueSnackbar } from 'notistack';
import { formatDistanceToNowStrict } from 'date-fns';
import { trackWebPage } from '../../helpers/analytics';

const HOURS_48 = 48 * 60 * 60 * 1000;

export const Debug: React.FC = () => {
  const withinDebuggingPeriod = useWithinDebuggingPeriod();
  const toggleDebugging = useToggleDebugging();
  const debug = useSelector(selectUserDebug);

  useEffect(() => {
    trackWebPage('debug consent');
  }, []);

  return (
    <Alert
      severity="warning"
      variant="light"
      title={
        <h1 className="text-lg">
          {withinDebuggingPeriod ? (
            <FormattedMessage
              defaultMessage="You have granted access to your account"
              id="w3pKGV"
            />
          ) : (
            <FormattedMessage
              defaultMessage="We’d like to request temporary access to your account for investigation purposes."
              id="KSmy/l"
            />
          )}
        </h1>
      }
      description={
        <p className="py-4">
          <FormattedMessage
            defaultMessage="This will allow Tactiq to investigate any issues you are experiencing. The access will last for 48 hours, and you can revoke it at any time."
            id="K8sFe1"
          />

          {withinDebuggingPeriod && debug?.grantedAt && (
            <div className="mx-5 mt-6 rounded-md border border-slate-300 p-2 text-yellow-700">
              <FormattedMessage
                defaultMessage="You have granted access. The access remains for {timeRemaining}."
                id="IMXR2s"
                values={{
                  timeRemaining: formatDistanceToNowStrict(
                    new Date(debug.grantedAt + HOURS_48),
                    {
                      unit: 'hour',
                    }
                  ),
                }}
              />
            </div>
          )}
        </p>
      }
      action={
        <Button
          onClick={async () => {
            await toggleDebugging.request({});
          }}
          loading={toggleDebugging.loading}
        >
          {withinDebuggingPeriod ? (
            <FormattedMessage defaultMessage="Revoke access" id="KUFMiM" />
          ) : (
            <FormattedMessage defaultMessage="Grant access" id="fNLMOq" />
          )}
        </Button>
      }
    />
  );
};

export const DebugPage: React.FC = () => {
  const intl = useIntl();

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Account access consent',
        id: 'JwF8SZ',
      })}
      maxWidth="sm"
    >
      <Debug />
    </Page>
  );
};

export function useWithinDebuggingPeriod(): boolean {
  const debug = useSelector(selectUserDebug);
  const withinDebuggingPeriod = debug?.grantedAt
    ? Date.now() - debug.grantedAt < HOURS_48
    : false;

  return withinDebuggingPeriod;
}

export function useToggleDebugging(): MutationResult<
  typeof ToggleDebuggingDocument
> {
  const intl = useIntl();
  const [fn, { data, loading, error }] = useMutation(ToggleDebuggingDocument, {
    onCompleted: () => {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Successfully updated settings',
          id: '0MC1vJ',
        }),
        {
          variant: 'SUCCESS',
        }
      );
    },
    onError: () => {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Failed to update settings',
          id: 'FzKosz',
        }),
        {
          variant: 'ERROR',
        }
      );
    },
  });

  return {
    request: () => {
      return fn();
    },
    data,
    error,
    loading,
  };
}
