import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '../../components/buttons';
import { CreateMeetingKitDocument, MeetingKit } from '../../graphql/operations';
import { trackWebEvent, trackWebPage } from '../../helpers/analytics';
import { kMeetingKits, kMeetingKitsExplore } from '../../helpers/routes';
import { setMeetingKit } from '../../redux/modules/global';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { EmojiField } from '../Common/icons/EmojiField';
import { getMeetingKitItemIcon } from './helpers';
import { ChevronRight, Compass, Folder, PlusIcon, Wand2 } from 'lucide-react';
import { Chip } from '../../components/Chips';
import { Link } from 'react-router-dom';

export const MeetingKits: React.FC<{ isExploring?: boolean }> = ({
  isExploring: initialExploring,
}) => {
  const intl = useIntl();

  const usedMeetingKits = useSelector((state: RootState) =>
    state.global.meetingKits.used.filter((k) => !k.publishedFrom)
  );

  const exploreMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.explore
  );

  const isExploring =
    initialExploring || (!usedMeetingKits.length && exploreMeetingKits.length);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    trackWebPage('Meeting Kits');
  }, []);

  const [createMeetingKit, createMeetingKitMutation] = useMutation(
    CreateMeetingKitDocument
  );

  const kits = isExploring ? exploreMeetingKits : usedMeetingKits;

  // TODO: remove kit item details

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: 'AI Kits', id: '2xPU/d' })}
    >
      <div className="flex flex-row items-center gap-4">
        <h1 className="flex-1 text-3xl">
          {isExploring ? (
            <FormattedMessage
              defaultMessage="Explore AI Meeting Kits"
              id="yeP8uF"
            />
          ) : (
            <FormattedMessage defaultMessage="AI Meeting Kits" id="2n0MJH" />
          )}
        </h1>
        <Button
          loading={createMeetingKitMutation.loading}
          startIcon={<PlusIcon className="h-5 w-5" />}
          onClick={async () => {
            trackWebEvent('Create meeting kit', { from: 'meeting-kits-page' });

            const kit = (await createMeetingKit()).data?.createMeetingKit
              ?.meetingKit;

            if (kit) {
              dispatch(setMeetingKit(kit));
              navigate(`/meeting-kits/${kit.id}`);
            } else {
              enqueueSnackbar(
                intl.formatMessage({
                  defaultMessage: 'Failed to create a meeting kit',
                  id: 'DGAxtU',
                }),
                { variant: 'WARNING' }
              );
            }
          }}
        >
          <FormattedMessage defaultMessage="Create Kit" id="aJ0iq+" />
        </Button>
        {isExploring && usedMeetingKits.length ? (
          <Button
            color="success"
            startIcon={<Wand2 className="text-white" />}
            onClick={() => navigate(kMeetingKits)}
          >
            <FormattedMessage defaultMessage="My Meeting Kits" id="xdWOm8" />
          </Button>
        ) : null}
        {!isExploring && (
          <Button
            color="success"
            startIcon={<Compass className="text-white" />}
            onClick={() => navigate(kMeetingKitsExplore)}
          >
            <FormattedMessage
              defaultMessage="Explore Meeting Kits"
              id="kzKk9w"
            />
          </Button>
        )}
      </div>
      <div className="mt-8 flex flex-col gap-4">
        {kits.map((kit) => (
          <MeetingKitTile key={kit.id} kit={kit} />
        ))}
      </div>
    </Page>
  );
};

export const MeetingKitTile: React.FC<{ kit: MeetingKit }> = ({ kit }) => {
  return (
    <Link
      to={`/meeting-kits/${kit.id}`}
      className="pointer relative rounded-card border border-slate-200 p-4 shadow-sm hover:bg-slate-50"
    >
      <div className="flex gap-4">
        <div className="mt-2 flex-shrink-0">
          <EmojiField
            value={kit.icon}
            defaultIcon={<Folder />}
            EmojiProps={{
              unified: kit.icon,
              size: 24,
            }}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <div className="font-semibold text-base text-slate-800">
              {kit.name}
            </div>
            <div className="text-slate-500 text-sm">{kit.description}</div>
          </div>
          {kit.items.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {kit.items.map((item) => (
                <Chip key={item.id} color="slate">
                  <EmojiField
                    value={item.icon}
                    defaultIcon={getMeetingKitItemIcon(item)}
                    EmojiProps={{
                      unified: item.icon,
                      size: 20,
                    }}
                  />
                  <span>{item.name}</span>
                </Chip>
              ))}
            </div>
          )}
        </div>
        <ChevronRight className="ml-auto flex-shrink-0 self-center text-slate-500" />
      </div>
    </Link>
  );
};
