import React from 'react';
import { SkeletonBlock } from './SkeletonBlock';
import { SearchIcon } from 'lucide-react';
import { TranscriptSkeleton } from './TranscriptSkeleton';

export const MeetingSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col">
      {/* TopBar */}
      <div className="my-5 flex flex-col gap-2 motion-safe:animate-pulse">
        <div className="flex flex-row justify-between">
          {/* Breadcrumbs */}
          <div className="h-4 w-32 rounded bg-slate-200" />
          {/* Meeting Actions */}
          <div className="hidden flex-row gap-2 md:flex">
            <div className="h-4 w-16 rounded bg-slate-200" />
            <div className="h-4 w-16 rounded bg-slate-200" />
            <div className="h-4 w-16 rounded bg-slate-200" />
            <div className="h-4 w-16 rounded bg-slate-200" />
            <div className="h-4 w-16 rounded bg-slate-200" />
            <div className="h-4 w-4 rounded bg-slate-200" />
            <div className="h-4 w-4 rounded bg-slate-200" />
          </div>
        </div>
        {/* Searchbar */}
        <div className="mb-2 flex h-8 w-full items-center rounded border border-slate-200 pl-2">
          <SearchIcon className="text-slate-200" />
        </div>
      </div>
      <div className="border-b border-b-slate-200" />
      <div className="lg:grid lg:grid-cols-12 lg:grid-rows-[min-content,1fr]">
        <div className="px-0 pt-4 lg:col-span-8 lg:row-start-1 lg:pr-5">
          <div className="flex flex-col gap-2 motion-safe:animate-pulse">
            {/* MeetingTitle */}
            <div className="h-8 w-1/2 rounded bg-slate-200" />
            {/* Owner and info */}
            <div className="mb-6 flex flex-row items-center gap-2">
              <div className="size-6 rounded-full bg-slate-200" />
              <div className="h-4 w-3/4 rounded bg-slate-200" />
            </div>

            {/* Transcript */}
            <TranscriptSkeleton />
          </div>
        </div>

        <div className="lg:col-start-9 lg:col-end-13 lg:row-start-1 lg:row-end-3 lg:pt-5 lg:pl-6">
          <div className="lg:sticky lg:top-[127px] lg:h-screen lg:max-h-[calc(100vh_-_127px)] lg:overflow-y-auto lg:overflow-x-clip lg:pb-10">
            <SkeletonBlock />
          </div>
        </div>
        <div className="col-start-1 col-end-9 row-start-2 px-0 lg:pr-5">
          <SkeletonBlock />
        </div>
      </div>
    </div>
  );
};
