import React from 'react';
import { FormattedMessage } from 'react-intl';

export const BadgeNew: React.FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  return (
    <span className="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-1 font-medium text-pink-700 text-xs">
      <FormattedMessage defaultMessage="New!" id="RlOKwP" />
    </span>
  );
};
