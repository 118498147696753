import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import GoogleMeetIcon from '../../../img/googlemeet.png';
import ZoomIcon from '../../../img/zoom.png';
import MSTeamIcon from '../../../img/msteams.png';
import { trackWebEvent, trackWebPage } from '../../../helpers/analytics';
import { RootState } from '../../../redux/store';
import { Banner } from '../../../components/Banner';

const BannerIconClasses = 'w-6 h-6';

const BannerIconsWrapperClasses = 'flex flex-row items-center gap-2';

/**
 * Install Extenstion Link
 * @param {unknown} param0 params
 * @param {string} param0.from from
 * @returns {React.FC} component
 */
export const InstallExtensionLink: React.FC<{ from: string }> = ({ from }) => {
  return (
    <a
      className="shrink-0 rounded-md bg-blue-100 px-2.5 py-1 font-semibold text-blue-600 text-sm"
      href="https://tactiq.io/r/notinstalled"
      onClick={() => {
        trackWebEvent('Clicked Install Extension Banner link', { from });
        return true;
      }}
    >
      {from === 'banner' ? (
        <FormattedMessage
          defaultMessage="Get started – Install Tactiq now"
          id="63JvQC"
        />
      ) : (
        <FormattedMessage defaultMessage="Install now" id="+R9DmE" />
      )}
    </a>
  );
};

/**
 * Install Extenstion Banner Icons
 * @returns {React.FC} component
 */
export const InstallExtensionBannerIcons: React.FC = () => {
  return (
    <div className={BannerIconsWrapperClasses}>
      <img
        className={BannerIconClasses}
        src={GoogleMeetIcon}
        alt="Google Meet"
      />
      <img className={BannerIconClasses} src={ZoomIcon} alt="Zoom" />
      <img
        className={BannerIconClasses}
        src={MSTeamIcon}
        alt="Microsoft Teams"
      />
    </div>
  );
};

/**
 * Install Extenstion Banner
 * @returns {React.FC} component
 */
export const InstallExtensionBanner: React.FC = () => {
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );

  useEffect(() => {
    if (isExtensionUnavailable) {
      trackWebPage('Install Extension Banner');
    }
  }, [isExtensionUnavailable]);

  if (!isExtensionUnavailable) {
    return null;
  }

  return (
    <Banner>
      <div className="flex flex-row items-center gap-x-3">
        <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
          <div className="font-medium text-slate-600 text-sm">
            <FormattedMessage
              defaultMessage="Add the chrome extension to use with Google Meet, Zoom, MS Teams and more. "
              id="vs59sE"
            />
          </div>
        </div>
      </div>
      <div className="shrink-0">
        <InstallExtensionLink from="banner" />
      </div>
    </Banner>
  );
};
