import React, { useRef, useState } from 'react';
import { RichTextInput } from '../../components/RichTextInput';
import { Button } from '../../components/buttons';
import { WorkflowStatus } from '@tactiq/model';
import {
  useSubmitWorkflowConfirmation,
  useSubmitIntegrationUserInput,
} from '../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import { NodeData, WorkflowExecution } from '../../graphql/operations';
import {
  ThumbsUp as ConfirmationIcon,
  Blocks as ShareIcon,
} from 'lucide-react';
import {
  useDataCollectionList,
  useDataCollectionSearch,
  useDataSource,
  useInstanceKey,
} from '../../services/Integration';
import { Combobox } from '../../components/Combobox';
import { get, set, cloneDeep } from 'lodash';

type NeedInput = {
  type: string;
  referenceCollectionKey: string;
  fieldPath: string;
  fieldTitle: string;
  fieldType: string | string[];
};

const UserInputField: React.FC<{
  value: Record<string, any>;
  onChange: (
    value: { id: string } | Array<{ id: string }> | null,
    path: string
  ) => void;
  field: NeedInput;
  instanceKey: string;
}> = ({ value, onChange, field, instanceKey }) => {
  const {
    type,
    referenceCollectionKey: collectionKey,
    fieldPath,
    fieldType,
  } = field;

  const dataSource = useDataSource({
    type,
    instanceKey,
    autoCreate: false,
  });
  const canSearch = Boolean(dataSource.data?.collectionSpec?.search);

  const list = useDataCollectionList({ type, collectionKey });
  const search = useDataCollectionSearch({ type, collectionKey });

  // because options can change
  const previousOptionsCache = useRef<
    Map<string, { name?: string; id: string }>
  >(new Map());

  const options = (search.data?.records ?? list.data?.records ?? []).map(
    (x) => {
      previousOptionsCache.current.set(x.id, x);
      return {
        name: x.name,
        id: x.id,
      };
    }
  );

  const multiple = fieldType === 'array';
  const loading = list.isLoading || search.isMutating;

  const fieldValue = get(value, fieldPath);

  return (
    <div>
      <h2 className="py-2 font-semibold text-sm">
        {field.fieldTitle ?? field.fieldPath}
      </h2>
      {multiple ? (
        <Combobox
          multiple
          full
          buttonClasses="w-full"
          loading={loading}
          disabled={loading}
          id={(x) => x.id}
          name={(x) => x.name ?? x.id}
          value={(fieldValue ?? [])
            .map((x: string) => previousOptionsCache.current.get(x))
            .filter((x: string) => x)}
          options={options}
          onChange={(next) => onChange(next, fieldPath)}
          onSearch={canSearch ? search.trigger : undefined}
        />
      ) : (
        <Combobox
          multiple={false}
          full
          buttonClasses="w-full"
          loading={loading}
          disabled={loading}
          id={(x) => x.id}
          name={(x) => x.name ?? x.id}
          value={previousOptionsCache.current.get(fieldValue)}
          options={options}
          onChange={(next) => onChange(next, fieldPath)}
          onSearch={canSearch ? search.trigger : undefined}
        />
      )}
    </div>
  );
};

const IntegrationUserInput: React.FC<{
  nodeData: NodeData;
  execution: WorkflowExecution;
}> = ({ nodeData, execution }) => {
  const submit = useSubmitIntegrationUserInput();
  const [value, setValue] = useState(nodeData.output.data);
  const needInputs: Array<NeedInput> = nodeData.output?.needInputs ?? [];
  const instanceKey = useInstanceKey(execution.workflowId, nodeData.id);

  if (needInputs.length === 0) return null;

  const btnDisabled = needInputs.some(
    (ni) =>
      !get(value, ni.fieldPath) ||
      (Array.isArray(get(value, ni.fieldPath)) &&
        get(value, ni.fieldPath).length === 0)
  );

  return (
    <div className="flex flex-col rounded-lg border border-1 border-slate-200 bg-white p-4">
      <div className="flex items-center gap-3 border-slate-200">
        <ShareIcon className="size-8 rounded-lg border border-amber-500/25 bg-amber-50 p-1.5 text-amber-500" />
        <h2 className="font-semibold">
          <FormattedMessage defaultMessage="Your input is needed" />
        </h2>
      </div>
      <p className="py-3 text-sm">
        <FormattedMessage defaultMessage="We need additional details from you to continue. Please provide the necessary information so we can proceed." />
      </p>

      {needInputs.map((needInput, index) => (
        <UserInputField
          key={`needInput-${index}`}
          value={value}
          field={needInput}
          instanceKey={instanceKey}
          onChange={async (next, path) =>
            setValue(
              set(
                cloneDeep(value),
                path,
                Array.isArray(next) ? next.map((x) => x.id) : next?.id
              )
            )
          }
        />
      ))}

      <Button
        className="mt-5"
        loading={submit.loading}
        disabled={submit.loading || btnDisabled}
        onClick={() =>
          submit.request({
            input: {
              executionId: execution.id,
              nodeId: nodeData.id,
              payload: value,
            },
          })
        }
      >
        <FormattedMessage defaultMessage="Continue" />
      </Button>
    </div>
  );
};

const ConfirmationUserInput: React.FC<{
  nodeData: NodeData;
  execution: WorkflowExecution;
}> = ({ nodeData, execution }) => {
  const [content, setContent] = React.useState(nodeData.output.data.prompt);
  const submitYes = useSubmitWorkflowConfirmation();
  const submitNo = useSubmitWorkflowConfirmation();

  const submitHandler = (isYes: boolean) => {
    const fetcher = isYes ? submitYes : submitNo;
    if (fetcher.loading) {
      return;
    }
    return fetcher.request({
      input: {
        executionId: execution.id,
        nodeId: nodeData.id,
        content,
        isYes,
      },
    });
  };

  if (submitYes.error) throw submitYes.error;
  if (submitNo.error) throw submitNo.error;

  return (
    <div className="flex flex-col rounded-lg border border-1 border-slate-200 bg-white">
      <div className="flex items-center gap-3 border-slate-200 border-b p-4">
        <ConfirmationIcon className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
        <h2 className="font-semibold">
          <FormattedMessage defaultMessage="Confirmation" />
        </h2>
      </div>

      <h2 className="px-4 pt-2 font-semibold text-sm">
        <FormattedMessage defaultMessage="Workflow output" />
      </h2>

      <div className="mx-4 mt-2 h-64 overflow-y-auto rounded-lg border border-slate-200 px-4 py-2">
        <RichTextInput
          value={content}
          isEditable={true}
          onChange={(value) => setContent(value)}
          disableCitations
        />
      </div>
      <div className="flex flex-row justify-center gap-2 px-4 py-3">
        <Button
          variant="secondaryOutline"
          loading={submitNo.loading}
          onClick={(event) => {
            event.preventDefault();
            submitHandler(false);
          }}
          fullWidth
        >
          <FormattedMessage defaultMessage="Decline" />
        </Button>
        <Button
          loading={submitYes.loading}
          onClick={(event) => {
            event.preventDefault();
            submitHandler(true);
          }}
          fullWidth
        >
          <FormattedMessage defaultMessage="Accept" />
        </Button>
      </div>
    </div>
  );
};

export const PendingUserInput: React.FC<{
  execution: WorkflowExecution;
}> = (props) => {
  const { execution } = props;

  return (
    <>
      {execution.nodeData
        .filter((_) => _.status === WorkflowStatus.WAITING_FOR_CONFIRMATION)
        .map((nodeData) =>
          nodeData.output.type === 'integration-user-input' ? (
            <IntegrationUserInput
              key={nodeData.id}
              nodeData={nodeData}
              execution={execution}
            />
          ) : (
            <ConfirmationUserInput
              key={nodeData.id}
              nodeData={nodeData}
              execution={execution}
            />
          )
        )}
    </>
  );
};
