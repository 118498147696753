import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconPicker } from './IconPicker';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { cx } from '../../helpers/utils';

type SpaceNameAndIconProps = {
  spaceIcon: string;
  setSpaceIcon: (icon: string) => void;
  spaceName: string;
  setSpaceName: (name: string) => void;
  isSpaceNameValid: boolean;
  onUpdate?: () => void;
  isUpdateButtonEnabled?: boolean;
  isUpdateButtonLoading?: boolean;
  isReadOnly?: boolean;
};

export const SpaceNameAndIcon: React.FC<SpaceNameAndIconProps> = ({
  spaceIcon,
  setSpaceIcon,
  spaceName,
  setSpaceName,
  isSpaceNameValid,
  onUpdate,
  isUpdateButtonEnabled = false,
  isUpdateButtonLoading = false,
  isReadOnly = false,
}) => {
  const intl = useIntl();
  return (
    <>
      <label
        htmlFor="space-name"
        className={'block pb-2 font-semibold text-slate-600 text-sm leading-6'}
      >
        <FormattedMessage defaultMessage="Icon & name" id="9fPSA1" />
      </label>
      <div className="flex space-x-2">
        {isReadOnly ? (
          <>
            <div className="w-7">{spaceIcon}</div>
            <div>{spaceName}</div>
          </>
        ) : (
          <>
            <div className="h-4 w-12">
              <IconPicker
                selectedIcon={spaceIcon}
                setSelectedIcon={setSpaceIcon}
              />
            </div>
            <TextInput
              error={!isSpaceNameValid}
              id="space-name"
              value={spaceName}
              onChange={setSpaceName}
              placeholder={intl.formatMessage({
                defaultMessage: 'e.g Marketing, Engineering',
                id: 'OGXcUi',
              })}
            />
          </>
        )}
        {onUpdate && !isReadOnly && (
          <Button
            loading={isUpdateButtonLoading}
            onClick={isUpdateButtonEnabled ? onUpdate : () => {}}
            variant="light"
            className={cx(
              !isUpdateButtonEnabled
                ? '!text-slate-300 cursor-not-allowed bg-slate-100 hover:bg-slate-100 focus:bg-slate-100'
                : ''
            )}
          >
            <FormattedMessage defaultMessage="Update" id="BWpuKl" />
          </Button>
        )}
      </div>
    </>
  );
};
