import { SignInPage as SignInPageOld } from './SignInPage';
import { SignInPage as SignInPageNew } from './SignInPageNew';
import featureFlagService from '../../helpers/feature-flags';
import React from 'react';

export const SignInPage: typeof SignInPageOld = (...props) => {
  const isNewLoginPage = featureFlagService.isDec2024SigninEnabled();
  return isNewLoginPage
    ? React.createElement(SignInPageNew, ...props)
    : React.createElement(SignInPageOld, ...props);
};
