import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../../helpers/analytics';
import { selectTeam, selectUserTier } from '../../../redux/selectors';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';

const PreviewOverlayClasses =
  'flex flex-col items-center content-center absolute w-full min-h-52 bottom-0 left-0 backdrop-filter backdrop-blur-sm bg-indigo-100 border-indigo-400 border rounded-lg bg-opacity-35 text-center gap-4 p-3 shadow-md shadow-slate-300';

/**
 *
 * @returns {React.ReactNode} Preview overlay component
 */
export const PreviewOverlay: React.FC = () => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);

  const onShowUpgradeDialog = useCallback(() => {
    trackWebEvent('Preview overlay upgrade button clicked');

    setShowTierPricingDialog(true);
  }, []);

  return (
    <div className={PreviewOverlayClasses}>
      <h6 className="font-semibold text-lg">
        <FormattedMessage
          defaultMessage="You have hit your free transcript limit for that period. Upgrade to access your full transcripts."
          id="+eHHCn"
          description="Transcript preview"
        />
      </h6>
      <p>
        <FormattedMessage
          defaultMessage="This meeting will be automatically deleted after the next cycle."
          id="TYSn4T"
          description="Transcript preview"
        />
      </p>
      <Button onClick={onShowUpgradeDialog}>
        <FormattedMessage
          defaultMessage="Upgrade to Pro now"
          id="p4oH9W"
          description="Transcript preview. Upgrade to Pro button title"
        />
      </Button>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.SETTINGS}
          onClose={() => setShowTierPricingDialog(false)}
        />
      )}
    </div>
  );
};
