import {
  FloatingWrapper,
  PlaceholderExtension,
  Remirror,
  ThemeProvider,
  useMentionAtom,
  useRemirror,
} from '@remirror/react';
import React, { useCallback, useEffect } from 'react';

import {
  MentionAtomExtension,
  MentionExtensionAttributes,
} from 'remirror/extensions';

import { InvalidContentHandler, RemirrorJSON, cx } from '@remirror/core';
import { Mention, MentionType, RemirrorDocument, logger } from '@tactiq/model';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  inputBaseClasses,
  inputWrapperClasses,
} from '../../../../../../../components/TextInput';
import { Button } from '../../../../../../../components/buttons';
import '../../../../../../../styles/prosemirror.css';
import {
  CreateMeetingCommentDocument,
  DeleteMeetingCommentDocument,
  MeetingComment,
} from '../../../../../../../graphql/operations';
import { useMutation } from '@apollo/client';
import { useFullMeeting } from '../../../../../common/meeting-hooks';
import { Trash2 } from 'lucide-react';

const UserSuggester: React.FC<{
  mentions: Mention[];
}> = ({ mentions }) => {
  const [users, setUsers] = React.useState<MentionExtensionAttributes[]>([]);
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } =
    useMentionAtom({
      items: users,
    });

  React.useEffect(() => {
    if (!state) {
      return;
    }

    const searchTerm = state.query.full.toLowerCase();
    const filteredUsers = mentions
      .filter((user) =>
        (user.type === MentionType.USER ? user.displayName : user.email)
          .toLowerCase()
          .includes(searchTerm)
      )
      .sort()
      .slice(0, 5)
      .map((user) => {
        return user.type === MentionType.USER
          ? { id: user.userId, label: user.displayName }
          : { id: user.email, label: user.email };
      });
    setUsers(filteredUsers);
  }, [mentions, state]);

  const enabled = !!state;

  return (
    <FloatingWrapper
      positioner="cursor"
      enabled={enabled}
      placement="bottom-start"
    >
      <div {...getMenuProps()} className="suggestions">
        {enabled &&
          users.map((user, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);
            return (
              <div
                key={user.id}
                className={cx(
                  'suggestion',
                  isHighlighted && 'highlighted',
                  isHovered && 'hovered'
                )}
                {...getItemProps({
                  item: user,
                  index,
                })}
              >
                {user.label}
              </div>
            );
          })}
      </div>
    </FloatingWrapper>
  );
};

export const Comment: React.FC<{
  mentions: Mention[];
  onCancel?: () => void;
  existingComment?: MeetingComment;
  readonly?: boolean;
  relatedBlockId?: string;
  relatedCommentId?: string | null;
  autoFocus?: boolean;
}> = ({
  autoFocus,
  mentions,
  onCancel,
  existingComment,
  readonly,
  relatedBlockId,
  relatedCommentId,
}) => {
  const [content, setContent] = React.useState<RemirrorJSON>();
  const intl = useIntl();
  const extensions = React.useCallback(() => {
    return [
      new MentionAtomExtension({
        extraAttributes: { type: 'user' },
        matchers: [
          {
            name: 'at',
            char: '@',
            matchOffset: 0,
            supportedCharacters: /\S+/,
          },
        ],
      }),
      new PlaceholderExtension({
        placeholder: intl.formatMessage({
          defaultMessage: 'Type @ to mention someone',
          id: 'VzDL9k',
        }),
      }),
    ];
  }, [intl]);
  const meeting = useFullMeeting();

  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      logger.warn('Invalid content in RichTextInput', {
        invalidContent,
      });
      return transformers.remove(json, invalidContent);
    },
    []
  );

  const visual = useRemirror({
    extensions,
    stringHandler: 'text',
    content: '',
    onError,
  });

  useEffect(() => {
    existingComment?.content &&
      visual.getContext()?.setContent(existingComment?.content);
    setContent(undefined);
  }, [visual, existingComment]);

  const cancel = () => {
    existingComment?.content &&
      visual.getContext()?.setContent(existingComment?.content);
    setContent(undefined);
  };

  const clear = () => {
    visual.getContext()?.clearContent();
    setContent(undefined);
  };

  const [saveComment, saveCommentMutation] = useMutation(
    CreateMeetingCommentDocument
  );

  const [deleteComment, deleteCommentMutation] = useMutation(
    DeleteMeetingCommentDocument
  );

  if (!meeting) return null;

  return (
    <div className="w-full">
      <div
        className={
          readonly
            ? ''
            : cx(
                inputWrapperClasses,
                'bg-white ring-slate-300 focus-within:ring-2 focus-within:ring-indigo-600'
              )
        }
      >
        <div
          className={cx(readonly ? '' : inputBaseClasses, 'text-sm md:text-sm')}
        >
          <ThemeProvider>
            <Remirror
              autoFocus={autoFocus}
              editable={!readonly}
              onChange={({ helpers, state }) => {
                if (readonly) return;

                setContent(helpers.getJSON(state));
              }}
              label={intl.formatMessage({
                defaultMessage:
                  'Comment input to mention others for this transcript',
                id: 'J1K7aI',
              })}
              manager={visual.manager}
              autoRender="end"
              placeholder={intl.formatMessage({
                defaultMessage: 'Type @ to mention someone',
                id: 'VzDL9k',
              })}
              classNames={readonly ? ['readonly'] : []}
            >
              <UserSuggester mentions={mentions} />
            </Remirror>
          </ThemeProvider>
        </div>
      </div>
      {content && (
        <div className="flex flex-row justify-end gap-2 pt-2">
          {existingComment ? (
            <Button
              variant="naked"
              startIcon={<Trash2 className="h-4 w-4 text-red-400" />}
              loading={deleteCommentMutation.loading}
              onClick={async () => {
                await deleteComment({ variables: { id: existingComment.id } });
              }}
            >
              <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
            </Button>
          ) : null}

          <Button
            variant="soft"
            onClick={() => {
              if (!saveCommentMutation.loading) {
                cancel();
                onCancel && onCancel();
              }
            }}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>

          <Button
            loading={saveCommentMutation.loading}
            onClick={async () => {
              await saveComment({
                variables: {
                  input: {
                    meetingId: meeting.id,
                    content: content as RemirrorDocument,
                    relatedBlockId,
                    relatedCommentId: relatedCommentId ?? undefined,
                  },
                },
              });

              if (!existingComment) {
                clear();
                onCancel && onCancel();
              }
            }}
          >
            <FormattedMessage defaultMessage="Save" id="jvo0vs" />
          </Button>
        </div>
      )}
    </div>
  );
};
