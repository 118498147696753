import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ListMeetingsDocument, MeetingType } from '../../graphql/operations';
import { trackWebPage } from '../../helpers/analytics';
import {
  gotMeetings,
  RateUsModalSource,
  showRateUsModal,
} from '../../redux/modules/global';
import { Page } from '../Common/Navigation';
import { TranscriptsList } from './list';
import { RootState } from '../../redux/store';
import { SearchPopup } from './common/SearchBar';

const SharedWithMe: React.FC = () => {
  const intl = useIntl();
  useEffect(() => {
    trackWebPage('Shared With Me');
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Shared With Me',
        id: 'Dxdk7c',
        description: 'Menu bar text.',
      })}
      maxWidth={'md'}
    >
      <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
        <h1 className="my-0 text-3xl">
          <FormattedMessage
            defaultMessage="Shared With Me"
            id="EQQDGY"
            description="Shared with me page title."
          />
        </h1>
      </div>
      <LegacyTranscriptsList />
    </Page>
  );
};

const LegacyTranscriptsList: React.FC = () => {
  const dispatch = useDispatch();

  const {
    previousData,
    data = previousData,
    loading,
    fetchMore,
  } = useQuery(ListMeetingsDocument, {
    variables: {
      type: MeetingType.SHAREDWITHME,
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(newData) {
      dispatch(
        gotMeetings({
          type: MeetingType.SHAREDWITHME,
          meetings: newData.meetings.meetings,
        })
      );
    },
  });
  const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);

  const storeMeetings = Object.values(
    useSelector((state: RootState) => state.global.meetings.sharedWithMe)
  );

  const meetings = storeMeetings.length
    ? storeMeetings
    : (data?.meetings.meetings ?? []);

  useEffect(() => {
    if (meetings.length > 10) {
      const timeout = setTimeout(() => {
        dispatch(showRateUsModal(RateUsModalSource.SHARED_MEETING_LIST));
      }, 2500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dispatch, meetings.length]);

  return (
    <TranscriptsList
      meetings={meetings}
      loadMore={async () => {
        if (data?.meetings.hasMore) {
          setIsLoadingMore(true);
          const result = await fetchMore({
            variables: { offset: data.meetings.meetings.length },
          });

          dispatch(
            gotMeetings({
              type: MeetingType.SHAREDWITHME,
              meetings: [...meetings, ...result.data.meetings.meetings],
            })
          );
          setIsLoadingMore(false);
        }
      }}
      isLoading={loading && !meetings.length}
      isLoadingMore={isLoadingMore}
      searchBar={<SearchPopup />}
    />
  );
};

export default SharedWithMe;
