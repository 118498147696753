import { useMutation } from '@apollo/client';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { logger } from '@tactiq/model';
import { PlusIcon } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import {
  UpdateSpacesOrderDocument,
  UserSpace,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { kSpace } from '../../../helpers/routes';
import {
  hideCreateSpaceDialog as hideCreateSpaceDialogAction,
  showCreateSpaceDialog as showCreateSpaceDialogAction,
} from '../../../redux/modules/global';
import {
  selectShowCreateSpaceDialog,
  selectUserSpaces,
} from '../../../redux/selectors';
import { CreateSpaceDialog } from '../../Spaces/CreateSpaceDialog';
import { SpacesItem } from './SpaceItem';
import { updateUserSpacesList } from '../../../redux/modules/user';

/**
 *
 * @param {unknown} param0 params
 * @returns {React.ReactNode} component
 */
export const SpacesWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const showCreateSpaceDialog = useSelector(selectShowCreateSpaceDialog);

  return (
    <div>
      <div className="mt-3 flex h-full flex-col overflow-y-auto overflow-x-hidden border-slate-200 border-t pb-2">
        <div className="flex min-h-10 flex-row items-center justify-between pt-4 pb-3">
          <p className="font-semibold text-sm">
            <FormattedMessage defaultMessage="Spaces" />
          </p>
          <Button
            variant="text"
            onClick={() => {
              dispatch(showCreateSpaceDialogAction());
              trackWebEvent('Clicked Create Space Button', {
                source: 'navigation',
              });
            }}
          >
            <PlusIcon strokeWidth={2} size={20} className="text-slate-400" />
          </Button>
        </div>
        {children}
      </div>
      {showCreateSpaceDialog && (
        <CreateSpaceDialog
          onClose={() => dispatch(hideCreateSpaceDialogAction())}
        />
      )}
    </div>
  );
};

export const SpacesList: React.FC<{
  currentPage: string;
}> = ({ currentPage }) => {
  const dispatch = useDispatch();
  const spacesList: UserSpace[] = useSelector(selectUserSpaces);
  const [updateSpacesOrder] = useMutation(UpdateSpacesOrderDocument);

  const updateOrder = React.useCallback(
    async (newOrder: UserSpace[]) => {
      dispatch(
        updateUserSpacesList(
          [...newOrder].map((s, index) => {
            return {
              ...s,
              order: index,
            };
          })
        )
      );
      await updateSpacesOrder({
        variables: {
          input: newOrder.map((space) => space.id),
        },
      });
    },
    [dispatch, updateSpacesOrder]
  );

  React.useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        if (!source.data) {
          return false;
        } else {
          return true;
        }
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (!sourceData || !targetData) {
          return;
        }

        const indexOfSource = spacesList.findIndex(
          (space) => space.id === sourceData.id
        );
        const indexOfTarget = spacesList.findIndex(
          (space) => space.id === targetData.id
        );
        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }
        const closestEdgeOfTarget = extractClosestEdge(targetData);
        const newOrder = reorderWithEdge({
          list: spacesList,
          startIndex: indexOfSource,
          indexOfTarget,
          closestEdgeOfTarget,
          axis: 'vertical',
        });
        updateOrder(newOrder).catch((e) => logger.error(e));
        const element = document.querySelector(`[data-id="${sourceData.id}"]`);
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element);
        }
      },
    });
  }, [dispatch, spacesList, updateOrder]);

  return (
    <SpacesWrapper>
      {spacesList.map((space) => (
        <SpacesItem
          key={space.id}
          id={space.id}
          label={space.name}
          href={kSpace.replace(':id', space.id)}
          currentPage={currentPage}
          icon={space.icon}
        />
      ))}
    </SpacesWrapper>
  );
};
