import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Page } from '../Common/Navigation';
import { useBlocker, useHref, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWorkflowItem, useWorkflowUpdate } from '../../services/Workflow';
import { Workflow } from '../../graphql/operations';
import { Spinner } from '../../components/Spinner';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import WorkflowEditor, { WorkflowPreview } from './WorkflowEditor';
import { Edge, MarkerType, Node, ReactFlowProvider } from '@xyflow/react';
import {
  workflowItem,
  workflowItemActivity,
  workflowItemExecution,
  workflowList,
} from '../../helpers/routes';
import { ArrowLeft, Group, SquareActivity, Users } from 'lucide-react';
import WorkflowActivity from './WorkflowActivity';
import { useWorkflowId, WorkflowProvider } from './WorkflowIdContext';
import { useNodeTypeToName } from './nodes';
import { useCurrentTeam, useCurrentUserId } from '../../services/User';
import { WorkflowActionsButton } from './WorkflowActionsButton';
import WorkflowSharingModal from './WorkflowSharingModal';
import { DeadEnd } from '../../components/DeadEnd';
import { WorkflowTriggerType } from '@tactiq/model';
import CopyWorkflowLinkButton from './CopyWorkflowLinkButton';
import { ConfirmActionDialog } from '../../components/modals';
import isEqual from 'lodash/isEqual';

export default function WorkflowItem(props: { tab: string }): ReactElement {
  const { id } = useParams();
  const intl = useIntl();
  const [savedOnce, setSavedOnce] = useState(false);
  if (!id) throw new Error('WorkflowItem used outside of route');
  const { data, loading, error } = useWorkflowItem({ id });
  const title = intl.formatMessage({ defaultMessage: 'Workflow' });

  if (error) {
    if (error.message === 'Workflow not found')
      return (
        <Page title={title}>
          <DeadEnd
            title={intl.formatMessage({
              defaultMessage: '😫 Ugh, oh!',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Looks like this workflow was deleted or never existed.',
            })}
            action={
              <Button variant="secondaryOutline" href={workflowList}>
                <FormattedMessage defaultMessage="Go to My Workflows" />
              </Button>
            }
          />
        </Page>
      );
    throw error;
  }

  return (
    <Page title={title} grow maxWidth="full" overflow>
      {loading || !data?.workflow ? (
        <Spinner className="m-auto my-16" size="1.5rem" />
      ) : (
        <WorkflowProvider
          value={{
            nodeCounter: data.workflow.nodeCounter,
            workflowId: data.workflow.id,
            savedOnce,
          }}
        >
          <WorkflowForm
            workflow={data.workflow}
            tab={props.tab}
            setSavedOnce={setSavedOnce}
          />
        </WorkflowProvider>
      )}
    </Page>
  );
}

export function WorkflowForm(props: {
  workflow: Workflow;
  tab: string;
  setSavedOnce: (value: boolean) => void;
}): ReactElement {
  const { tab, setSavedOnce } = props;
  const { id } = props.workflow;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const currentUserId = useCurrentUserId();
  const team = useCurrentTeam();
  const navigate = useNavigate();
  const workflowSave = useWorkflowUpdate(() => setHasUnsavedChanges(false));
  const { nodeCounter } = useWorkflowId();
  const [name, setName] = useState(props.workflow.name);
  const nodeTypeToName = useNodeTypeToName();
  const isOwner = props.workflow.createdBy.uid === currentUserId;
  const [sharingOpen, setSharingOpen] = useState(false);

  // This is generated like this because window.location.href will be the wrong
  // page when the activity view is active.
  const workflowLink = `${window.location.origin}/${useHref(workflowItem.replace(':id', id))}`;

  const [nodes, setNodes] = useState<Node[]>(
    props.workflow.definition.nodes.length > 0
      ? props.workflow.definition.nodes.map((node) => ({
          ...node,
          height: Math.max(node.height ?? 0, 56),
        }))
      : [
          {
            id: 'step0',
            type: 'StartNode',
            position: { x: 0, y: 0 },
            data: {
              displayName: nodeTypeToName.StartNode,
              trigger: { type: WorkflowTriggerType.MANUAL },
            },
          },
        ]
  );
  const [edges, setEdges] = useState<Edge[]>(
    (props.workflow.definition.edges ?? []).map((e) => ({
      ...e,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.Arrow },
    }))
  );

  const input = {
    id: props.workflow.id,
    name,
    nodeCounter,
    definition: {
      nodes: nodes.map((ii: Node) => {
        const { recentExecutionData, ...restData } = ii.data;
        return {
          id: ii.id,
          type: ii.type,
          position: { x: ii.position.x, y: ii.position.y },
          width: ii.width,
          height: ii.height,
          ...ii.measured,
          data: restData,
        };
      }),
      edges: edges.map((e) => ({
        id: e.id,
        target: e.target,
        source: e.source,
        sourceHandle: e.sourceHandle ? e.sourceHandle : undefined,
        label: e.label as string,
      })),
    },
  };

  // Compare current input with the last saved input to check for unsaved changes.
  // We tried to set this in the onChange functions, but our node auto-sizing
  // code makes some changes that are not distinguishable from real edits.
  const lastSavedInput = useRef(input);
  if (!hasUnsavedChanges && !isEqual(input, lastSavedInput.current)) {
    setHasUnsavedChanges(true);
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex justify-between gap-3 p-4">
        <div className="flex flex-grow items-center gap-3">
          <Button
            href={workflowList}
            startIcon={<ArrowLeft size="1rem" />}
            variant="secondaryOutline"
          >
            <FormattedMessage defaultMessage="Exit" />
          </Button>
          {isOwner && tab === 'builder' ? (
            <TextInput
              variant="naked"
              className="max-w-md font-medium text-md"
              value={name}
              onChange={(next) => {
                setName(next);
                setHasUnsavedChanges(true);
              }}
            />
          ) : (
            <div className="max-w-md truncate font-medium text-md">{name}</div>
          )}
        </div>
        <div className="flex flex-grow">
          <div className="flex items-center gap-1 rounded-lg bg-slate-100 p-0.5">
            <Button
              href={workflowItem.replace(':id', id)}
              variant="secondaryOutline"
              className={
                tab === 'builder' ? '' : 'bg-transparent shadow-none ring-0'
              }
              startIcon={<Group size="1rem" />}
            >
              <FormattedMessage defaultMessage="Builder" />
            </Button>
            <Button
              href={workflowItemActivity.replace(':id', id)}
              variant="secondaryOutline"
              className={
                tab === 'activity' ? '' : 'bg-transparent shadow-none ring-0'
              }
              startIcon={<SquareActivity size="1rem" />}
            >
              <FormattedMessage defaultMessage="Activity" />
            </Button>
          </div>
        </div>

        <div className="ml-5 flex items-center gap-4">
          <div className="flex items-stretch gap-2 border-r pr-3">
            {isOwner && (
              <>
                <Button
                  startIcon={<Users size="1rem" />}
                  onClick={() => setSharingOpen(true)}
                  variant="secondaryOutline"
                >
                  <FormattedMessage defaultMessage="Share" />
                </Button>
                <WorkflowSharingModal
                  team={team}
                  open={sharingOpen}
                  workflow={props.workflow}
                  workflowLink={workflowLink}
                  onClose={() => setSharingOpen(false)}
                />
              </>
            )}
            <CopyWorkflowLinkButton
              workflow={props.workflow}
              source="builder"
            />
          </div>
          <WorkflowActionsButton
            workflow={props.workflow}
            loading={workflowSave.loading}
            hasUnsavedChanges={hasUnsavedChanges}
            onSave={
              tab === 'builder' && props.workflow.canModify
                ? async () => {
                    await workflowSave.request({ input }).finally(() => {
                      lastSavedInput.current = input;
                      setSavedOnce(true);
                    });
                  }
                : undefined
            }
            onSuccess={({ executionId }) => {
              navigate(
                workflowItemExecution
                  .replace(':id', id)
                  .replace(':executionId', executionId)
              );
            }}
          />
        </div>
      </div>

      <ReactFlowProvider>
        <div className="h-full w-full flex-grow overflow-auto border-t">
          {tab === 'builder' ? (
            isOwner ? (
              <>
                <WorkflowEditor
                  workflowId={id}
                  nodeCounter={nodeCounter}
                  nodes={nodes}
                  setNodes={setNodes}
                  edges={edges}
                  setEdges={setEdges}
                  nodeDragThreshold={5}
                  recentExecution={props.workflow.recentExecution}
                />
                <UnsavedChangeNavigationBlocker
                  hasUnsavedChanges={hasUnsavedChanges}
                />
              </>
            ) : (
              <WorkflowPreview
                nodes={nodes}
                edges={edges}
                setNodes={setNodes}
              />
            )
          ) : (
            <WorkflowActivity workflowId={id} nodeDragThreshold={5} />
          )}
        </div>
      </ReactFlowProvider>
    </div>
  );
}

function UnsavedChangeNavigationBlocker({
  hasUnsavedChanges,
}: {
  hasUnsavedChanges: boolean;
}): ReactElement | null {
  const blocker = useBlocker(hasUnsavedChanges);

  useEffect(() => {
    const beforeunload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [hasUnsavedChanges]);

  return blocker.state === 'blocked' ? (
    <ConfirmActionDialog
      open
      title={<FormattedMessage defaultMessage="Unsaved changes" />}
      text={
        <FormattedMessage defaultMessage="Are you sure you want to navigate away from this page? Any unsaved changes will be lost." />
      }
      yes={<FormattedMessage defaultMessage="Discard" />}
      yesProps={{ color: 'error' }}
      noProps={{ color: 'info' }}
      onYes={() => blocker.proceed()}
      onNo={() => blocker.reset()}
    />
  ) : null;
}
