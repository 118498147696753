import { useDispatch, useSelector } from 'react-redux';
import { selectAddableSpaces } from '../redux/selectors';
import {
  FetchAllSpacesDocument,
  FetchSpaceDetailsDocument,
  UpdateSpaceOwnerDocument,
  UserSpace,
} from '../graphql/operations';
import { useMutation, useQuery } from '@apollo/client';
import { MutationResult, QueryResult } from './helpers';
import {
  gotSpace,
  showCreateSpaceDialog as showCreateSpaceDialogAction,
} from '../redux/modules/global';

/** Return all spaces a user can add meetings to */
export function useAddableSpaces(): UserSpace[] {
  const addableSpaces = useSelector(selectAddableSpaces);
  return addableSpaces;
}

/**
 * Return a single space
 */
export function useSpace(options: {
  id: string | undefined;
  refetch?: boolean;
}): QueryResult<typeof FetchSpaceDetailsDocument> {
  const { id, refetch } = options;
  const dispatch = useDispatch();
  const { data, error, loading } = useQuery(FetchSpaceDetailsDocument, {
    skip: !id,
    variables: { id: id ?? '' },
    fetchPolicy: refetch ? 'cache-and-network' : 'cache-first',
    // Keep redux in sync while we are still using spaces stored there
    onCompleted(data) {
      data.space && dispatch(gotSpace(data.space));
    },
  });

  return { data, error, loading };
}

/**
 * Return a multiple spaces
 */
export function useSpaceList(options: {
  teamId: string | undefined;
  refetch?: boolean;
}): QueryResult<typeof FetchAllSpacesDocument> {
  const { teamId, refetch } = options;
  const { data, error, loading } = useQuery(FetchAllSpacesDocument, {
    variables: teamId ? { input: { teamId } } : undefined,
    fetchPolicy: refetch ? 'cache-and-network' : 'cache-first',
  });

  return { data, error, loading };
}

/**
 * Transfer ownership of a space
 */
export function useSpaceUpdateOwner(): MutationResult<
  typeof UpdateSpaceOwnerDocument
> {
  const [fn, { data, loading, error }] = useMutation(UpdateSpaceOwnerDocument);

  return {
    request: (variables) => fn({ variables: { input: variables.input } }),
    data,
    error,
    loading,
  };
}

export function useCreateSpaceDialog() {
  const dispatch = useDispatch();
  return () => dispatch(showCreateSpaceDialogAction());
}
