import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MeetingReach } from '../../../../graphql/operations';
import {
  trackTranscriptSharingStarted,
  trackWebEvent,
} from '../../../../helpers/analytics';
import { useMeeting } from '../../common/meeting-hooks';
import { createStopPropagationHandler } from '../../common/stopPropagation';
import { useNavigate } from 'react-router';
import { ShareCardWrapper } from './ShareCardWrapper';
import {
  Cloud,
  Globe2,
  Settings2,
  Share2,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectTeam, selectUid } from '../../../../redux/selectors';
import { isMeetingOwner } from '../../../../helpers/meetings';
import { Tooltip } from '../../../../components/Tooltip';
import { Button } from '../../../../components/buttons';

export const SharingPermissionsCard: React.FC = () => {
  const intl = useIntl();
  const meeting = useMeeting();
  const navigate = useNavigate();
  const team = useSelector(selectTeam);
  const currentUserId = useSelector(selectUid);

  if (!meeting) return null;

  const isOwner = currentUserId && isMeetingOwner(currentUserId, meeting);
  const sharedInSpace =
    meeting.permissions?.allow?.spaces?.filter(Boolean).length;
  const sharedWithUsers = meeting.permissions?.allow.emails.length;
  const sharedPublicly =
    meeting.permissions?.allow.reach === MeetingReach.PUBLIC;

  const noEditPermissionContent = (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          defaultMessage="This meeting is shared with you by another Tactiq user. You don't have permissions to edit it."
          id="CeGEZr"
        />
      }
    >
      <Button variant="icon" size="tiny">
        <Cloud className="size-5 text-slate-600" />
      </Button>
    </Tooltip>
  );

  const isPrivate = !sharedInSpace && !sharedWithUsers && !sharedPublicly;
  if (isPrivate) {
    return (
      <ShareCardWrapper
        title={intl.formatMessage({
          defaultMessage: 'Private meeting',
          id: 'xALIsl',
        })}
        imageComponent={
          <ShieldCheck strokeWidth={2} size={16} className="text-green-600" />
        }
        actions={
          <Tooltip
            title={<FormattedMessage defaultMessage="Share" id="OKhRC6" />}
          >
            {isOwner ? (
              <Button
                variant="icon"
                size="tiny"
                onClick={createStopPropagationHandler(() => {
                  trackTranscriptSharingStarted(
                    'Transcript Sidebar - Access Updates',
                    team?.id
                  );
                  navigate(`/transcripts/${meeting.id}/share`);
                })}
              >
                <Share2 className="size-5 text-slate-600" />
              </Button>
            ) : (
              noEditPermissionContent
            )}
          </Tooltip>
        }
      />
    );
  }

  const sharingStatusIcon = sharedPublicly ? (
    <Globe2 strokeWidth={2} size={16} className="text-slate-600" />
  ) : (
    <Users strokeWidth={2} size={16} className="text-slate-600" />
  );

  let sharingStatusText = '';

  if (sharedPublicly) {
    sharingStatusText = intl.formatMessage({
      defaultMessage: 'Shared with everyone',
      id: 'mdcI0k',
    });
  } else if (!sharedWithUsers) {
    sharingStatusText = intl.formatMessage({
      defaultMessage: 'Shared meeting',
      id: 'U07VLV',
    });
  } else {
    sharingStatusText = intl.formatMessage(
      { defaultMessage: 'Shared with {userCount} users', id: 'd4U6sS' },
      {
        userCount: sharedWithUsers,
      }
    );
  }

  return (
    <ShareCardWrapper
      title={sharingStatusText}
      imageComponent={sharingStatusIcon}
      actions={
        <span>
          {isOwner ? (
            <Tooltip
              title={
                <FormattedMessage
                  defaultMessage="Sharing settings"
                  id="um5I+O"
                />
              }
            >
              <Button
                size="tiny"
                variant="icon"
                onClick={createStopPropagationHandler(() => {
                  trackWebEvent('Clicked Link - Sharing settings', {
                    place: `files-and-shares`,
                  });
                  trackTranscriptSharingStarted(
                    'Transcript Sidebar - Access Updates',
                    team?.id
                  );
                  navigate(`/transcripts/${meeting.id}/share/link`);
                })}
              >
                <Settings2 className="size-5 text-slate-600" />
              </Button>
            </Tooltip>
          ) : (
            noEditPermissionContent
          )}
        </span>
      }
    />
  );
};
