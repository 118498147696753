import React, { ReactElement, useEffect } from 'react';
import { ReactFlow, Controls } from '@xyflow/react';
// The docs say that you need this for functionality - i'm not sure why though
import '@xyflow/react/dist/style.css';

import {
  useWorkflowExecution,
  useWorkflowExecutionList,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { workflowItemActivity } from '../../helpers/routes';
import { useNavigate, useParams } from 'react-router-dom';
import EdgeComponent, { EdgeMarkerDefs } from './edges/EdgeComponent';
import { Background } from './Background';
import { nodeTypes } from './nodes';
import { viewWorkflowHistoryPage } from '../../helpers/analytics';
import { WorkflowExecutionItemRow } from './WorkflowExecutionItemRow';

type Props = {
  workflowId: string;
  nodeDragThreshold: number;
};

export default function WorkflowActivity(props: Props): ReactElement {
  const { workflowId } = props;
  const { data, loading, error } = useWorkflowExecutionList({
    workflowId: props.workflowId,
  });

  useEffect(() => {
    viewWorkflowHistoryPage({ workflowId });
  }, []);

  const navigate = useNavigate();
  const params = useParams<{ executionId: string }>();
  const live = useWorkflowExecution({ id: params.executionId }); // Subscribe to changes
  const firstExecutionId = data?.workflowExecutions[0]?.id;
  const activityPath = workflowItemActivity.replace(':id', workflowId);
  const ex =
    live.data?.workflowExecution ??
    data?.workflowExecutions.find((e) => e.id === params.executionId);

  const nodeData = Object.fromEntries(ex?.nodeData.map((n) => [n.id, n]) ?? []);

  const nodes =
    ex?.definition.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        execution: nodeData[node.id],
      },
    })) ?? [];

  const edges = ex?.definition.edges ?? [];

  useEffect(() => {
    if (!params.executionId && firstExecutionId) {
      navigate(`${activityPath}/${firstExecutionId}`, { replace: true });
    }
  }, [navigate, activityPath, params.executionId, firstExecutionId]);

  if (error) throw error;
  if (loading || !nodes || !edges) {
    return (
      <div className="flex h-full w-full flex-row items-center justify-center">
        <Spinner size="1.5rem" />
      </div>
    );
  }

  return (
    <div className="relative flex h-full w-full flex-grow overflow-auto bg-slate-25">
      <EdgeMarkerDefs />
      <ReactFlow
        key={params.executionId}
        className="border-0"
        nodes={nodes}
        edges={edges}
        maxZoom={1}
        nodeOrigin={[0.5, 0]}
        zoomOnDoubleClick={false}
        fitView
        edgeTypes={{
          EdgeComponent,
          default: EdgeComponent,
          smoothstep: EdgeComponent,
        }}
        edgesReconnectable={false}
        edgesFocusable={false}
        nodeTypes={nodeTypes}
        proOptions={{ hideAttribution: true }}
      >
        <Controls />
        <Background />
      </ReactFlow>
      <div className="flex w-1/3 max-w-96 flex-col gap-2 overflow-auto border-l bg-white p-4">
        {(data?.workflowExecutions ?? []).map((e) => {
          return (
            <WorkflowExecutionItemRow
              key={e.id}
              execution={e}
              isEditorView={true}
              active={e.id === params.executionId}
            />
          );
        })}
      </div>
    </div>
  );
}
