import { DataSchema } from '@integration-app/sdk';

/**
 * Fetch a nested schema via an object path.
 * Mostly it just skips over array and object schema defintions.
 */
export function jsonSchemaGet(schema: DataSchema, pathString: string) {
  const path = pathString.split('.');
  let value: DataSchema | undefined = { ...schema };

  while (path.length > 0 && value && value.type) {
    // Jump into arrays before we try to get deeper keys
    if (value.type === 'array') {
      value = value.items;
    }

    // Grab the next path, exit early if there is nothing to find
    const nextPath = path.shift();
    if (!nextPath || !value || value.type !== 'object') break;

    // At this point we must be an object
    value = value.properties?.[nextPath];
  }

  return value;
}
