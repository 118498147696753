import React from 'react';
import { ShareCardWrapper } from './ShareCardWrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { AddToSpaceMenu } from '../../../Spaces/AddToSpaceMenu';
import { useMeeting } from '../../common/meeting-hooks';
import { Button, TLoading } from '../../../../components/buttons';
import { Tooltip } from '../../../../components/Tooltip';
import { Plus, X, Component } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectUid, selectUserSpaces } from '../../../../redux/selectors';
import { RemoveFromSpaceDocument, Space } from '../../../../graphql/operations';
import { Link } from '../../../../components/Link';
import { enqueueSnackbar } from 'notistack';
import { BasicMeeting } from '../../../../models/meeting';
import { useMutation } from '@apollo/client';
import { isMeetingOwner } from '../../../../helpers/meetings';

const SpaceTab: React.FC<{
  space: Pick<Space, 'id' | 'icon' | 'name'>;
  meeting: BasicMeeting;
  isOwner: boolean;
}> = ({ space, meeting, isOwner }) => {
  const [removeFromSpace, removeSpaceMutation] = useMutation(
    RemoveFromSpaceDocument
  );

  const removeBtn = (
    <>
      {removeSpaceMutation.loading ? (
        <TLoading size="small" />
      ) : (
        <button
          className="size-3"
          onClick={async () => {
            await removeFromSpace({
              variables: {
                input: {
                  meetingId: meeting.id,
                  spaceId: space.id,
                },
              },
            });
            enqueueSnackbar(
              <FormattedMessage
                defaultMessage="Meeting has been removed from the space - {spaceIcon} {spaceName}"
                id="hnzejC"
                values={{
                  spaceIcon: space.icon,
                  spaceName: space.name,
                }}
              />,
              { variant: 'SUCCESS' }
            );
          }}
        >
          <X className="size-3 text-slate-600" />
        </button>
      )}
    </>
  );

  return (
    <div className="flex flex-row flex-nowrap items-center gap-1.5 overflow-hidden rounded-md border border-slate-200 border-solid bg-white px-1.5 py-1">
      <Link noUnderline to={`/space/${space.id}`}>
        <div className="flex flex-row flex-nowrap items-center gap-1.5 text-slate-600 text-xs">
          <span className="flex size-3.5 items-center justify-center">
            {space.icon}
          </span>
          <Tooltip arrow placement="top" title={space.name}>
            <span className="truncate font-medium">{space.name}</span>
          </Tooltip>
        </div>
      </Link>
      {isOwner && removeBtn}
    </div>
  );
};

export const SharedInSpaceCard: React.FC = () => {
  const intl = useIntl();
  const meeting = useMeeting();
  const spaces = useSelector(selectUserSpaces);
  const currentUserId = useSelector(selectUid);

  if (!meeting) {
    return null;
  }

  const isOwner =
    (currentUserId && isMeetingOwner(currentUserId, meeting)) || false;

  const inSpaces = spaces.filter((space) =>
    meeting.permissions?.allow.spaces?.includes(space.id)
  );

  // If the user is not the owner and the meeting is not in any spaces the user has access to, display nothing
  if (!isOwner && inSpaces.length === 0) {
    return null;
  }

  return (
    <ShareCardWrapper
      title={intl.formatMessage({ defaultMessage: 'In Spaces', id: 'QAxaPS' })}
      imageComponent={
        <Component strokeWidth={2} size={16} className="text-slate-600" />
      }
      centerContent={
        <div className="flex w-full flex-row flex-wrap gap-1 p-0.5">
          {inSpaces.map((space) => (
            <SpaceTab
              key={space.id}
              space={space}
              meeting={meeting}
              isOwner={isOwner}
            />
          ))}
        </div>
      }
      actions={
        isOwner ? (
          <AddToSpaceMenu
            meetingId={meeting.id}
            activeSpaces={meeting.permissions?.allow.spaces ?? []}
            trigger={
              <Button size="tiny" variant="icon" onClick={() => {}}>
                <Plus strokeWidth={2} size={18} className="text-slate-600" />
              </Button>
            }
          />
        ) : null
      }
    />
  );
};
