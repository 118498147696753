import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { trackWebEvent } from '../../helpers/analytics';
import { GlobalLoadingClasses, LoadingTextClasses } from '../Common/Loading';

const LandingPageTitleClasses = 'mb-0 font-medium text-2xl text-slate-700';

export const ExistingUserFromExtensionSignedIn: React.FC = () => {
  return (
    <div className={GlobalLoadingClasses}>
      <p className={LandingPageTitleClasses}>
        <FormattedMessage
          defaultMessage="Success!"
          id="blm1LY"
          description="Sign in success page title."
        />
      </p>
      <div className={LoadingTextClasses}>
        <p />
        <FormattedMessage
          defaultMessage="You are ready to join the meetings."
          description="Sign in success page. Ready to join meetings text."
          id="4eHnyM"
        />
      </div>
      <div className={LoadingTextClasses}>
        <Button
          onClick={() => {
            trackWebEvent('Click Link After Authentication', {
              destination: 'googlemeet',
            });
            window.location.href = 'https://meet.google.com';
          }}
        >
          <FormattedMessage
            defaultMessage="Open Google Meet"
            description="Sign in success page. Open Google Meet button"
            id="AAAW+x"
          />
        </Button>
        ,
        <Button
          onClick={() => {
            trackWebEvent('Click Link After Authentication', {
              destination: 'tactiq',
            });
            window.location.href = '/#/';
          }}
        >
          <FormattedMessage
            defaultMessage="Continue to Tactiq"
            id="+PKGUI"
            description="Sign in success page. Continue to Tactiq text."
          />
        </Button>
        <FormattedMessage
          defaultMessage="or"
          description="Sign in success page. or text in `or close this window`."
          id="jOLCP6"
        />
        <Button
          className="ml-1"
          onClick={() => {
            trackWebEvent('Click Link After Authentication', {
              destination: 'close',
            });
            try {
              window.close();
            } finally {
              window.location.href = 'https://meet.google.com';
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Close this window"
            description="Sign in success page. Close this window button"
            id="73bCam"
          />
        </Button>
      </div>
    </div>
  );
};
