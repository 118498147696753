import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createStopPropagationHandler,
  stopPropagation,
} from '../../../../common/stopPropagation';
import { Tag } from '../../../../../../graphql/operations';
import { HIGHLIGHT_TAG_GQL } from '../../../../../../models/meeting';
import { BookmarkMinus, Edit3, MessageSquarePlus, Trash } from 'lucide-react';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Button } from '../../../../../../components/buttons';

export interface HighlightTooltipProps {
  tags: Tag[];
  isHighlight: boolean;
  isEditable: boolean;
  onHighlight: (tag?: Tag) => void;
  onRemoveHighlight: () => void;
  onRemoveBlock?: () => void;
  onEditBlock?: () => void;
  onCommentBlock?: () => void;
}

export const HighlightTooltip: React.FC<HighlightTooltipProps> = ({
  tags,
  isHighlight,
  isEditable,
  onHighlight,
  onRemoveHighlight,
  onRemoveBlock,
  onEditBlock,
  onCommentBlock,
}) => {
  const intl = useIntl();

  const onRemoveBlockClick = useCallback(() => {
    if (
      confirm(
        intl.formatMessage({
          defaultMessage: 'Are you sure you want to remove this block?',
          id: 'GTttw/',
        })
      )
    ) {
      onRemoveBlock && onRemoveBlock();
    }
  }, [intl, onRemoveBlock]);

  const commentButton = onCommentBlock ? (
    <Tooltip
      placement="top"
      title={<FormattedMessage defaultMessage="Comment" id="LgbKvU" />}
    >
      <Button variant="icon" onClick={onCommentBlock}>
        <MessageSquarePlus />
      </Button>
    </Tooltip>
  ) : null;

  const editActions = isEditable ? (
    <span className="nowrap flex flex-row items-center">
      {isHighlight ? (
        <Tooltip
          placement="top"
          title={
            <FormattedMessage defaultMessage="Remove highlight" id="MvrypP" />
          }
        >
          <Button variant="icon" onClick={onRemoveHighlight}>
            <BookmarkMinus />
          </Button>
        </Tooltip>
      ) : (
        [...tags, HIGHLIGHT_TAG_GQL].map((tag) => {
          const handler = createStopPropagationHandler(() => {
            onHighlight(tag.icon === HIGHLIGHT_TAG_GQL.icon ? undefined : tag);
          });

          return (
            <Tooltip key={tag.name} title={tag.name} placement="top">
              <Button variant="icon" onClick={handler}>
                <span className="width-[24px] text-[1rem]">{tag.icon}</span>
              </Button>
            </Tooltip>
          );
        })
      )}
      <div className="mx-1 flex h-6 border-slate-200 border-l"></div>
      {commentButton}
      {onEditBlock && (
        <Tooltip
          placement="top"
          title={<FormattedMessage defaultMessage="Edit" id="wEQDC6" />}
        >
          <Button variant="icon" onClick={onEditBlock}>
            <Edit3 />
          </Button>
        </Tooltip>
      )}
      {onRemoveBlock && (
        <Tooltip
          placement="top"
          title={<FormattedMessage defaultMessage="Remove block" id="g8fdXA" />}
        >
          <Button variant="icon" onClick={onRemoveBlockClick}>
            <Trash />
          </Button>
        </Tooltip>
      )}
    </span>
  ) : (
    commentButton
  );

  return (
    <div
      className="flex w-auto flex-none flex-col rounded border border-slate-200 bg-white p-1 shadow-md"
      onMouseUp={stopPropagation}
      onTouchEnd={stopPropagation}
    >
      <div role="group">{editActions}</div>
    </div>
  );
};
