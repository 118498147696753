import { useMutation } from '@apollo/client';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '../../../components/buttons';
import { UpdateUserSettingDocument } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import icon from '../../../img/zoom.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import { RootState } from '../../../redux/store';
import { SettingsRow } from '../../Common/SettingsRow';
import { AlertNeedMorePermissions } from '../../Setup/AlertNeedMorePermissions';
import { AlertNeedZoomWCRedirect } from '../../Setup/AlertNeedZoomWCRedirect';
import { Switch } from '../../../components/buttons/Switch';
import { selectUserSettings } from '../../../redux/selectors';
import { Alert } from '../../../components/Alert';
import { PermissionsGrantedStatus } from '../../../redux/modules/global';
import { Link } from '../../../components/Link';

const ZoomConnector: React.FC = () => {
  const video = (
    <iframe
      src="https://www.youtube.com/embed/yrcFhoOwr7c"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="aspect-video"
    />
  );

  return (
    <div className="flex flex-col gap-4">
      <ZoomSetupStatus />

      <Button
        href="https://zoom.us/start/webmeeting"
        target="_blank"
        size="large"
        onClick={() => {
          trackWebEvent(
            'Clicked Try Tactiq in Zoom from the Zoom integration page'
          );
          return true;
        }}
      >
        <FormattedMessage
          defaultMessage="Try Tactiq in Zoom"
          id="fGW5gW"
          description="Try Tactiq in Zoom"
        />
      </Button>

      {video}
    </div>
  );
};

export const ZoomSetupStatus: React.FC = () => {
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations?.arePermissionsGranted
  );
  const isRedirectEnabled = useSelector(selectUserSettings).zoomwcredirect;
  const userSettings = useSelector((state: RootState) => state.user.settings);
  const intl = useIntl();

  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  let status;

  if (arePermissionsGranted === PermissionsGrantedStatus.GRANTED) {
    if (isRedirectEnabled) {
      status = (
        <Alert
          severity="success"
          description={
            <FormattedMessage
              defaultMessage="Tactiq is fully configured for this browser"
              id="DpW8Rz"
            />
          }
          action={
            <Button
              target="_blank"
              href="https://zoom.us/start/webmeeting"
              color={'success'}
              onClick={() => {
                trackWebEvent('Integration Page Button - Try in Zoom');
              }}
            >
              <FormattedMessage
                defaultMessage="Try in {brand}"
                id="ZZMTii"
                values={{ brand: 'Zoom' }}
              />
            </Button>
          }
        />
      );
    } else {
      status = <AlertNeedZoomWCRedirect />;
    }
  } else {
    status = <AlertNeedMorePermissions />;
  }

  return (
    <div className="flex flex-col gap-3">
      {status}
      <Alert
        severity="info"
        action={
          <Button
            color="info"
            href="https://zoom.us/profile/setting"
            target="_blank"
          >
            Open Zoom Settings
          </Button>
        }
        description={
          <FormattedMessage
            defaultMessage="You will also need to <link>configure closed captioning</link> in Zoom."
            id="MQtR+8"
            description="Zoom integration. Configure captions message."
            values={{
              link: (chunks) => (
                <Link
                  to="https://help.tactiq.io/en/articles/10128615-tactiq-integration-for-zoom-app"
                  target="_blank"
                  color="secondary"
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        }
      />

      <h2 className="mt-4 text-2xl">
        <FormattedMessage defaultMessage="Settings" id="D3idYv" />
      </h2>

      <SettingsRow
        settingInfo={{
          title: intl.formatMessage({
            defaultMessage: 'Use Zoom Web Client automatically',
            id: 'vuM5u2',
            description:
              'Zoom integration. Use client automatically checkbox label.',
          }),
        }}
        settingAction={
          <Switch
            disabled={updateUserSettingMutation.loading}
            onClick={async () => {
              trackWebEvent('Toggled Zoom Web Client setting', {
                status: !userSettings?.zoomwcredirect,
              });
              await updateUserSetting({
                variables: {
                  input: { zoomwcredirect: !userSettings?.zoomwcredirect },
                },
              });
            }}
            isOn={userSettings?.zoomwcredirect}
          />
        }
      />

      <h2 className="mt-4 text-2xl">
        <FormattedMessage defaultMessage="Connections" id="l9CREU" />
      </h2>
    </div>
  );
};

const integration: IntegrationImplementation = {
  id: 'zoom',
  hidden: true,
  category: IntegrationCategory.Meetings,
  order: 2,
  title: 'Zoom',
  icon,
  description: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    navigate('/integrations/zoomapi');
    return <></>;
  },
  connector: ZoomConnector,
  isConnected: (integrationsState: any) =>
    integrationsState.arePermissionsGranted ===
    PermissionsGrantedStatus.GRANTED,
};

export default integration;
