import React, { ReactElement, useEffect, useState } from 'react';
import { WorkflowExecution, WorkflowStatus } from '../../graphql/operations';
import { formatDistanceStrict } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkflowStatusIcon } from './WorkflowStatusIcon';
import { kMeeting, workflowItemExecution } from '../../helpers/routes';
import { PendingUserInput } from './WorkflowConfirmation';
import { Link } from 'react-router-dom';
import { cx } from '../../helpers/utils';
import { ExternalLink } from 'lucide-react';
import { Tooltip } from '../../components/Tooltip';

export type WorkflowExecutionItemRowProps = {
  active?: boolean;
  isEditorView?: boolean;
  execution: WorkflowExecution;
};

export function WorkflowExecutionItemRow({
  active,
  isEditorView,
  execution,
}: WorkflowExecutionItemRowProps): ReactElement {
  const { formatTime, formatDate } = useIntl();
  const { createdAt } = execution;
  const classes = cx(
    'group flex-shrink-0 overflow-hidden rounded-lg p-3 transition-colors duration-200',
    active
      ? 'bg-slate-200 hover:bg-slate-200'
      : 'bg-slate-50 hover:bg-slate-100'
  );
  const exactTime = `${formatDate(createdAt)} ${formatTime(createdAt)}`;

  const [sinceLastExecution, setSinceLastExecution] = useState<string>(
    formatDistanceStrict(new Date(createdAt), new Date(), {
      addSuffix: true,
    })
  );

  // how often the relative time is updated, update more frequently for
  // running workflows
  let refreshIntervalForRelativeTimeForWorkflowActivity = 60000; // 60 seconds
  if (execution.status === WorkflowStatus.PENDING) {
    refreshIntervalForRelativeTimeForWorkflowActivity = 5000; // 5 seconds
  }

  useEffect(() => {
    const updateRelativeTime = (): void => {
      const newRelativeTime = formatDistanceStrict(
        new Date(createdAt),
        new Date(),
        {
          addSuffix: true,
        }
      );
      setSinceLastExecution(newRelativeTime);
    };

    const intervalId = setInterval(
      updateRelativeTime,
      refreshIntervalForRelativeTimeForWorkflowActivity
    );
    return () => clearInterval(intervalId);
  }, [refreshIntervalForRelativeTimeForWorkflowActivity, createdAt]);

  return (
    <Link
      className={classes}
      to={workflowItemExecution
        .replace(':id', execution.workflowId)
        .replace(':executionId', execution.id)}
    >
      <div
        key={execution.id}
        className={
          'relative flex w-full flex-shrink-0 cursor-pointer items-center gap-1 rounded-md transition-all duration-1000'
        }
      >
        <div className="flex-shrink-0">
          <WorkflowStatusIcon status={execution.status} />
        </div>

        {!isEditorView && (
          <div className="mr-auto truncate text-sm">
            {execution.workflowName}
          </div>
        )}

        <div
          className={
            !isEditorView
              ? 'ml-2 flex-shrink-[10000] truncate text-slate-500 text-xs opacity-0 group-hover:opacity-100'
              : 'mr-auto truncate text-sm'
          }
        >
          <span title={exactTime}>{sinceLastExecution}</span>
        </div>

        {isEditorView ? (
          <Tooltip
            arrow
            title={
              <FormattedMessage defaultMessage="View transcript" id="tS1eQb" />
            }
          >
            <div className="-m-3 p-3 hover:bg-slate-200">
              <Link to={kMeeting.replace(':id', execution.meetingId)}>
                <ExternalLink
                  className="opacity-0 group-hover:opacity-100"
                  size="20px"
                />
              </Link>
            </div>
          </Tooltip>
        ) : (
          ''
        )}
      </div>
      {execution.status === WorkflowStatus.WAITING_FOR_CONFIRMATION && (
        <div className="mt-3 flex flex-col gap-2">
          <PendingUserInput execution={execution} />
        </div>
      )}
    </Link>
  );
}
