import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gotTeam } from '../redux/modules/user';
import { useMutation } from '@apollo/client';
import {
  AddTeamMemberDocument,
  ChangeMemberRolesDocument,
  LeaveTeamDocument,
  RemovePendingInvitationDocument,
  RemoveTeamMemberDocument,
} from '../graphql/operations';
import {
  trackTeamRemoveInvitation,
  trackTeamRemoveMember,
  trackWebEvent,
} from '../helpers/analytics';
import { delay } from '@tactiq/model';

/**
 * Remove a member from a team
 */
export function useRemoveTeamMember(input: {
  userId: string;
  teamId: string;
}): (member: { uid: string }) => Promise<void> {
  const { userId, teamId } = input;
  const dispatch = useDispatch();
  const [leaveTeam, { error: leaveTeamError }] = useMutation(LeaveTeamDocument);
  const [removeTeamMember, { error: removeTeamMemberError }] = useMutation(
    RemoveTeamMemberDocument
  );

  if (leaveTeamError) throw leaveTeamError;
  if (removeTeamMemberError) throw removeTeamMemberError;

  return useCallback(
    async (member: { uid: string }) => {
      if (member.uid === userId) {
        await leaveTeam();
        dispatch(gotTeam(undefined));
      } else {
        dispatch(
          gotTeam(
            (
              await removeTeamMember({
                variables: { input: { memberId: member.uid } },
              })
            ).data?.RemoveTeamMember
          )
        );
      }
      trackTeamRemoveMember({ teamId, memberId: member.uid });
    },
    [userId, teamId, leaveTeam, dispatch, removeTeamMember]
  );
}

/**
 * Remove a pending invitation
 */
export function useRemoveInvitation(): (member: {
  email: string;
}) => Promise<void> {
  const dispatch = useDispatch();
  const [removeTeamInvitation] = useMutation(RemovePendingInvitationDocument);

  return useCallback(
    async (member: { email: string }) => {
      dispatch(
        gotTeam(
          (
            await removeTeamInvitation({
              variables: { input: { memberEmail: member.email } },
            })
          ).data?.RemovePendingInvitation
        )
      );
      trackTeamRemoveInvitation();
    },
    [dispatch, removeTeamInvitation]
  );
}

/**
 * Assign a new admin for the team
 */
export function useAssignNewAdmin(): (memberId: string) => Promise<void> {
  const dispatch = useDispatch();
  const [changeMemberRoles] = useMutation(ChangeMemberRolesDocument);

  return useCallback(
    async (memberId: string) => {
      trackWebEvent('New admin assigned');
      dispatch(
        gotTeam(
          (
            await changeMemberRoles({
              variables: {
                input: {
                  memberId,
                  roles: {
                    ADMIN: true,
                  },
                },
              },
            })
          ).data?.team_changeMemberRoles
        )
      );
    },
    [changeMemberRoles, dispatch]
  );
}

export const useAddTeamMember = (options: {
  onCompleted: () => void;
}): {
  request: (emails: string[]) => Promise<void>;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const { onCompleted } = options;
  const [loading, setLoading] = useState(false);

  const [addTeamMember] = useMutation(AddTeamMemberDocument);

  const request = useCallback(
    async (emails: string[]) => {
      setLoading(true);
      for (const memberEmail of emails) {
        dispatch(
          gotTeam(
            (
              await addTeamMember({
                variables: { input: { memberEmail } },
              })
            ).data?.AddTeamMember
          )
        );
        await delay(1100);
      }

      trackWebEvent('Team - Add Members', {
        count: emails.length,
      });

      setLoading(false);
      onCompleted();
    },
    [onCompleted, dispatch, addTeamMember]
  );

  return {
    request,
    loading,
  };
};
