import React from 'react';
import { Popover } from '@headlessui/react';
import { Button } from '../../components/buttons';
import EmojiPicker from 'emoji-picker-react';

type IconPickerProps = {
  selectedIcon: string;
  setSelectedIcon: (icon: string) => void;
};

export const IconPicker: React.FC<IconPickerProps> = ({
  selectedIcon,
  setSelectedIcon,
}) => {
  return (
    <div className="flex items-center justify-center">
      <Popover className="relative">
        <>
          <Popover.Button
            as={Button}
            variant="secondaryOutline"
            className="!h-9 z-10 flex items-center"
          >
            <div className="text-lg leading-5">{selectedIcon}</div>
          </Popover.Button>

          <Popover.Panel className="absolute z-20 mt-2 w-56 max-w-sm px-4 sm:px-0">
            {({ close, open }) =>
              open ? (
                <EmojiPicker
                  lazyLoadEmojis={true}
                  onEmojiClick={(emoji) => {
                    setSelectedIcon(emoji.emoji);
                    close();
                  }}
                />
              ) : (
                <div />
              )
            }
          </Popover.Panel>
        </>
      </Popover>
    </div>
  );
};
