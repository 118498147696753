import React, {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
const WorkflowIdContext = createContext<{
  nodeCounter?: [counter: number, setCounter: (next: number) => void];
  workflowId?: string;
  savedOnce?: boolean;
}>({});

export function WorkflowProvider(props: {
  value: {
    nodeCounter: number;
    workflowId: string;
    savedOnce: boolean;
  };
  children: any;
}): ReactElement {
  const nodeCounter = useState(props.value.nodeCounter);
  return (
    <WorkflowIdContext.Provider
      value={{
        nodeCounter,
        workflowId: props.value.workflowId,
        savedOnce: props.value.savedOnce,
      }}
    >
      {props.children}
    </WorkflowIdContext.Provider>
  );
}

export function useWorkflowId(): {
  nodeCounter: number;
  newId: () => string;
  workflowId: string;
} {
  const context = useContext(WorkflowIdContext);
  if (context === null)
    throw new Error('useWorkflowId used outside of WorkflowIdProvider');

  return useMemo(
    () => ({
      workflowId: context.workflowId ?? '',
      nodeCounter: context.nodeCounter?.[0] ?? 0,
      newId: () => {
        const [counter, setCounter] = context.nodeCounter ?? [0, () => {}];
        const next = counter + 1;
        setCounter(next);
        return `step${next}`;
      },
    }),
    [context]
  );
}

export function useSavedOnce(): boolean {
  const context = useContext(WorkflowIdContext);
  if (context === null)
    throw new Error('useSavedOnce used outside of WorkflowIdProvider');
  return context.savedOnce ?? false;
}
