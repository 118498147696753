import { useMutation } from '@apollo/client';
import { validate } from 'email-validator';
import difference from 'lodash/difference';
import React, { ClipboardEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckEmailIsInTeamDocument } from '../../graphql/operations';
import { Alert } from '../../components/Alert';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { Chip } from '../../components/Chips';
import { Tooltip } from '../../components/Tooltip';
import { Link } from '../../components/Link';
import { useAddTeamMember } from '../../services/Team';

export interface EmailsInputProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  onClose: () => void;
}

/**
 * Emails input
 */
export const EmailsInput: React.FC<EmailsInputProps> = ({
  emails,
  setEmails,
  onClose,
}) => {
  const intl = useIntl();
  const [currentEmail, setCurrentEmail] = useState('');
  const [error, setError] = useState(false);
  const [emailstoError, setEmailstoError] = useState<string[]>([]);

  const addTeamMember = useAddTeamMember({ onCompleted: onClose });
  const [checkEmailIsInTeam] = useMutation(CheckEmailIsInTeamDocument);

  const handleOnAddClick = useCallback(async () => {
    if (emails.length && !error && emailstoError.length === 0) {
      await addTeamMember.request(emails);
    }
  }, [addTeamMember, emails, emailstoError.length, error]);

  const onChangeValidation = useCallback(
    (isValid: boolean) => {
      setError(isValid);
    },
    [setError]
  );

  useEffect(() => {
    onChangeValidation(currentEmail ? !validate(currentEmail) : false);
  }, [currentEmail, onChangeValidation]);

  const onPaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const incoming = event.clipboardData.getData('text/plain');
      const separator = /\s*(?:;|,|\s|$)\s*/;
      const parts = incoming.split(separator).filter((p) => p);

      if (parts.length > 1) {
        const toAdd = parts.filter((part) => validate(part));
        if (toAdd.length) {
          setEmails([...emails, ...toAdd]);
        }
        setCurrentEmail('');
        event.preventDefault();
        return false;
      }
    },
    [emails, setEmails]
  );

  const onChangeEmails = useCallback(
    async (newEmails: string[]) => {
      difference(newEmails, emails).forEach(async (email) => {
        const result = await checkEmailIsInTeam({
          variables: { input: { email } },
        });
        if (!result.data?.checkEmailIsInTeam?.success) {
          setEmailstoError([...emailstoError, email]);
        }
      });
      setEmails(newEmails);
      onChangeValidation(false);
    },
    [emails, setEmails, onChangeValidation, checkEmailIsInTeam, emailstoError]
  );

  const onDeleteEmail = useCallback(
    async (email: string) => {
      await onChangeEmails(emails.filter((e) => e !== email));
      setEmailstoError(emailstoError.filter((e) => e !== email));
    },
    [emails, emailstoError, onChangeEmails]
  );

  const onBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      const newEmail = event.target.value.trim();

      if (newEmail) {
        const isValid = validate(newEmail);

        if (isValid) {
          await onChangeEmails([...emails, newEmail]);
          setCurrentEmail('');
        } else {
          onChangeValidation(true);
        }
      }
    },
    [emails, onChangeEmails, onChangeValidation, setCurrentEmail]
  );

  const onKeyPress = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (event.key === 'Enter' || event.key === ' ' || event.key === ',') &&
        currentEmail.trim() &&
        !error
      ) {
        await onChangeEmails([...emails, currentEmail.trim()]);
        setCurrentEmail('');
        event.preventDefault();
        return false;
      }
    },
    [currentEmail, error, onChangeEmails, emails]
  );

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-md">
        <FormattedMessage
          defaultMessage="Invite by email"
          description="Team invitation by email. Title."
          id="TYHQDJ"
        />
      </h3>
      {!!emailstoError.length && (
        <Alert
          variant="light"
          severity="warning"
          description={
            <FormattedMessage
              defaultMessage="Oops! The user you’re inviting is already on a team. They’ll need to exit their current team before you can invite them. {link}"
              id="iCDaXF"
              values={{
                link: (
                  <Link
                    blue
                    target="_blank"
                    to="https://help.tactiq.io/en/articles/8039046-leaving-a-team-to-join-another"
                  >
                    <FormattedMessage
                      defaultMessage="Learn how to leave a team."
                      id="kQ6kz/"
                    />
                  </Link>
                ),
              }}
            />
          }
        />
      )}
      <div className="flex items-end gap-2">
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage:
              'Enter email addresses, separated by space or comma',
            id: 'F4Fc66',
          })}
          value={currentEmail}
          autoFocus
          error={error}
          onChange={(val) => setCurrentEmail((val ?? '').trim().toLowerCase())}
          type="email"
          inputProps={{ onPaste, onKeyPress, onBlur }}
        />

        <Button
          onClick={async () => {
            if (currentEmail.trim() && !error) {
              await onChangeEmails([...emails, currentEmail.trim()]);
              setCurrentEmail('');
            }
          }}
        >
          <FormattedMessage defaultMessage="Add" id="2/2yg+" />
        </Button>
      </div>

      <div className="m-0 flex list-none flex-wrap justify-center gap-2 p-2">
        {emails.map((x) =>
          emailstoError.includes(x) ? (
            <li key={x}>
              <Tooltip
                title={intl.formatMessage({
                  defaultMessage:
                    'This user is already in another team and will need to leave that team before joining this one',
                  id: 'J4v8Yt',
                })}
              >
                <Chip color="yellow" onDelete={() => onDeleteEmail(x)}>
                  {x}
                </Chip>
              </Tooltip>
            </li>
          ) : (
            <li key={x}>
              <Chip onDelete={() => onDeleteEmail(x)}>{x}</Chip>
            </li>
          )
        )}
      </div>

      <div className="flex justify-end gap-4">
        <Button onClick={onClose} variant="secondary">
          <FormattedMessage
            defaultMessage="Cancel"
            id="xzQXp4"
            description="Team view. Add members dialog. Cancel button title"
          />
        </Button>
        <Button onClick={handleOnAddClick} loading={addTeamMember.loading}>
          <FormattedMessage
            defaultMessage="Send invites"
            id="HaKNip"
            description="Team view. Add members dialog. Add button title"
          />
        </Button>
      </div>
    </div>
  );
};
