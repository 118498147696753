import { gql, useQuery } from '@apollo/client';
import { Table } from '../../../components/Table';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/buttons';
import { Loading } from '../../Landing/Loading/LoadingComponent';
import { Query } from '../../../graphql/operations';
import {
  trackWebPage,
  trackReportOwnSaveAsCSVClicked,
} from '../../../helpers/analytics';
import { baseURL } from '../../../helpers/firebase/config';
import {
  getUserFriendlyPlatform,
  toHrMinSec,
} from '../../../helpers/transcript';
import { Page } from '../../Common/Navigation';
import { FileSpreadsheet } from 'lucide-react';
import { saveAsCsv } from '../../../helpers/save-as-csv';

const reportOwnMeetingsQuery = gql`
  query reportOwnMeetings {
    reportOwnMeetings {
      meetings {
        id
        title
        timestamp
        speechDurationSeconds
        platform
        speakers
        speakerCount
      }
    }
  }
`;

export const ReportOwnMeetings: React.FC = () => {
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Report - Own Meetings');
  }, []);

  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery<Query>(reportOwnMeetingsQuery);

  let report: React.ReactNode;

  if (data) {
    const rows =
      data.reportOwnMeetings?.meetings.map((m) => ({
        id: m.id,
        title: m.title,
        timestamp: new Date(m.timestamp),
        timestampDate: new Date(m.timestamp),
        timestampTime: new Date(m.timestamp).toLocaleTimeString(
          navigator.language,
          { hour: '2-digit', minute: '2-digit' }
        ),
        speechDurationSeconds: toHrMinSec(
          m.speechDurationSeconds * 1000,
          true,
          false
        ),
        speechDurationMinutes: Math.ceil(m.speechDurationSeconds / 60),
        platform: getUserFriendlyPlatform(m.platform, intl),
        speakerCount: m.speakerCount,
        speakers: m.speakers.join(', '),
        speakersArray: m.speakers,
        link: `${baseURL}/#/transcripts/${m.id}`,
      })) ?? [];

    const onSaveAsCsv = () => {
      trackReportOwnSaveAsCSVClicked();

      saveAsCsv(
        [
          'Started',
          'Title',
          'Duration',
          'Platform',
          'Number of speakers',
          'Speakers',
          'Link',
        ],
        rows.map((meeting) => [
          meeting.timestamp.toLocaleString(),
          meeting.title,
          meeting.speechDurationSeconds,
          meeting.platform,
          meeting.speakerCount.toString(),
          meeting.speakers,
          `${baseURL}/#/transcripts/${meeting.id}`,
        ]),
        'Tactiq_meetings_report.csv'
      );
    };

    const renderTableActions = () => {
      return (
        <Button
          size="small"
          startIcon={<FileSpreadsheet className="h-4 w-4 text-current" />}
          onClick={onSaveAsCsv}
          variant="secondaryOutline"
        >
          <FormattedMessage defaultMessage="Save as CSV" id="YYMipA" />
        </Button>
      );
    };

    report = (
      <div className="max-width-[900px] mt-8 w-full">
        <Table
          search={true}
          data={rows}
          initialPageSize={10}
          columns={[
            {
              id: 'timestamp',
              header: () => (
                <FormattedMessage defaultMessage="Started" id="TDUfVk" />
              ),
              cell: ({ row }) => (
                <span className="w-10 text-ellipsis whitespace-nowrap text-sm">
                  {row.original.timestamp.toLocaleString()}
                </span>
              ),
              enableSorting: true,
              accessorFn: (row) => row.timestamp,
            },
            {
              id: 'title',
              header: () => (
                <FormattedMessage defaultMessage="Title" id="9a9+ww" />
              ),
              cell: ({ row }) => (
                <span className="font-medium text-slate-800 text-sm">
                  {row.original.title}
                </span>
              ),
              accessorFn: (row) => row.title,
            },
            {
              id: 'speechDurationMinutes',
              header: () => (
                <FormattedMessage defaultMessage="Duration" id="IuFETn" />
              ),
              enableSorting: true,
              cell: ({ row }) => (
                <span className="whitespace-nowrap text-sm">
                  {row.original.speechDurationMinutes}
                  {' min'}
                </span>
              ),

              accessorFn: (row) => row.speechDurationMinutes,
            },
            {
              id: 'speakerCount',
              header: () => (
                <FormattedMessage defaultMessage="Speakers Count" id="4hazLp" />
              ),
              enableSorting: true,
              enableColumnFilter: true,
              cell: ({ row }) => (
                <span className="text-sm">{row.original.speakerCount}</span>
              ),
              accessorFn: (row) => row.speakerCount,
            },
            {
              id: 'speakers',
              header: () => (
                <FormattedMessage defaultMessage="Speakers" id="oM1Qdm" />
              ),

              enableSorting: true,
              enableColumnFilter: true,
              cell: ({ row }) => (
                <span className="text-sm">
                  {row.original.speakersArray.join(', ')}
                </span>
              ),
              accessorFn: (row) => row.speakersArray,
            },
            {
              id: 'platform',
              header: () => (
                <FormattedMessage defaultMessage="Platform" id="Mmuj1R" />
              ),

              enableSorting: true,
              enableColumnFilter: true,
              cell: ({ row }) => (
                <span className="whitespace-nowrap text-sm">
                  {row.original.platform}
                </span>
              ),

              meta: {
                filterLabel: (
                  <FormattedMessage defaultMessage="Platform" id="Mmuj1R" />
                ),
              },
              accessorFn: (row) => row.platform,
            },
            {
              id: 'link',
              header: () => (
                <FormattedMessage defaultMessage="Link" id="JBWS0c" />
              ),
              cell: ({ row }) => (
                <Button
                  variant="secondary"
                  size="small"
                  href={row.original.link}
                  target="_blank"
                >
                  <FormattedMessage defaultMessage="View" id="FgydNe" />
                </Button>
              ),
            },
          ]}
          actions={renderTableActions}
        />
      </div>
    );
  }

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Reporting',
        id: 'reFEEC',
      })}
      grow
      maxWidth={'lg'}
    >
      <h1 className="mb-4 text-3xl">
        <FormattedMessage defaultMessage="Report: Own Meetings" id="Jmj4vQ" />
      </h1>
      {loading ? (
        <div className="flex w-full grow items-center justify-center">
          <Loading />
        </div>
      ) : (
        report
      )}
    </Page>
  );
};
