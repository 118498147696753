import { ApolloClientFactory } from './client';
import {
  CancelDataMoveDocument,
  AcceptDataMoveDocument,
  RequestDataMoveDocument,
  RequestDataMoveInput,
  SetGoogleDriveStorageDocument,
  SetGoogleDriveStorageInput,
  SetRequestedDeletionDocument,
  SetRequestedDeletionInput,
} from './operations';

export const setRequestedDeletion = async (
  input: SetRequestedDeletionInput
): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: SetRequestedDeletionDocument,
    variables: {
      input,
    },
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }
};

export const setGoogleDriveStorage = async (
  input: SetGoogleDriveStorageInput
): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: SetGoogleDriveStorageDocument,
    variables: {
      input,
    },
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }
};

export const requestDataMove = async (
  input: RequestDataMoveInput
): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: RequestDataMoveDocument,
    variables: {
      input,
    },
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }
};

export const cancelDataMove = async (): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: CancelDataMoveDocument,
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }
};

export const acceptDataMove = async (): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: AcceptDataMoveDocument,
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }
};
