import React, { useCallback, useContext } from 'react';
import { ExtendedTranscriptBlock, ViewMode } from '../../types';
import { BlockGroup, getDisplayTimestamp } from '@tactiq/model';
import { Quote } from './Quote';
import { SelectionObserverContext } from '../selection-observer';
import { BlockHighlightTooltip } from './HighlightTooltip';
import { SpeakerName } from './SpeakerName';
import { BlockCommentList } from './comment';
import {
  Tag,
  UserSettingsAutosaveTimestampOption,
} from '../../../../../graphql/operations';
import { toModelTimestampOption } from '../../../../../helpers/transcript';
import { HIGHLIGHT_TAG_GQL } from '../../../../../models/meeting';
import { FuseResultMatch } from 'fuse.js';
import { TextInput } from '../../../../../components/TextInput';
import { cx } from '../../../../../helpers/utils';
import { Tooltip } from '../../../../../components/Tooltip';

const BlockWrapperClasses = `relative flex flex-col p-1 mb-0 grow `;

const BlockTag: React.FC<{ tag: Tag }> = ({ tag }) => {
  return (
    <Tooltip key={tag.icon} title={tag.name}>
      <div
        className={
          'flex max-w-fit items-center gap-x-1.5 rounded-md border border-transparent bg-slate-200/50 px-1.5 py-0.5 font-semibold text-slate-600 text-xs transition-colors hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
        }
      >
        <div className="flex flex-col justify-center text-sm">{tag.icon}</div>
        <div className="max-w-18 truncate">{tag.name}</div>
      </div>
    </Tooltip>
  );
};

/**
 * Block
 * @param {unknown} props props
 * @param {string} props.speakerColor speakerColor
 * @param {ViewMode} props.viewMode viewMode
 * @param {boolean} props.isHighlight isHighlight
 * @param {boolean} props.isEditable isEditable
 * @param {boolean} props.isArchived isArchived
 * @param {number} props.firstTimestamp firstTimestamp
 * @param {UserSettingsAutosaveTimestampOption} props.timestampOption timestampOption
 * @param {boolean} props.showSpeakerName showSpeakerName
 * @param {BlockGroup<ExtendedTranscriptBlock>} props.group group
 * @param {FuseResultMatch[]} props.currentMatches currentMatches
 * @param {(event: React.MouseEvent) => void} props.onClick onClick
 * @param {(messageIds: string[], newTranscript: string) => void} props.onChange onChange
 * @param {React.ReactNode} props.actions actions
 * @returns {React.FC} component
 */
export const Block: React.FC<{
  speakerColor: string;
  viewMode: ViewMode;
  isEditable: boolean;
  isArchived: boolean;
  firstTimestamp: number;
  timestampOption: UserSettingsAutosaveTimestampOption;
  showSpeakerName?: boolean;
  group: BlockGroup<ExtendedTranscriptBlock>;
  currentMatches?: readonly FuseResultMatch[];
  onClick?: (event: React.MouseEvent) => void;
  onChange?: (messageIds: string[], newTranscript: string) => void;
  actions?: React.ReactNode;
}> = (props) => {
  const {
    editingGroupId,
    commentParams,
    selection,
    onBlockSelection,
    onBlockSelectionClear,
    onEditBlock,
  } = useContext(SelectionObserverContext);
  const {
    speakerColor,
    group,
    timestampOption,
    firstTimestamp,
    currentMatches,
    viewMode,
    showSpeakerName = true,
    isEditable,
    isArchived,
    onClick,
    onChange,
    actions,
  } = props;

  const { timestamp, speakerName, messageId } = group;
  const { matches: speakerMatches } = group.blocks[0];

  const formattedTimestamp = getDisplayTimestamp(
    toModelTimestampOption(timestampOption),
    timestamp,
    firstTimestamp
  );

  const canEdit = Boolean(
    isEditable && group.type !== 'screenshot' && onChange
  );
  const isEditing = canEdit && group.messageId === editingGroupId;
  const isCommenting = group.messageId === commentParams?.groupId;

  const onMouseEnter = useCallback(() => {
    if (!selection?.isTextSelection && !isEditing) {
      onBlockSelection(messageId, Array.from(group.messageIds));
    }
  }, [selection, isEditing, onBlockSelection, messageId, group.messageIds]);

  const onMouseLeave = useCallback(() => {
    if (!selection?.isTextSelection) {
      onBlockSelectionClear();
    }
  }, [selection, onBlockSelectionClear]);

  const onTextChange = useCallback(
    (newTranscript: string) => {
      if (newTranscript !== group.transcript && onChange) {
        onChange(Array.from(group.messageIds), newTranscript);
      }
      onEditBlock(null);
    },
    [group, onChange, onEditBlock]
  );

  const tags = group.tags?.map((tag) => <BlockTag key={tag.icon} tag={tag} />);

  if (group.isPinned) {
    tags.unshift(
      <BlockTag key={HIGHLIGHT_TAG_GQL.icon} tag={HIGHLIGHT_TAG_GQL} />
    );
  }

  let speakerNameElement = null;

  if (showSpeakerName) {
    speakerNameElement = (
      <SpeakerName
        refIndex={group.blocks[0].refIndex}
        speakerName={speakerName}
        speakerColor={speakerColor}
        groupType={group.type}
        currentMatches={currentMatches}
        speakerMatches={speakerMatches}
      />
    );
  }

  return (
    <div>
      <div
        className={cx(
          'tactiq-block',
          BlockWrapperClasses,
          'group rounded-lg border border-transparent p-2 selection:bg-brand-200 selection:text-slate-800 hover:border-brand-50 hover:bg-brand-25/50 sm:px-4 sm:py-3'
        )}
        data-tactiq-group-id={group.messageId}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div>{speakerNameElement}</div>

        <div
          className={cx(
            'flex flex-row items-start gap-x-4 gap-y-1 sm:flex-row md:gap-x-6 lg:gap-x-8',
            showSpeakerName ? 'mt-1.5' : ''
          )}
        >
          <div
            className={cx(
              'mt-[5px] flex h-min min-w-8 select-none flex-col items-end md:w-12'
            )}
          >
            <div className="font-medium text-slate-400 text-xs group-hover:text-slate-600">
              {formattedTimestamp}
            </div>
          </div>

          <div className="flex-1 flex-col">
            {Boolean(
              !isArchived && selection && selection.groupId === group.messageId
            ) && <BlockHighlightTooltip group={group} isEditable={canEdit} />}
            {isEditing ? (
              <TextInput
                type="textarea"
                autoFocus
                value={group.transcript}
                onBlur={onTextChange}
              />
            ) : (
              group.blocks.map(
                (
                  {
                    isPinned,
                    type,
                    transcript,
                    translatedTranscript,
                    matches,
                    messageId: blockMessageId,
                    refIndex,
                    tags: blockTags = [],
                  },
                  index
                ) => {
                  const isLastBlock = index === group.blocks.length - 1;

                  return (
                    <span key={blockMessageId}>
                      <Quote
                        messageId={blockMessageId}
                        refIndex={refIndex}
                        transcript={transcript}
                        timestamp={timestamp}
                        type={type}
                        translatedTranscript={translatedTranscript}
                        viewMode={viewMode}
                        isPinned={isPinned ?? blockTags.length > 0}
                        tags={blockTags}
                        matches={matches}
                        currentMatches={currentMatches}
                      />
                      {!isLastBlock && ' '}
                    </span>
                  );
                }
              )
            )}
            <div className="mt-2">{tags}</div>
          </div>

          {actions && (
            <div className="col-span-6 row-start-1 md:col-span-2">
              <div className="flex justify-end">{actions}</div>
            </div>
          )}
        </div>
      </div>
      {!actions && (
        <BlockCommentList
          groupId={group.messageId}
          isCommenting={isCommenting}
        />
      )}
    </div>
  );
};
