import React from 'react';
import { FormattedMessage } from 'react-intl';
import { trackWebEvent } from '../../helpers/analytics';
import { Button } from '../../components/buttons';
import { Alert } from '../../components/Alert';

type SettingsInfoProps = {
  title: string;
  description?: React.ReactNode;
  helpLink?: {
    condition?: boolean;
    message: React.ReactNode;
    url: string;
  };
};

type RequiresUpgadeProps = {
  condition: boolean;
  prompt?: React.ReactNode;
  action: () => void;
  chipMessage?: React.ReactNode;
  reason: string;
};

export const SettingsRow: React.FC<{
  settingInfo: SettingsInfoProps;
  settingAction: React.ReactNode;
  overriddenByTeam?: boolean;
  requiresUpgrade?: RequiresUpgadeProps;
}> = ({ settingInfo, settingAction, requiresUpgrade, overriddenByTeam }) => {
  const shouldShowHelpLink = settingInfo.helpLink?.condition ?? true;
  if (requiresUpgrade?.condition) {
    return (
      <li className="flex list-none flex-row items-center justify-between gap-4 py-5 md:gap-6">
        <div className="flex flex-col items-start gap-1.5">
          <div className="font-semibold text-lg text-slate-900 leading-6">
            {settingInfo.title}
          </div>
          {settingInfo.description && (
            <div className="text-slate-500 text-sm leading-5">
              {settingInfo.description}
            </div>
          )}
          {requiresUpgrade.prompt && (
            <Alert severity="info" description={requiresUpgrade.prompt} />
          )}
        </div>
        <Button
          color="primary"
          onClick={() => {
            requiresUpgrade.action();
            trackWebEvent('Settings - Upgrade chip clicked', {
              setting: settingInfo.title,
              reason: requiresUpgrade.reason,
            });
          }}
        >
          {requiresUpgrade.chipMessage ? (
            requiresUpgrade.chipMessage
          ) : (
            <FormattedMessage
              defaultMessage="Upgrade your account"
              id="SZKtph"
            />
          )}
        </Button>
      </li>
    );
  }
  return (
    <li className="flex list-none flex-row justify-between gap-4 py-5 md:gap-6">
      <div className="min-w-0 items-center">
        <div className="flex flex-col items-start gap-1.5">
          <div className="font-semibold text-lg text-slate-900 leading-6">
            {settingInfo.title}
          </div>

          {settingInfo.description && (
            <div className="mt-1 text-slate-500 text-sm leading-5">
              {settingInfo.description}
            </div>
          )}
          {shouldShowHelpLink &&
            settingInfo.helpLink?.message &&
            settingInfo.helpLink?.url && (
              <Alert
                action={
                  <Button
                    href={settingInfo.helpLink.url}
                    target="_blank"
                    variant="outlined"
                    onClick={() => {
                      trackWebEvent(
                        'Clicked article link – Go to Help Center article',
                        {
                          article: settingInfo.helpLink?.url,
                        }
                      );
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Read the article"
                      id="Up4VfN"
                    />
                  </Button>
                }
                description={settingInfo.helpLink.message}
                severity="info"
              />
            )}
          {overriddenByTeam ? (
            <Alert
              description={
                <FormattedMessage
                  defaultMessage="This setting is controlled by your team administrator."
                  id="ZdwXZY"
                />
              }
              severity="warning"
            />
          ) : null}
        </div>
      </div>
      <div className="flex items-center">{settingAction}</div>
    </li>
  );
};
