import { GripVertical, HashIcon } from 'lucide-react';
import React from 'react';
import { cx } from '../../../helpers/utils';
import { BadgeNew } from '../BadgeNew';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { Tooltip } from '../../../components/Tooltip';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const spaceDataKey = Symbol('spaceItem');

type SpaceData = { [spaceDataKey]: true; id: SpaceItemData['id'] };

function getSpaceData(id: string): SpaceData {
  return { [spaceDataKey]: true, id };
}

function isSpaceData(
  data: Record<string | symbol, unknown>
): data is SpaceData {
  return data[spaceDataKey] === true;
}

export type SpaceItemData = {
  id: string;
  href: string;
  label: string;
  currentPage: string;
  isNew?: boolean;
  isSubItem?: boolean;
  isDisabled?: boolean;
  icon?: string;
};

type SpaceState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
      rect: DOMRect;
    }
  | {
      type: 'is-dragging';
    }
  | {
      type: 'is-dragging-over';
      closestEdge: Edge | null;
    };

const idle: SpaceState = { type: 'idle' };

export const SpacesItem: React.FC<SpaceItemData> = ({
  id,
  href,
  label,
  currentPage,
  isNew = false,
  isSubItem = false,
  isDisabled = false,
  icon,
}) => {
  const intl = useIntl();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [state, setState] = React.useState<SpaceState>(idle);

  React.useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    if (!id) {
      return;
    }
    return combine(
      draggable({
        element,
        getInitialData() {
          return getSpaceData(id);
        },
        onGenerateDragPreview({ source }) {
          const rect = source.element.getBoundingClientRect();
          setState({ type: 'preview', container: element, rect });
        },
        onDragStart() {
          setState({ type: 'is-dragging' });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          if (source.element === element) {
            return false;
          }
          return isSpaceData(source.data);
        },
        getData({ input }) {
          const data = getSpaceData(id);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: 'is-dragging-over', closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          // preventing unnecessary re-renders
          setState((current) => {
            if (
              current.type === 'is-dragging-over' &&
              current.closestEdge === closestEdge
            ) {
              return current;
            }
            return { type: 'is-dragging-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      })
    );
  }, [id]);

  return (
    <div className="group">
      <div
        className={cx(
          'relative rounded-lg group-hover:bg-slate-50',
          label === currentPage ? 'bg-slate-50' : ''
        )}
      >
        <div
          data-task-id={id}
          ref={ref}
          className={cx(
            'flex w-full flex-nowrap items-center justify-between rounded-lg p-1 group-hover:bg-slate-50',
            state.type === 'is-dragging' ? 'opacity-40' : ''
          )}
        >
          <Link
            className={cx(
              'group relative flex min-h-10 w-full flex-row items-center gap-x-3 overflow-hidden rounded-lg leading-6 md:bg-none',
              label === currentPage ? 'bg-slate-50' : '',
              isDisabled ? 'cursor-not-allowed opacity-50' : ''
            )}
            to={href}
            onClick={(e) => isDisabled && e.preventDefault()}
            aria-disabled={isDisabled}
          >
            <div
              className={cx(
                label === currentPage ? 'text-brand-600' : 'text-slate-400',
                isSubItem ? 'ml-11' : ''
              )}
            >
              {icon ? (
                <p className="font-['emoji'] text-lg">{icon}</p>
              ) : (
                <HashIcon strokeWidth={2} size={20} />
              )}
            </div>
            <div
              className={cx(
                'overflow-hidden text-ellipsis',
                isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
            >
              <div className="flex flex-row items-center gap-x-3">
                <Tooltip title={label} placement="right" arrow>
                  <div
                    className={cx(
                      'truncate font-semibold text-sm capitalize',
                      label === currentPage
                        ? 'text-brand-600'
                        : 'text-slate-600'
                    )}
                  >
                    {label}
                  </div>
                </Tooltip>
                <BadgeNew visible={isNew} />
              </div>
            </div>
          </Link>
          <div className="hidden size-6 group-hover:flex">
            {state.type === 'idle' ? (
              <Tooltip
                arrow
                placement="right"
                title={intl.formatMessage({
                  defaultMessage: 'Drag to move',
                  id: 'lK08IF',
                })}
              >
                <div
                  className={cx(
                    'mr-2 hidden size-6 items-center justify-center rounded-lg p-1 text-slate-500 hover:cursor-grab hover:bg-slate-200 group-hover:flex'
                  )}
                >
                  <GripVertical />
                </div>
              </Tooltip>
            ) : (
              <div
                className={cx(
                  'mr-2 hidden size-6 items-center justify-center rounded-lg p-1 text-slate-500 hover:cursor-grab hover:bg-slate-200 group-hover:flex',
                  state.type === 'preview' ? 'cursor-grabbing' : ''
                )}
              >
                <GripVertical />
              </div>
            )}
          </div>
          {/* {true && ( */}
          {state.type === 'is-dragging-over' && state.closestEdge && (
            // Drop indicator line between items
            <div
              className={cx(
                'pointer-events-none absolute right-0 left-0 z-10 box-border h-0.5 rounded bg-brand-600',
                state.type === 'is-dragging-over' &&
                  state.closestEdge === 'bottom'
                  ? '-bottom-[1px]'
                  : '-top-[1px]'
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
