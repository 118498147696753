import React, { ReactElement, useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';
import { BooleanNode, WorkflowNodeProps } from './BaseNode';
import { GitFork } from 'lucide-react';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';
import { useIntl } from 'react-intl';

/**
 * A node to perform a boolean check and branch the workflows down one of two paths
 */
export function Condition(
  props: WorkflowNodeProps<{
    condition?: string;
  }>
): ReactElement {
  const intl = useIntl();
  const { workflowId } = useWorkflowId();
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const isExecution = Boolean(props.data.execution);
  const isPreview = useIsPreview();
  const handleChange = useCallback(
    (value: string) => {
      update(props.id, {
        condition: value,
      });
    },
    [props.id, update]
  );

  return (
    <BooleanNode
      icon={
        <GitFork className="size-8 rotate-180 rounded-lg border border-sky-500/25 bg-sky-50 p-1.5 text-sky-500" />
      }
      workflowNode={props}
      minHeight={256}
    >
      <LiquidTemplateInput
        className="min-h-40 flex-grow text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={props.data.condition ?? ''}
        onChange={handleChange}
        workflowId={workflowId}
        nodeType={props.type}
        disabled={isExecution || isPreview}
        placeholder={intl.formatMessage({
          defaultMessage: 'Enter a condition that needs to be met',
        })}
        ariaLabel={intl.formatMessage({
          defaultMessage: 'Condition input',
        })}
      />
    </BooleanNode>
  );
}
