import { useMutation } from '@apollo/client';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { ModalDialog } from '../../../components/modals';
import { selectTeam } from '../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DowngradeTeamToProDocument } from '../../../graphql/operations';
import { enqueueSnackbar } from 'notistack';
import { setUserLoaded } from '../../../redux/modules/global';
import { trackWebEvent } from '../../../helpers/analytics';
import { RootState } from '../../../redux/store';

export const DowngradeTeamToProDialog: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [downgradeTeamToPro, downgradeTeamToProMutation] = useMutation(
    DowngradeTeamToProDocument
  );
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);
  const user = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    trackWebEvent('Downgrade Team to Pro Dialog viewed', {
      teamId: team?.id,
      userId: user.id,
    });
  }, [team?.id, user.id]);

  if (!team) {
    return null;
  }

  return (
    <ModalDialog
      open
      onClose={onClose}
      title={
        <FormattedMessage defaultMessage="Downgrade to Pro plan" id="D/Pgup" />
      }
      text={
        <FormattedMessage
          defaultMessage="Please confirm if you want to go back to the Pro Plan and lose all of the Team features and number of AI credits."
          id="0vJTML"
        />
      }
      actions={
        <>
          <Button
            loading={downgradeTeamToProMutation.loading}
            onClick={async () => {
              trackWebEvent('Downgrade Team to Pro Dialog confirmed', {
                teamId: team?.id,
                userId: user.id,
              });
              await downgradeTeamToPro();
              dispatch(setUserLoaded(false));
              onClose();
              enqueueSnackbar('You have been downgraded from Team to Pro', {
                variant: 'SUCCESS',
              });
            }}
          >
            <FormattedMessage defaultMessage="Downgrade" id="SduMNm" />
          </Button>
          <Button
            loading={downgradeTeamToProMutation.loading}
            variant="soft"
            onClick={() => {
              trackWebEvent('Downgrade Team to Pro Dialog cancelled', {
                teamId: team?.id,
                userId: user.id,
              });
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </>
      }
    />
  );
};
