import { useMutation } from '@apollo/client';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconGoogleClasses } from '../../../app/Landing/SignInPage';
import { Button } from '../../../components/buttons';
import {
  UpdateUserSettingDocument,
  UserIntegrationConnection,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import icon from '../../../img/gcalendar.png';
import { IntegrationImplementation } from '../../../models/integration';
import { RootState } from '../../../redux/store';
import { SettingsRow } from '../../Common/SettingsRow';
import { createOAuthConnector } from './connector';
import { Switch } from '../../../components/buttons/Switch';
import { Alert } from '../../../components/Alert';
import { PlusIcon } from 'lucide-react';
import { selectHadGoogleWorkspaceAddon } from '../../../redux/selectors';
import { Link } from '../../../components/Link';

const Viewer: React.FC<{
  connection: UserIntegrationConnection;
}> = ({ connection }) => {
  return (
    <FormattedMessage
      defaultMessage="Connected to {name}"
      id="6anboP"
      description="Google Calendar connected to"
      values={{
        name: <strong>{connection.name}</strong>,
      }}
    />
  );
};

const Description: React.FC = () => {
  const userSettings = useSelector((state: RootState) => state.user.settings);
  const hasGoogleWorkspaceAddon = useSelector(selectHadGoogleWorkspaceAddon);
  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );
  const intl = useIntl();

  return (
    <div className="flex flex-col gap-2">
      {!hasGoogleWorkspaceAddon ? (
        <>
          <p>
            <FormattedMessage
              defaultMessage="Find and review Google Meet and Zoom transcriptions & summaries with one click in Google Calendar."
              description="Google Calendar integration. Description."
              id="RAKaFj"
            />
          </p>
          <Alert
            severity="info"
            description={
              <Link
                to="https://help.tactiq.io/en/articles/10056350-how-to-use-tactiq-in-google-calendar"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="Learn more about Google Calendar integration"
                  description="Google Calendar integration. Help center link"
                  id="f+Oyr8"
                />
              </Link>
            }
          />

          <Button
            color="primary"
            variant="outlined"
            startIcon={<PlusIcon className="h-6 w-6" />}
            onClick={() => {
              trackWebEvent('Clicked Link - Install Google Calendar Addon');
              window.open(
                'https://workspace.google.com/u/0/marketplace/app/tactiqio/399035273123',
                '_blank'
              );
            }}
          >
            <FormattedMessage
              defaultMessage="Install Tactiq add-on for Google Calendar"
              id="CAoSLY"
            />
          </Button>
        </>
      ) : null}

      <SettingsRow
        settingInfo={{
          title: intl.formatMessage({
            defaultMessage:
              'Append Tactiq transcript links to your calendar events',
            id: 'kctago',
          }),
        }}
        settingAction={
          <Switch
            disabled={updateUserSettingMutation.loading}
            onClick={async () => {
              trackWebEvent('Toggled Append Tactiq transcript links setting', {
                status: !userSettings?.addTactiqLinksToCalendarEvents,
              });
              await updateUserSetting({
                variables: {
                  input: {
                    addTactiqLinksToCalendarEvents:
                      !userSettings?.addTactiqLinksToCalendarEvents,
                  },
                },
              });
            }}
            isOn={userSettings?.addTactiqLinksToCalendarEvents}
          />
        }
      />
    </div>
  );
};

const integration: IntegrationImplementation = {
  id: 'google-calendar',
  order: 1,
  title: 'Google Calendar',
  icon,
  description: () => <Description />,
  connector: createOAuthConnector(
    'google-calendar',
    'Google Calendar',
    <FormattedMessage
      defaultMessage="Connect: Sign in with Google"
      id="4VLfDS"
    />,
    'primary',
    false,
    'outlined',
    <span className={IconGoogleClasses} />
  ),
  viewer: Viewer,
  isConnected: ({ hasGoogleCalendar }) => {
    return hasGoogleCalendar ?? false;
  },
  connectionFilter: (conn: UserIntegrationConnection) => {
    return (
      conn.scope
        ?.split(' ')
        .includes('https://www.googleapis.com/auth/calendar.events') ?? false
    );
  },
  actions: [
    createOAuthConnector(
      'google-calendar',
      'Google Calendar',
      <FormattedMessage
        defaultMessage="Reconnect: Sign in with Google"
        id="K97CMd"
      />,
      'primary',
      false,
      'outlined',
      <span className={IconGoogleClasses} />
    ),
  ],
};

export default integration;
