import React, { ReactElement, useEffect, useState } from 'react';
import { ModalDialog } from '../../components/modals';
import {
  useWorkflowCreate,
  useWorkflowList,
  useWorkflowRun,
} from '../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import {
  ArrowLeft,
  ArrowUpRight,
  CircleDashed,
  LayoutGrid,
  Library,
  Plus,
} from 'lucide-react';
import { Button } from '../../components/buttons';
import { Workflow, WorkflowsType } from '../../graphql/operations';
import { WorkflowPreview } from './WorkflowEditor';
import { useNavigate } from 'react-router';
import { WorkflowActionsButton } from './WorkflowActionsButton';
import { cx } from '../../helpers/utils';
import { workflowItemExecution } from '../../helpers/routes';
import {
  trackWorkflowBrowsed,
  trackWorkflowExploreSideBarClicked,
  trackWorkflowPreview,
} from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import { Skeleton } from '../../components/Skeleton';
import { Tooltip } from '../../components/Tooltip';
import { useSendAutomationEvent } from '../../services/Email';
import { AutomationEventName } from '@tactiq/model';
import { Node } from '@xyflow/react';
import CopyWorkflowLinkButton from './CopyWorkflowLinkButton';
import {
  AllIntegrations,
  AllIntegrations_IntegrationApp,
  IntegrationId,
} from '../Integration/integrations/integration-app';
import { Dot } from '../Common/Dot';
import { useSelector } from 'react-redux';
import { selectUid } from '../../redux/selectors';
import featureFlagService from '../../helpers/feature-flags';

/**
 * View the workflow discover page from a modal
 */
export function WorkflowDiscoveryModal(props: {
  open: boolean;
  setOpen: (next: boolean) => void;
  meetingId?: string;
  defaultType?:
    | WorkflowsType.TEAM
    | WorkflowsType.TACTIQ
    | WorkflowsType.ALL_DISCOVER;
}): ReactElement {
  const { open, setOpen, meetingId, defaultType } = props;
  const [workflowId, setWorkflowId] = useState<string>();
  const sendAutomationEvent = useSendAutomationEvent();
  const navigate = useNavigate();

  useEffect(() => {
    if (workflowId) {
      trackWorkflowPreview({ workflowId, source: 'modal' });
    }
  }, [workflowId]);

  useEffect(() => {
    if (open) {
      sendAutomationEvent.request({
        input: { type: AutomationEventName.workflow_browsed },
      });
      trackWorkflowBrowsed({ source: 'modal' });
    }
  }, [open]);

  return (
    <ModalDialog
      open={open}
      bgColor="raised"
      size="xl"
      onClose={() => setOpen(false)}
    >
      <WorkflowDiscovery
        workflowId={workflowId}
        setWorkflowId={setWorkflowId}
        meetingId={meetingId}
        setMeetingId={() => {}}
        onSuccess={({ executionId }) => {
          if (!workflowId) {
            throw new Error('WorkflowDiscovery onSuccess with no workflowId');
          }
          setOpen(false);
          navigate(
            workflowItemExecution
              .replace(':id', workflowId)
              .replace(':executionId', executionId)
          );
        }}
        heightClassName="h-[60vh]"
        selectedType={defaultType}
      />
    </ModalDialog>
  );
}

/**
 * Gives people a way to preview workflows without duplicating or running them.
 */
function WorkflowDiscovery(props: {
  workflowId: string | undefined;
  setWorkflowId: (next: string | undefined) => void;
  meetingId?: string;
  setMeetingId: (next?: string) => void;
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  heightClassName?: string;
  selectedType?:
    | WorkflowsType.TEAM
    | WorkflowsType.TACTIQ
    | WorkflowsType.ALL_DISCOVER;
}): ReactElement {
  const {
    onSuccess,
    meetingId,
    heightClassName,
    workflowId,
    setWorkflowId,
    selectedType,
  } = props;
  const [integrationFilter, setIntegrationFilter] = useState<
    'all' | IntegrationId
  >('all');
  const tactiqWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TACTIQ },
  });
  const teamWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TEAM },
  });
  const allWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.ALL_DISCOVER },
  });
  const [type, setType] = useState<
    WorkflowsType.ALL_DISCOVER | WorkflowsType.TACTIQ | WorkflowsType.TEAM
  >(selectedType ?? WorkflowsType.ALL_DISCOVER);

  const tabs = [
    {
      value: WorkflowsType.TACTIQ,
      label: <FormattedMessage defaultMessage="Recommended" id="VKfWR3" />,
    },
  ];
  if (teamWorkflowList.data?.workflows?.length) {
    tabs.unshift({
      value: WorkflowsType.TEAM,
      label: <FormattedMessage defaultMessage="Team" id="wsUmh9" />,
    });
  }
  tabs.unshift({
    value: WorkflowsType.ALL_DISCOVER,
    label: <FormattedMessage defaultMessage="All" id="zQvVDJ" />,
  });

  useEffect(() => {
    // Switch to all tab if there are no team workflows
    if (
      selectedType === WorkflowsType.TEAM &&
      !teamWorkflowList.loading &&
      !teamWorkflowList.data?.workflows?.length
    ) {
      setType(WorkflowsType.ALL_DISCOVER);
    }
  }, [selectedType, teamWorkflowList]);

  const workflows = {
    [WorkflowsType.ALL_DISCOVER]: allWorkflowList.data?.workflows,
    [WorkflowsType.TACTIQ]: tactiqWorkflowList.data?.workflows,
    [WorkflowsType.TEAM]: teamWorkflowList.data?.workflows,
  }[type]?.filter((workflow) =>
    integrationFilter !== 'all'
      ? workflow.integrations.includes(integrationFilter)
      : true
  );

  const [nodes, setNodes] = useState<Node[]>([]);
  const preview = workflows?.find((workflow) => workflow.id === workflowId);

  useEffect(() => {
    setNodes(preview ? preview.definition.nodes : []);
  }, [preview, preview?.definition.nodes]);

  const workflowRun = useWorkflowRun('discovery');
  if (workflowRun.error) throw workflowRun.error;

  if (preview) {
    return (
      <div
        className={cx(
          'flex flex-col overflow-hidden rounded-lg',
          heightClassName ?? 'h-full'
        )}
      >
        <div className="mr-8 mb-3 flex items-center justify-between gap-x-6">
          <Button
            variant="secondaryOutline"
            onClick={() => setWorkflowId(undefined)}
            startIcon={<ArrowLeft size="1rem" />}
          >
            <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
          </Button>
          <div className="font-semibold text-lg">{preview.name}</div>
          <div className="flex flex-row gap-2">
            <WorkflowActionsButton
              meetingId={meetingId}
              workflow={preview}
              preview={true}
              onSuccess={onSuccess}
            />
          </div>
        </div>
        <div className="flex-grow">
          <WorkflowPreview
            nodes={nodes}
            edges={preview.definition.edges}
            setNodes={setNodes}
          />
        </div>
      </div>
    );
  }

  const isLoading =
    teamWorkflowList.loading ||
    tactiqWorkflowList.loading ||
    allWorkflowList.loading;

  return (
    <div className={cx(heightClassName, 'flex flex-col bg-slate-25')}>
      <div className="mr-8 mb-8 flex flex-col justify-between gap-y-4 md:mb-8 md:flex-row">
        <div className="flex items-center gap-2">
          <Library />
          <div className="font-bold text-2xl tracking-tight">
            <FormattedMessage defaultMessage="Workflow Templates" id="SdX349" />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <Skeleton className="h-8" />
          ) : (
            <ToggleButtonGroup
              value={type}
              onChange={setType}
              style="disconnected"
            >
              {tabs.map(({ value, label }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </div>
      </div>
      <div className="@container flex w-full gap-10 overflow-auto">
        <SideBar
          integrationFilter={integrationFilter}
          setIntegrationFilter={setIntegrationFilter}
        />
        <div className="flex w-full flex-col gap-y-8">
          <BlankCard />
          <div className="flex items-center justify-center gap-x-6">
            <div className="h-px w-full bg-slate-200" />
            <div className="shrink-0 font-medium text-slate-500 text-sm">
              <div>
                <FormattedMessage
                  defaultMessage="or try a suggested template"
                  id="TZiggU"
                />
              </div>
            </div>
            <div className="h-px w-full bg-slate-200" />
          </div>
          <div
            className={cx(
              'grid w-full auto-rows-min @2xl:grid-cols-3 @7xl:grid-cols-4 @md:grid-cols-2 grid-cols-1 gap-4 overflow-auto pt-2'
            )}
          >
            {isLoading ? (
              <Skeleton count={6} className="h-40" />
            ) : (
              workflows?.map((workflow) => (
                <DiscoveryCard
                  key={workflow.id}
                  workflow={workflow}
                  setWorkflowId={setWorkflowId}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const SideBar: React.FC<{
  integrationFilter: 'all' | IntegrationId;
  setIntegrationFilter: (filter: 'all' | IntegrationId) => void;
}> = (props) => {
  const { integrationFilter, setIntegrationFilter } = props;
  const avaiableIntegrations = featureFlagService.getAvailableIntegrations();
  const sidebarItems = AllIntegrations_IntegrationApp.filter((ii) =>
    avaiableIntegrations.has(ii.id)
  );
  const handleItemClick = (id: 'all' | IntegrationId) => {
    trackWorkflowExploreSideBarClicked(id);
    setIntegrationFilter(id);
  };
  return (
    <div className="flex min-w-28 flex-col">
      <div className="mb-2 font-semibold text-slate-700">
        <FormattedMessage defaultMessage="Integrations" />
      </div>
      <button
        key="all"
        className={cx(
          'flex items-center gap-2 rounded-lg px-2 py-2 hover:bg-slate-200',
          integrationFilter === 'all' ? 'bg-slate-200' : ''
        )}
        type="button"
        onClick={() => handleItemClick('all')}
      >
        <LayoutGrid className="size-4" />
        <span className="text-sm">
          <FormattedMessage defaultMessage="All" />
        </span>
      </button>
      {sidebarItems.map(({ id, name, Icon }) => (
        <button
          key={id}
          className={cx(
            'flex items-center gap-2 rounded-lg px-2 py-2 hover:bg-slate-200',
            integrationFilter === id ? 'bg-slate-200' : ''
          )}
          type="button"
          onClick={() => handleItemClick(id)}
        >
          <Icon className="size-4" />
          <span className="text-sm">{name}</span>
        </button>
      ))}
    </div>
  );
};

const BlankCard: React.FC = () => {
  const { data, request, loading } = useWorkflowCreate();
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const id = data?.createWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${id}`);
    }
  }, [id, isSuccess, navigate]);

  return (
    <div
      className="tq-dashed-border group flex min-h-20 cursor-pointer items-center justify-center rounded-2xl bg-white px-5 text-lg leading-5 transition-colors hover:bg-slate-50"
      onClick={request}
    >
      <div className="relative flex items-center justify-center gap-x-1.5">
        <Plus
          size={18}
          strokeWidth={2.5}
          className="-right-6 absolute text-slate-600 opacity-0 transition-all group-hover:rotate-90 group-hover:opacity-100"
        />
        <div className="font-semibold text-base text-slate-500 group-hover:text-slate-600">
          <FormattedMessage
            defaultMessage="Start with a blank workflow"
            id="9Dd2PR"
          />
        </div>
      </div>
    </div>
  );
};

const IntegrationsPreview = ({
  integrations,
  showEmpty,
}: { integrations: string[]; showEmpty: boolean }) => {
  const wrapperClass = 'flex gap-1 rounded-md border border-slate-200';
  const className =
    'flex items-center justify-center border-slate-200 border-r p-1 last:border-none';

  if (showEmpty) {
    return (
      <div className={wrapperClass}>
        <Tooltip
          title={<FormattedMessage defaultMessage="No steps" />}
          placement="top"
          arrow
        >
          <span className={className}>
            <CircleDashed className="size-4" />
          </span>
        </Tooltip>
      </div>
    );
  }

  const inter = integrations.map((intg) =>
    AllIntegrations.find((ii) => ii.id === intg)
  );
  const first3 = inter.slice(0, 3);
  const rest = inter.slice(3);
  return (
    <div className={wrapperClass}>
      {first3.map((ii) => {
        return ii?.Icon ? (
          <Tooltip key={ii.id} title={ii.name} placement="top" arrow>
            <span className={className}>
              <ii.Icon className="size-4" />
            </span>
          </Tooltip>
        ) : null;
      })}
      {rest.length > 0 && (
        <Tooltip
          title={rest.map((ii) => ii?.name).join(', ')}
          placement="top"
          arrow
        >
          <span className={className}>
            <span className="text-xs">+{rest.length}</span>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const DiscoveryCard: React.FC<{
  workflow: Workflow;
  setWorkflowId: (next: string | undefined) => void;
}> = (props) => {
  const { workflow, setWorkflowId } = props;
  const currentUserId = useSelector(selectUid);

  let type: ReactElement | undefined;
  if (workflow.tactiq) {
    type = <FormattedMessage defaultMessage="Recommended" />;
  } else if (workflow.createdBy.uid !== currentUserId) {
    type = <FormattedMessage defaultMessage="Team" />;
  }

  const hasAi = workflow.definition.nodes.some(
    ({ type }) => type === 'RunMeetingKit' || type === 'CustomPrompt'
  );
  const integrations = [...workflow.integrations];
  if (hasAi) {
    integrations.unshift('ai');
  }

  return (
    <div
      key={workflow.id}
      onClick={() => setWorkflowId(workflow.id)}
      className="hover:-translate-y-0.5 flex cursor-pointer flex-col justify-between rounded-2xl border border-slate-200 bg-white p-5 text-lg leading-5 transition-all duration-200 will-change-transform hover:shadow-lg hover:shadow-slate-500/10"
    >
      <div className="flex flex-col">
        <div className="mb-6 flex items-start justify-between">
          <IntegrationsPreview
            integrations={integrations}
            showEmpty={workflow.definition.nodes.length <= 1} // if it's just the start node
          />
          {type && (
            <div className="rounded-md bg-slate-200 px-1 py-[2px] text-xs">
              {type}
            </div>
          )}
        </div>

        <div className="mb-2 line-clamp-3 font-semibold text-md text-slate-700 leading-6">
          {workflow.name}
        </div>

        <div className="mb-4 flex items-center gap-1 text-gray-500 text-xs">
          <FormattedMessage
            defaultMessage="By {name}"
            values={{ name: workflow.createdBy.displayName }}
          />
          <Dot className="size-1" />
          <FormattedMessage
            defaultMessage="{steps} steps"
            values={{ steps: workflow.definition.nodes.length }}
          />
        </div>
      </div>

      <div className="flex items-center gap-1">
        <Button
          variant="secondaryOutline"
          size="small"
          fullWidth
          endIcon={<ArrowUpRight size="1rem" />}
          onClick={() => setWorkflowId(workflow.id)}
        >
          <FormattedMessage defaultMessage="Preview" id="TJo5E6" />
        </Button>

        <CopyWorkflowLinkButton
          workflow={workflow}
          source="template_list"
          withMessage={false}
          stopPropagation
          variant="naked"
        />
      </div>
    </div>
  );
};
