import { Checkbox } from '../../../../components/Checkbox';
import { AIStructuredActionItems } from '@tactiq/model';
import { omitDeep } from 'lodash-omitdeep';
import { Pencil, ThumbsUp, Trash2 } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '../../../../components/TextInput';
import { Button } from '../../../../components/buttons';
import { trackWebEvent } from '../../../../helpers/analytics';
import { useFullMeeting } from '../../common/meeting-hooks';
import { useMutation } from '@apollo/client';
import {
  MeetingAiOutputTypeActionItems,
  MeetingKitItemOutputType,
  UpdateAiActionItemsDocument,
} from '../../../../graphql/operations';
import { ModalDialog } from '../../../../components/modals';

const EditDialog: React.FC<{
  meetingId: string;
  open: boolean;
  content: string;
  participantIndex: number;
  actionIndex: number;
  onClose: () => void;
}> = ({ meetingId, open, content, participantIndex, actionIndex, onClose }) => {
  const intl = useIntl();
  const meeting = useFullMeeting();
  const [value, setValue] = useState(content);
  const [updateAIActionItems] = useMutation(UpdateAiActionItemsDocument);

  const onSave = useCallback(async () => {
    if (!meeting) return;

    const { actionItems: list } = meeting.aiOutputs.find(
      (aiOutput) =>
        aiOutput.contentType === MeetingKitItemOutputType.ACTION_ITEMS
    )?.content as MeetingAiOutputTypeActionItems;

    if (!list) return;
    const action = list[participantIndex]?.actions?.[actionIndex];

    if (!action) return;

    const updatedList = omitDeep(list, '__typename');
    updatedList[participantIndex].actions[actionIndex].content = value;

    await updateAIActionItems({
      variables: { input: { meetingId, actionItems: updatedList } },
    });

    trackWebEvent('AI action items - Edit action button - clicked', {
      meetingId,
    });

    onClose();
  }, [
    actionIndex,
    meeting,
    meetingId,
    onClose,
    participantIndex,
    updateAIActionItems,
    value,
  ]);

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      size="large"
      title={<FormattedMessage defaultMessage="Edit action item" id="rnyWvE" />}
      text={
        <TextInput
          type="textarea"
          value={value}
          label={intl.formatMessage({
            defaultMessage: 'Action item text',
            id: '7X9yg4',
          })}
          onChange={setValue}
          autoFocus
        />
      }
      actions={<Button onClick={onSave}>Save</Button>}
    />
  );
};

const ActionItem: React.FC<{
  meetingId: string;
  content: string;
  isDone: boolean;
  index: number;
  actionIndex: number;
  onDelete: (index: number, actionIndex: number) => void;
  onDoneStateChange: (
    index: number,
    actionIndex: number,
    isDone: boolean
  ) => void;
  isEditable: boolean;
}> = ({
  meetingId,
  content,
  index,
  actionIndex,
  isDone,
  onDelete,
  onDoneStateChange,
  isEditable,
}) => {
  const labelId = `checkbox-list-label-${index}-${actionIndex}`;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const intl = useIntl();

  const actions = isEditable ? (
    <div className="flex gap-1">
      <Button
        size="small"
        variant="naked"
        onClick={() => {
          setEditDialogOpen(true);
        }}
      >
        <Pencil className="h-4 w-4 text-slate-600" />
      </Button>

      <Button
        size="small"
        variant="naked"
        onClick={() => {
          trackWebEvent('AI action items - Like action button - clicked', {
            meetingId,
          });
          enqueueSnackbar(
            intl.formatMessage({
              defaultMessage:
                'Thank you for your feedback! We will use it to improve our AI summary.',
              id: 'I0qp8Q',
            }),
            { variant: 'SUCCESS' }
          );
        }}
      >
        <ThumbsUp className="h-4 w-4 text-slate-600" />
      </Button>

      <Button
        size="small"
        variant="naked"
        onClick={() => onDelete(index, actionIndex)}
      >
        <Trash2 className="h-4 w-4 text-slate-600" />
      </Button>
    </div>
  ) : null;

  return (
    <li className="group relative rounded-md px-2 hover:bg-slate-100">
      <Checkbox
        label={content}
        id={labelId}
        checked={isDone}
        className="p-2 px-1"
        onChange={() => {
          if (isEditable) {
            onDoneStateChange(index, actionIndex, !isDone);
          } else {
            enqueueSnackbar(
              intl.formatMessage({
                defaultMessage: `You cannot change Action Item status`,
                id: 'k5RzpD',
              }),
              { variant: 'WARNING' }
            );
          }
        }}
      />
      <div className="absolute top-1 right-1 hidden group-hover:block">
        {actions}
      </div>
      <EditDialog
        meetingId={meetingId}
        open={editDialogOpen}
        content={content}
        participantIndex={index}
        actionIndex={actionIndex}
        onClose={() => setEditDialogOpen(false)}
      />
    </li>
  );
};

/**
 * Action Items
 * @param {unknown} param0 params
 * @returns {React.FC} a component
 */
export const ActionItems: React.FC<{
  meetingId: string;
  list: AIStructuredActionItems[];
  isEditable: boolean;
}> = ({ meetingId, list, isEditable }) => {
  const [updateAIActionItems] = useMutation(UpdateAiActionItemsDocument);
  const onDeleteAction = useCallback(
    async (idx: number, actionIdx: number) => {
      const updatedList = omitDeep(list, '__typename');
      updatedList[idx].actions.splice(actionIdx, 1);
      await updateAIActionItems({
        variables: { input: { meetingId, actionItems: updatedList } },
      });

      trackWebEvent('AI action items - Delete action button - clicked', {
        meetingId,
      });
    },
    [list, meetingId, updateAIActionItems]
  );

  const onDoneStateChange = useCallback(
    async (idx: number, actionIdx: number, isChecked: boolean) => {
      const updatedList = omitDeep(list, '__typename');
      const action = updatedList[idx]?.actions?.[actionIdx];

      if (!action) {
        return;
      }

      action.isDone = isChecked;
      await updateAIActionItems({
        variables: { input: { meetingId, actionItems: updatedList } },
      });

      trackWebEvent('AI action items - Mark as done checkbox - changed', {
        meetingId,
        isChecked,
      });
    },
    [list, meetingId, updateAIActionItems]
  );

  return (
    <ul className="flex flex-col gap-3 pl-3">
      {list.map(({ participantName, actions }, index) => (
        <li key={index}>
          <span className="text-lg">{participantName}</span>
          <ul>
            {actions.map(({ content, isDone }, actionIndex) => (
              <ActionItem
                isEditable={isEditable}
                key={actionIndex}
                meetingId={meetingId}
                content={content}
                isDone={isDone}
                index={index}
                actionIndex={actionIndex}
                onDelete={onDeleteAction}
                onDoneStateChange={onDoneStateChange}
              />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
