import { useMutation } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { Breadcrumbs } from '../../components/pageparts/Breadcrumbs';
import { DescriptionList } from '../../components/pageparts/DescriptionList';
import { DescriptionListItem } from '../../components/pageparts/DescriptionListItem';
import { PageHeader } from '../../components/pageparts/PageHeader';
import {
  DeleteMeetingKitItemDocument,
  MeetingKit,
  MeetingKitItem,
  MeetingKitItemOutputType,
  UpdateMeetingKitItemDocument,
  UpdateMeetingKitItemInput,
} from '../../graphql/operations';
import { trackWebEvent, trackWebPage } from '../../helpers/analytics';
import featureFlagService from '../../helpers/feature-flags';
import {
  removeMeetingKitItem,
  setMeetingKit,
  setMeetingKitItem,
} from '../../redux/modules/global';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { EmojiField } from '../Common/icons/EmojiField';
import { getMeetingKitItemIcon } from './helpers';
import { Alert } from '../../components/Alert';
import { Trash } from 'lucide-react';
import { ModalDialog } from '../../components/modals';
import { Select } from '../../components/Select';

const MeetingKitItemNotFound: React.FC = () => {
  return (
    <>
      <Alert
        severity="warning"
        description={
          <FormattedMessage
            defaultMessage="Saved Prompt not found or you don't have access to it"
            id="yfq0Bp"
          />
        }
      />
    </>
  );
};

/**
 * Meeting kit item page
 * @param {unknown} param0 params
 * @returns {React.FC} a component
 */
export const MeetingKitItemPage: React.FC<{
  isEditing?: boolean;
}> = ({ isEditing }) => {
  const intl = useIntl();

  const usedMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.used
  );

  const match = useParams<{ kitId: string; itemId: string }>();

  const kit = usedMeetingKits.find((k) => k.id === match.kitId);
  const item = kit?.items.find((i) => i.id === match.itemId);

  useEffect(() => {
    trackWebPage('Meeting Kit Item', {
      kitId: match.kitId,
      itemId: match.itemId,
    });
  }, [match]);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'AI Kits',
        id: '2xPU/d',
      })}
      maxWidth={'md'}
    >
      <MeetingKitItemComponent kit={kit} item={item} isEditing={isEditing} />
    </Page>
  );
};

/**
 * Meeting kit item component
 * @param {unknown} param0 params
 * @param {boolean} param0.isEditing is editing
 * @param {(reason: 'save' | 'cancel') => void} param0.onClose close handler
 * @param {MeetingKit} param0.kit meeting kit
 * @param {MeetingKitItem} param0.item meeting kit item
 * @returns {React.FC} a component
 */
export const MeetingKitItemComponent: React.FC<{
  isEditing?: boolean;
  onClose?: (reason: 'save' | 'cancel') => void;
  kit?: MeetingKit;
  item?: MeetingKitItem;
}> = ({ isEditing, onClose, kit, item }) => {
  const intl = useIntl();
  const areActionItemMeetingKitItemsEnabled =
    featureFlagService.areActionItemMeetingKitItemsEnabled();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [updateMeetingKitItem, updateMeetingKitItemMutation] = useMutation(
    UpdateMeetingKitItemDocument
  );

  const [deleteMeetingKitItem, deleteMeetingKitItemMutation] = useMutation(
    DeleteMeetingKitItemDocument
  );

  const isLoading =
    updateMeetingKitItemMutation.loading ||
    deleteMeetingKitItemMutation.loading;
  const [itemDetails, setItemDetails] = useState<UpdateMeetingKitItemInput>();

  const timestampCitationPrompt = intl.formatMessage({
    defaultMessage:
      ' For each key point or topic discussed, include a corresponding timestamp from the transcript to cite when that moment was addressed. This citation should be formatted as [mm:ss], indicating the exact minute and second where the related discussion can be found.',
    id: 'FxMYFs',
  });

  const initialItemDetails: UpdateMeetingKitItemInput | undefined =
    useMemo(() => {
      return (
        kit &&
        item && {
          meetingKitId: kit.id,
          meetingKitItemId: item.id,

          name: item.name,
          description: item.description,
          icon: item.icon,

          outputType: item.outputType,

          updatePrompt: {
            prompt: item.prompt,
          },
        }
      );
    }, [kit, item]);

  useEffect(() => {
    if (!kit || !item) return;

    setItemDetails(initialItemDetails);
  }, [kit, item, initialItemDetails]);

  const startEditing = useCallback(() => {
    if (!kit || !item) return;

    navigate(`/meeting-kits/${kit.id}/${item.id}/edit`);
  }, [kit, item, navigate]);

  const usedMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.used
  );

  const saveChanges = useCallback(async () => {
    if (!kit || !item) return;
    if (!itemDetails) return;
    if (isEqual(itemDetails, initialItemDetails)) return;
    if (isLoading) return;

    trackWebEvent('Meeting Kit Item - Update', { kit: kit.id, item: item.id });

    const updatedItem = (
      await updateMeetingKitItem({
        variables: {
          input: itemDetails,
        },
      })
    ).data?.updateMeetingKitItem.meetingKitItem;

    if (updatedItem) {
      dispatch(
        setMeetingKitItem({
          kitId: kit.id,
          item: updatedItem,
        })
      );

      if (kit.publishedId) {
        const publishedMeetingKit = usedMeetingKits.find(
          (k) => k.id === kit.publishedId
        );
        if (publishedMeetingKit) {
          dispatch(
            setMeetingKit({
              ...publishedMeetingKit,
              owner: {
                ...publishedMeetingKit.owner,
                sourceLastUpdatedAt: Date.now(),
              },
            })
          );
        }
      }
    }

    onClose ? onClose('save') : navigate(`/meeting-kits/${kit.id}/${item.id}`);
  }, [
    kit,
    item,
    itemDetails,
    initialItemDetails,
    isLoading,
    updateMeetingKitItem,
    dispatch,
    onClose,
    navigate,
    usedMeetingKits,
  ]);

  const intlMeetingKitItemOutputType: Record<MeetingKitItemOutputType, string> =
    {
      [MeetingKitItemOutputType.TEXT]: intl.formatMessage({
        defaultMessage: 'Plain Text',
        id: 'XOi37d',
      }),
      [MeetingKitItemOutputType.MARKDOWN]: intl.formatMessage({
        defaultMessage: 'Markdown',
        id: 'jr4+vD',
      }),
      [MeetingKitItemOutputType.ACTION_ITEMS]: intl.formatMessage({
        defaultMessage: 'Action Items',
        id: 'kXd1r0',
      }),
    };

  const content =
    kit && item ? (
      <>
        <DescriptionList>
          <DescriptionListItem
            label={<FormattedMessage defaultMessage="Icon" id="kk4TVW" />}
            content={
              <EmojiField
                onChange={
                  itemDetails
                    ? (emoji) => {
                        setItemDetails({
                          ...itemDetails,
                          icon: emoji.unified,
                        });
                      }
                    : undefined
                }
                value={itemDetails?.icon ?? item.icon}
                defaultIcon={getMeetingKitItemIcon(item)}
              />
            }
          />
          <DescriptionListItem
            label={<FormattedMessage defaultMessage="Name" id="HAlOn1" />}
            content={
              isEditing && itemDetails ? (
                <TextInput
                  disabled={isLoading}
                  value={itemDetails.name}
                  onChange={(value) => {
                    setItemDetails({
                      ...itemDetails,
                      name: value,
                    });
                  }}
                />
              ) : (
                item.name
              )
            }
          />
          <DescriptionListItem
            label={
              <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
            }
            content={
              isEditing && itemDetails ? (
                <TextInput
                  type="textarea"
                  disabled={isLoading}
                  value={itemDetails.description}
                  onChange={(value) => {
                    setItemDetails({
                      ...itemDetails,
                      description: value,
                    });
                  }}
                />
              ) : (
                item.description
              )
            }
          />
          {
            // when areActionItemMeetingKitItemsEnabled is false we only
            // have Plain tet + markdown, and we moving away from the plain text type
            // for now allow to select all 3 options if the user is allowed to select action items
            areActionItemMeetingKitItemsEnabled ? (
              <DescriptionListItem
                label={
                  <p>
                    <FormattedMessage
                      defaultMessage="Output Mode"
                      id="n/FAlx"
                    />
                  </p>
                }
                content={
                  isEditing && itemDetails ? (
                    <Select
                      value={itemDetails.outputType}
                      disabled={isLoading}
                      onChange={(outputType) => {
                        setItemDetails({
                          ...itemDetails,
                          outputType,
                        });
                      }}
                      options={[
                        {
                          value: MeetingKitItemOutputType.TEXT,
                          label: intlMeetingKitItemOutputType.TEXT,
                        },
                        {
                          value: MeetingKitItemOutputType.MARKDOWN,
                          label: intlMeetingKitItemOutputType.MARKDOWN,
                        },
                        {
                          value: MeetingKitItemOutputType.ACTION_ITEMS,
                          label: intlMeetingKitItemOutputType.ACTION_ITEMS,
                        },
                      ]}
                    />
                  ) : (
                    intlMeetingKitItemOutputType[item.outputType]
                  )
                }
              />
            ) : null
          }
          <DescriptionListItem
            label={
              <FormattedMessage
                defaultMessage="Prompt (question)"
                id="L30MEF"
              />
            }
            content={
              isEditing && itemDetails ? (
                <div className="font-mono">
                  <TextInput
                    type="textarea"
                    autoFocus
                    disabled={isLoading}
                    value={itemDetails.updatePrompt?.prompt}
                    onChange={(value) => {
                      setItemDetails({
                        ...itemDetails,
                        updatePrompt: {
                          prompt: value,
                        },
                      });
                    }}
                  />
                </div>
              ) : (
                item.prompt
              )
            }
          />
          {(itemDetails?.outputType === MeetingKitItemOutputType.TEXT ||
            itemDetails?.outputType === MeetingKitItemOutputType.MARKDOWN) &&
          isEditing &&
          itemDetails ? (
            <DescriptionListItem
              label={
                <>
                  <p>
                    <FormattedMessage
                      defaultMessage="Timestamp Citation"
                      id="+qtxac"
                    />
                  </p>
                  <p className="font-light text-sm">
                    <FormattedMessage
                      defaultMessage="Automatically insert the exact time a quote was mentioned."
                      id="okbVtQ"
                    />
                  </p>
                </>
              }
              content={
                <div className="flex h-full flex-row items-center justify-end">
                  <Button
                    onClick={() => {
                      trackWebEvent(
                        'Meeting Kit Item - Add Timestamp Citation'
                      );

                      setItemDetails({
                        ...itemDetails,
                        updatePrompt: {
                          prompt:
                            itemDetails.updatePrompt?.prompt +
                            timestampCitationPrompt,
                        },
                      });
                    }}
                  >
                    <FormattedMessage defaultMessage="Add to prompt" />
                  </Button>
                </div>
              }
            />
          ) : null}
        </DescriptionList>

        <ModalDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title={
            <FormattedMessage
              defaultMessage="Delete meeting kit item?"
              id="ouIg6M"
            />
          }
          text={
            <FormattedMessage
              defaultMessage="You will not be able to recover it - this is irreversible. Do you still want to delete it?"
              id="X+ttwe"
            />
          }
          actions={
            <div className="flex flex-row gap-2">
              <Button
                color="error"
                loading={deleteMeetingKitItemMutation.loading}
                onClick={async () => {
                  trackWebEvent('Delete meeting kit tool');
                  await deleteMeetingKitItem({
                    variables: {
                      input: {
                        meetingKitId: kit.id,
                        meetingKitItemId: item.id,
                      },
                    },
                  });
                  dispatch(
                    removeMeetingKitItem({
                      kitId: kit.id,
                      itemId: item.id,
                    })
                  );
                  navigate(`/meeting-kits/${kit.id}`);
                }}
              >
                <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
              </Button>
              <Button
                loading={deleteMeetingKitItemMutation.loading}
                onClick={() => setDeleteDialogOpen(false)}
                autoFocus
              >
                <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
              </Button>
            </div>
          }
        />
      </>
    ) : (
      <MeetingKitItemNotFound />
    );

  return (
    <>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs
            backHref="/meeting-kits"
            items={[
              {
                label: (
                  <FormattedMessage
                    defaultMessage="All Meeting Kits"
                    id="ufETaW"
                  />
                ),
                href: '/meeting-kits',
              },
              ...(kit?.publishedFrom
                ? [
                    {
                      label: kit.name,
                      href: `/meeting-kits/${kit.publishedFrom}`,
                    },
                  ]
                : []),
              ...(kit
                ? [
                    {
                      label: kit.publishedFrom ? 'Published' : kit.name,
                      href: `/meeting-kits/${kit.id}`,
                    },
                  ]
                : []),
              ...(kit && item
                ? [
                    {
                      label: item.name,
                      href: `/meeting-kits/${kit.id}/${item.id}`,
                    },
                  ]
                : []),
            ]}
          />
        }
        title={
          item?.name ?? (
            <FormattedMessage
              defaultMessage="Saved Prompt Not Found"
              id="YJo329"
            />
          )
        }
        actions={
          isEditing ? (
            <>
              <Button
                loading={updateMeetingKitItemMutation.loading}
                onClick={saveChanges}
              >
                <FormattedMessage defaultMessage="Save" id="jvo0vs" />
              </Button>
              <Button
                loading={isLoading}
                onClick={() => {
                  onClose
                    ? onClose('cancel')
                    : navigate(`/meeting-kits/${kit!.id}/${item!.id}`);
                }}
              >
                <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
              </Button>
            </>
          ) : kit && item && !kit.publishedFrom ? (
            <>
              <Button
                variant="naked"
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
                startIcon={<Trash className="h-5 w-5 text-red-600" />}
              ></Button>
              <Button onClick={startEditing}>
                <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
              </Button>
            </>
          ) : null
        }
      />
      {content}
    </>
  );
};
