import { logger } from '@tactiq/model';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { trackWebPage } from '../../helpers/analytics';
import { kTeamInvite } from '../../helpers/routes';
import { selectTeam } from '../../redux/selectors';
import { JoinTeamAlert } from '../Common/JoinTeamAlert';
import { Page } from '../Common/Navigation';
import { EmptyTeamPlaceholder } from './EmptyTeamPlaceholder';
import TeamView from './Team';
import { gotTeam } from '../../redux/modules/user';
import { useMutation } from '@apollo/client';
import { CreateTeamDocument } from '../../graphql/operations';

export const TeamPage: React.FC = () => {
  const intl = useIntl();
  const team = useSelector(selectTeam);
  const match = useMatch(kTeamInvite);
  const dispatch = useDispatch();
  const [createTeam, createTeamMutation] = useMutation(CreateTeamDocument);

  useEffect(() => {
    trackWebPage('Team');
  }, []);

  useEffect(() => {
    if (!team && match && !createTeamMutation.loading) {
      createTeam()
        .then((m) => dispatch(gotTeam(m.data?.createTeam)))
        .catch(logger.error);
    }
  }, [dispatch, intl, createTeam, match, team, createTeamMutation.loading]);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Team',
        id: 'V/0qRL',
        description: 'Menu bar text.',
      })}
      maxWidth="lg"
    >
      <div className="flex flex-col gap-6">
        <JoinTeamAlert />
        {team ? (
          <TeamView
            key={team.id}
            teamId={team.id}
            isAddMemberModalOpen={!!match}
          />
        ) : (
          <EmptyTeamPlaceholder />
        )}
      </div>
    </Page>
  );
};
