import { useMutation } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button } from '../../../../components/buttons';
import {
  JoinWaitlistDocument,
  UserIntegrationConnection,
} from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { IntegrationImplementation } from '../../../../models/integration';
import { Chip } from '../../../../components/Chips';
import { cx } from '../../../../helpers/utils';

interface DestinationListProps {
  destinations: IntegrationImplementation[];
  userConnections: UserIntegrationConnection[];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  share: Function;
}

interface DestinationProps {
  destination: IntegrationImplementation;
  hasConnection: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  share: Function;
  connections: UserIntegrationConnection[];
}

export const DestinationList: React.FC<DestinationListProps> = ({
  destinations,
  userConnections,
  share,
}) => {
  const userConnectionsHash = keyBy(userConnections || [], 'type');

  return (
    <ul>
      {destinations.map((x) => (
        <Destination
          key={x.id}
          destination={x}
          hasConnection={Boolean(
            x.isConnected
              ? x.isConnected({ userConnections })
              : x.id in userConnectionsHash
          )}
          connections={userConnections.filter((c) => c.type === x.id)}
          share={share}
        />
      ))}
    </ul>
  );
};

function Destination(props: DestinationProps) {
  const { hasConnection, destination, connections } = props;
  const { id, icon: Icon, title, share, waitlist } = destination;
  const isEnabled = Boolean(share && (!waitlist || hasConnection));
  const navigate = useNavigate();
  const intl = useIntl();
  const hasError = connections?.find((c) => c.error);
  const [joinWaitlist, joinWaitlistMutation] =
    useMutation(JoinWaitlistDocument);

  const textContent = (
    <div
      className={cx(
        'flex items-center gap-4 p-1 text-left',
        !isEnabled ? 'opacity-50 saturate-0' : ''
      )}
    >
      {typeof Icon === 'string' ? (
        <img style={{ height: '32px', width: '32px' }} src={Icon} />
      ) : (
        <Icon />
      )}
      <div>
        <div className="font-semibold text-sm">{title}</div>
        <div className="font-normal text-sm">
          {destination.shareDescription ? (
            <destination.shareDescription />
          ) : null}
        </div>
      </div>
    </div>
  );

  if (!isEnabled) {
    return (
      <li className="flex items-center justify-between px-3 py-2">
        {textContent}
        <Button
          size="small"
          loading={joinWaitlistMutation.loading}
          className="flex items-center justify-between"
          onClick={async () => {
            if (waitlist && !hasConnection) {
              trackWebEvent(
                'Clicked on a new sharing destination, but it is waitlisted',
                { destination: id }
              );
              await joinWaitlist({ variables: { integrationId: id } });
              enqueueSnackbar(
                intl.formatMessage({
                  defaultMessage: 'You have joined the waitlist!',
                  id: 'TxpF/V',
                }),
                { variant: 'SUCCESS' }
              );
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Join waitlist"
            id="MLB6P/"
            description="Share meeting. Coming soon destination tooltip."
          />
        </Button>
      </li>
    );
  }

  return (
    <li>
      <Button
        fullWidth
        variant="naked"
        className="flex items-center justify-between"
        disabled={!isEnabled}
        onClick={() => {
          if (hasError) {
            navigate(`/integrations/${id}`);
          } else {
            trackWebEvent('Clicked on a new sharing destination', {
              destination: id,
            });
            props.share(props.destination);
          }
        }}
      >
        {textContent}
        {hasConnection ? (
          hasError ? (
            <Chip color="red">
              <FormattedMessage defaultMessage="Error" id="KN7zKn" />
            </Chip>
          ) : (
            <Chip>
              <FormattedMessage defaultMessage="Connected" id="IvjoDS" />
            </Chip>
          )
        ) : null}
      </Button>
    </li>
  );
}
