import React from 'react';
import { cx } from '../../../../helpers/utils';

export interface ParticipantStatsBarProps {
  color: string;
  width: string;
}

// The below set of colours come from COLORS from src/graphql/resolvers/serializers/meeting.ts
// It's a workaround for now but we can make it better soon.
export type SpeakerColourProps =
  | 'default'
  | '#e91e63'
  | '#00bcd4'
  | '#03a9f4'
  | '#4caf50'
  | '#9c27b0'
  | '#ff9800'
  | '#f44336'
  | '#673ab7'
  | '#8bc34a'
  | '#ffc107'
  | '#ff5722'
  | '#2196f3'
  | '#cddc39'
  | '#ffeb3b'
  | '#3f51b5'
  | '#009688';

type ParticipantStatsBar = {
  imgSrc: string;
  altText: string;
  className?: string;
  color?: SpeakerColourProps;
};

export const ColorClasses: Record<
  NonNullable<ParticipantStatsBarProps['color']>,
  string
> = {
  default: 'bg-slate-500',
  '#e91e63': 'bg-pink-500',
  '#00bcd4': 'bg-cyan-500',
  '#03a9f4': 'bg-blue-500',
  '#4caf50': 'bg-green-500',
  '#9c27b0': 'bg-purple-500',
  '#ff9800': 'bg-orange-500',
  '#f44336': 'bg-red-500',
  '#673ab7': 'bg-indigo-500',
  '#8bc34a': 'bg-light-green-500',
  '#ffc107': 'bg-amber-500',
  '#ff5722': 'bg-orange-600',
  '#2196f3': 'bg-sky-500',
  '#cddc39': 'bg-lime-500',
  '#ffeb3b': 'bg-yellow-500',
  '#3f51b5': 'bg-indigo-500',
  '#009688': 'bg-teal-500',
};

/**
 * Participant Stats bar
 * @param {unknown} param0 params
 * @returns {React.FC<ParticipantStatsBarProps>} compoment
 */
export const ParticipantStatsBar: React.FC<ParticipantStatsBarProps> = ({
  width,
  color,
}: ParticipantStatsBarProps) => {
  return (
    <div className="h-1.5 w-full overflow-hidden rounded-md bg-slate-200">
      <div
        className={cx('h-full rounded-md', ColorClasses[color ?? 'default'])}
        style={{
          width: `${width}%`,
        }}
      />
    </div>
  );
};
