import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Team } from '../../../graphql/operations';
import { EmailsInput } from '../../Common/EmailsInput';
import { TeamInvitationLinkUI } from './TeamInvitationLink';
import { useSelector } from 'react-redux';
import { selectUid } from '../../../redux/selectors';
import { Alert } from '../../../components/Alert';
import { ModalDialog } from '../../../components/modals';

export interface AddMemberModalProps {
  team: Team;
  open: boolean;
  onClose: () => void;
  defaultEmails?: string[];
}

export const AddMembersModal: React.FC<AddMemberModalProps> = ({
  team,
  open,
  onClose,
  defaultEmails,
}) => {
  const [emails, setEmails] = useState(defaultEmails ?? []);
  const userId = useSelector(selectUid);

  const isAdmin =
    team?.members?.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;
  const canInvite = team.settings.enableTeamInvite || isAdmin;

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Invite members to your team"
          id="LTDlkC"
          description="Team view. Add members dialog title"
        />
      }
    >
      {!canInvite ? (
        <Alert
          variant="light"
          severity="info"
          title={
            <FormattedMessage
              defaultMessage="Only admins can invite to the team"
              id="1OzVs7"
            />
          }
          description={
            <FormattedMessage
              defaultMessage="Only an admin can invite members to the team. Ask admin to send an invitation."
              id="b2h9kF"
            />
          }
        />
      ) : (
        <div className="flex flex-col gap-6 pt-4">
          <TeamInvitationLinkUI team={team} isAdmin={isAdmin} />

          <div className="flex items-center">
            <div className="h-px w-full bg-slate-200" />
            <div className="px-3">or</div>
            <div className="h-px w-full bg-slate-200" />
          </div>

          <EmailsInput
            emails={emails}
            setEmails={setEmails}
            onClose={onClose}
          />
        </div>
      )}
    </ModalDialog>
  );
};
