import React, { ReactElement } from 'react';
import {
  Background as ReactFlowBackground,
  BackgroundVariant,
} from '@xyflow/react';

export const Background = (): ReactElement => {
  return (
    <>
      <ReactFlowBackground
        gap={16}
        variant={BackgroundVariant.Dots}
        patternClassName={'!stroke-slate-200'}
      />
    </>
  );
};
