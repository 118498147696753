import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Connection,
  DataSourceInstance,
  Integration,
  IntegrationAppClient,
  IntegrationAppProvider,
  PaginationResponse,
  useIntegrationApp,
  DataSchema,
  DataCollectionListResponse,
  DataCollectionSearchResponse,
  DataCollectionListRequest,
} from '@integration-app/react';
import { useQuery } from '@apollo/client';
import { IntegrationTokenDocument } from '../graphql/operations';
import useSWR, { SWRResponse, useSWRConfig } from 'swr';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';
import featureFlagService from '../helpers/feature-flags';
import { listAllFromIntegration } from '@tactiq/model';

// Re-exports
export type { DataSchema };

const WORKFLOW_DATA_SOURCE = 'workflows-data-source';

/**
 * Provider for the integration app recat sdk
 * Handles fetching the token for the current user
 */
export function IntegrationProvider(props: {
  children: ReactNode;
}): ReactElement {
  const query = useQuery(IntegrationTokenDocument, { skip: true });

  const fetchToken = useCallback(async () => {
    const request = await query.refetch();
    return request.data?.integrationToken ?? '';
  }, [query]);

  return (
    <IntegrationAppProvider fetchToken={fetchToken}>
      {props.children}
    </IntegrationAppProvider>
  );
}

/**
 * Hook to access the integration app client.
 * This should probably be locked down and consumed through other hooks from this file
 */
export function useIntegration(): IntegrationAppClient {
  return useIntegrationApp();
}

//
// Integrations
//

/**
 * Fetch details about a single integration
 * This is used to determine how create a connection to it
 */
export function useIntegrationGet(type: string): SWRResponse<Integration> {
  const api = useIntegration();
  return useSWR(['integration', type], () => api.integration(type).get());
}

//
// Connections
//

/**
 * Fetch a list of possible connections
 */
export function useConnectionList(): SWRResponse<
  PaginationResponse<Integration>
> {
  const api = useIntegration();
  const avaiableIntegrations = featureFlagService.getAvailableIntegrations();

  return useSWR(
    'connectionList',
    async () => {
      const integrations = await api.integrations.find();
      integrations.items = integrations.items.filter((ii) =>
        avaiableIntegrations.has(ii.key)
      );
      return integrations;
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the response is always the same unless we add a new integration
    }
  );
}

/**
 * Create a new Connection
 */
export function useConnectionCreate(
  type: string,
  parameters: unknown
): SWRMutationResponse<Connection | undefined, unknown, string[], string> {
  const api = useIntegration();
  const { mutate } = useSWRConfig();
  return useSWRMutation(['connection', type], async (_, { arg }) => {
    const connection = await api
      .integration(type)
      .connect({ parameters, authOptionKey: arg });
    await mutate('connectionList');
    return connection;
  });
}

/**
 * Disconnect an existing Connection
 */
export function useConnectionDisconnect(
  type: string
): SWRMutationResponse<void> {
  const api = useIntegration();
  const { mutate } = useSWRConfig();
  return useSWRMutation(['connection', type], async () => {
    const response = await api.integration(type).disconnect();
    await mutate('connectionList');
    return response;
  });
}

//
// Data Sources
//

/**
 * Fetch/Create a new data source for a single node
 */
export function useDataSource(config?: {
  type: string;
  instanceKey: string;
  autoCreate?: boolean;
}): SWRResponse<DataSourceInstance> {
  const api = useIntegration();
  return useSWR(
    config ? ['dataSource', config.type, config.instanceKey] : null,
    ([, type, instanceKey]) => {
      return api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .get({ autoCreate: config?.autoCreate ?? true });
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the response is always the same unless the user changes the data source
    }
  );
}
/**
 * refresh
 */
export function useDataSourceRefresh(config: {
  type: string;
  instanceKey: string;
}): SWRMutationResponse<DataSourceInstance, unknown, string[], undefined> {
  const { type, instanceKey } = config;
  const api = useIntegration();
  return useSWRMutation(
    config ? ['dataSource', type, instanceKey] : null,
    () => {
      return api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .setup();
    }
  );
}

/**
 * Update the data source
 * */
export function useDataSourceUpdate(config: {
  type: string;
  collectionKey: string;
  instanceKey: string;
}): SWRMutationResponse<
  DataSourceInstance,
  unknown,
  string[],
  Record<string, string>
> {
  const { type, collectionKey, instanceKey } = config;
  const api = useIntegration();

  return useSWRMutation(
    ['dataSource', config.type, config.instanceKey],
    async (_, { arg }) => {
      const response = await api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .patch({
          collectionKey,
          collectionParameters: arg,
        });

      return response;
    },
    {
      populateCache: true,
    }
  );
}

/**
 * Open the configuration modal for a data source
 */
export function useDataSourceConfigure(config: {
  type: string;
  instanceKey: string;
}): SWRMutationResponse<void> {
  const { type, instanceKey } = config;
  const api = useIntegration();
  return useSWRMutation('dataSourceConfigure', () => {
    return api
      .connection(type)
      .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
      .openConfiguration();
  });
}

//
// Data Collections
//

/**
 * Fetch the list of data collections
 * */
export function useDataCollectionList(config: {
  type: string;
  collectionKey: string;
}): SWRResponse<DataCollectionListResponse> {
  const api = useIntegration();
  return useSWR(
    ['dataCollections', config.type, config.collectionKey],
    async () => {
      let filter: DataCollectionListRequest['filter'];
      if (config.type === 'slack') {
        const response = await api
          .connection('slack')
          .operation('auth-test')
          .run();
        if (response.output.user_id) {
          filter = { id: response.output.user_id };
        }
      }
      return listAllFromIntegration(
        api,
        config.type,
        config.collectionKey,
        1,
        filter
      );
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the request takes really long
    }
  );
}

/**
 * Search for items in a data collection
 */
export function useDataCollectionSearch(config: {
  type: string;
  collectionKey: string;
}): SWRMutationResponse<
  DataCollectionSearchResponse,
  unknown,
  string[],
  string
> {
  const api = useIntegration();
  return useSWRMutation(
    ['dataCollectionSearch', config.type, config.collectionKey],
    async (_, { arg }) => {
      return await api
        .connection(config.type)
        .dataCollection(config.collectionKey)
        .search({ query: arg });
    }
  );
}

export function useInstanceKey(workflowId: string, nodeId: string): string {
  return `${workflowId}::${nodeId}`;
}
