import React, { useCallback, ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { Checkbox } from '../../../components/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import { AiOutputLanguageSelector } from '../../Common/AiOutputLanguageSelector';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { Sparkle } from 'lucide-react';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';

export type CustomPromptData = {
  prompt: string;
  saveToMeeting: boolean;
  aiOutputLanguage: string;
  includeTranscriptContext: boolean;
};

export function CustomPrompt(
  props: WorkflowNodeProps<CustomPromptData>
): ReactElement {
  const intl = useIntl();
  const { id } = props;
  const { updateNodeData: update } = useReactFlow();
  const value = props.data.prompt ?? '';
  const autocomplete = useAutocomplete(props);
  const { workflowId } = useWorkflowId();
  const isExecution = Boolean(props.data.execution);
  const isPreview = useIsPreview();
  const handleChange = useCallback(
    (prompt: string) => {
      update(id, { prompt });
    },
    [id, update]
  );

  const input = (
    <div className="flex grow flex-col gap-3">
      <LiquidTemplateInput
        className="min-h-48 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={value}
        onChange={handleChange}
        workflowId={workflowId}
        nodeType={props.type}
        disabled={isExecution || isPreview}
        placeholder={intl.formatMessage({
          defaultMessage: 'Write your own custom prompt',
        })}
        ariaLabel={intl.formatMessage({
          defaultMessage: 'Custom prompt input',
        })}
      />

      <div className="flex flex-col gap-2">
        <Checkbox
          id={`${props.id}-applyToMeeting`}
          checked={props.data.saveToMeeting}
          label={
            <FormattedMessage
              defaultMessage="Save the result to the meeting"
              id="6306sX"
            />
          }
          onChange={(saveToMeeting) => update(id, { saveToMeeting })}
          disabled={isExecution}
          className="nodrag"
        />
        <Checkbox
          id={`${props.id}-includeTranscriptContext`}
          checked={props.data.includeTranscriptContext}
          label={
            <FormattedMessage
              defaultMessage="Include the entire transcript in the context (uncheck to make this faster)"
              id="/Jlf5s"
            />
          }
          onChange={(includeTranscriptContext) =>
            update(id, { includeTranscriptContext })
          }
          disabled={isExecution}
          className="nodrag"
        />
        <div className="nodrag flex flex-row items-center gap-2">
          <span className="text-sm">
            <FormattedMessage
              defaultMessage="Language for AI output"
              id="LnF3hG"
            />
          </span>
          <AiOutputLanguageSelector
            onChange={(aiOutputLanguage) => update(id, { aiOutputLanguage })}
            value={props.data.aiOutputLanguage}
            context="meeting"
            disabled={isExecution}
          />
        </div>
      </div>
    </div>
  );

  return (
    <SingleSourceNode
      icon={
        <Sparkle className="size-8 rounded-lg border border-purple-500/15 bg-purple-50 p-1.5 text-purple-500" />
      }
      workflowNode={props}
      minHeight={415}
      contentClassName="h-full"
    >
      {input}
    </SingleSourceNode>
  );
}
