import React, { ReactElement, useEffect } from 'react';
import { ModalDialog } from '../../components/modals';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircleHelp } from 'lucide-react';
import { Select } from '../../components/Select';
import { trackWorkflowAccessViewed } from '../../helpers/analytics';
import { Team, Workflow, WorkflowAccess } from '../../graphql/operations';
import { useWorkflowShare } from '../../services/Workflow';
import { Button } from '../../components/buttons';
import { Tooltip } from '../../components/Tooltip';
import CopyWorkflowLinkButton from './CopyWorkflowLinkButton';

export default function WorkflowSharingModal(props: {
  open: boolean;
  onClose: () => void;
  workflowLink: string;
  workflow: Workflow;
  team: Team | undefined;
}): ReactElement {
  const intl = useIntl();
  const noAccessLabel = intl.formatMessage({ defaultMessage: 'No access' });
  const open = Boolean(props.team) && props.open;
  const { loading, request } = useWorkflowShare();

  useEffect(() => {
    if (open) trackWorkflowAccessViewed(undefined);
  }, [open]);

  return (
    <ModalDialog
      open={open}
      onClose={props.onClose}
      size="medium"
      title={<FormattedMessage defaultMessage="Workflow access" />}
      actions={
        <>
          <Button variant="secondaryOutline" onClick={props.onClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
          <CopyWorkflowLinkButton
            source="access"
            workflow={props.workflow}
            withMessage
            className="-ml-3 mr-auto"
            variant="naked"
          />
        </>
      }
    >
      <div className="mt-6 mb-3 grid grid-cols-[6fr_4fr] items-center gap-3">
        <FormattedMessage defaultMessage="You're the workflow owner" />
        <div className="h-8" />

        <FormattedMessage
          defaultMessage="{displayName} team"
          values={{ displayName: props.team?.displayName }}
        />
        <Select
          full
          loading={loading}
          onChange={(next) =>
            request({ input: { id: props.workflow.id, team: next } })
          }
          options={[
            {
              value: WorkflowAccess.READ,
              label: intl.formatMessage({
                defaultMessage: 'Duplicate and run',
              }),
            },
            {
              value: WorkflowAccess.NONE,
              label: noAccessLabel,
            },
          ]}
          value={props.workflow.sharing?.team}
        />

        <FormattedMessage defaultMessage="Anyone with the link" />
        <Tooltip
          arrow
          title={<FormattedMessage defaultMessage="Coming soon" />}
        >
          <span className="ml-auto flex items-center gap-2 text-sm">
            {noAccessLabel}
            <CircleHelp size="1rem" />
          </span>
        </Tooltip>
      </div>
    </ModalDialog>
  );
}
